
import { acceptFloatPatch, declineFloatPatch, getAllBankLogo, getFloatPatchByID, viewFloatRequest } from "lib/auth";
import { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import view from '../../../../images/svg/float/view.svg'
import document from '../../../../images/svg/float/document.svg'
import { dateFormatter } from "utils/helpers";
import BasicVerificationResult from '../results/BasicVerificationResult'
import WarningModal from "components/Modal/WarningModal";
import clsx from "clsx";
import Back from 'components/back'


export default function PatchDetails ({setHeader}) {
    const params = useParams();
    const [requestData, setRequestData] = useState({});
    const [basicData, setBasicData] = useState({});
    const [basicVerificationModal, setBasicVerificationModal] = useState(false);
    const [patchData, setPatchData] = useState({});
    const [ngBanks, setNgBanks] = useState([]);
    const [showWarning, setShowWarning] = useState(false)
    const [warningType, setWarningType] = useState("")
    let navigate = useNavigate();

    var formatMoney = new Intl.NumberFormat('en-NG', {
        style: 'currency',
        currency: 'NGN',
        maximumFractionDigits: 2, 
    });

    useEffect(()=>{
        setHeader();
        getFloatDetail();
        getAllBankLogo()
          .then(resp=>{
              setNgBanks(resp.data);
          })
          // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const getFloatDetail = () => {
        getFloatPatchByID(params.id)
            .then((response) => response.data)
            .then((res) => {
                if (res.status === 'success') {
                    viewFloat(res?.data?.floatId)
                    setPatchData(res.data)
                } else {
                    toast.error("Something went wrong, please try again!!!")
                }
            })
            .catch((err) => {
                if(err.response){
                    toast.error(err.response.data.message);
                } else{
                    toast.error("Something went wrong!!! Please make sure you have a good internet connection.");
                }
            });
    }

    const viewFloat = (id) => {
        viewFloatRequest(id)
            .then(response => response.data)
            .then((res) => {
                console.log(res)
                if(res.status === 'success'){
                    setRequestData(res.data)
                }
                else {
                    toast.error("Something went wrong, please try again!!!")
                }
            })
            .catch((err) => {
                if(err.response){
                    toast.error(err.response.data.message);
                } else{
                    toast.error("Something went wrong!!! Please make sure you have a good internet connection.");
                }
            });
    }

    const director1Bvn = (item) => {
        sessionStorage.setItem('floatBvn', JSON.stringify(requestData?.director1BVN));
        window.location= '/float/director1bvn'
    }

    const director2Bvn = (item) => {
        sessionStorage.setItem('floatBvn', JSON.stringify(requestData?.director2BVN));
        window.location= '/float/director2bvn'
    }

    const openView = (item) => {
        if(item === 'businessBankDetail'){
            setBasicData(requestData?.businessBankDetail)
            setBasicVerificationModal(true)
        }else if(item === 'Director1BankResult'){
            setBasicData(requestData?.director1BankDetail)
            setBasicVerificationModal(true)
        }else if(item === 'Director2BankResult'){
            setBasicData(requestData?.director2BankDetail)
            setBasicVerificationModal(true)
        }
    }

    const declinePatch = () => {
        declineFloatPatch(patchData?._id)
            .then(response => response.data)
            .then((res) => {
                if(res.status === 'success'){
                    setShowWarning(false);
                    toast.success("Patch request declined!")
                    getFloatDetail()
                }
                else {
                    toast.error("Something went wrong, please try again!!!")
                }
            })
            .catch((err) => {
                if(err.response){
                    toast.error(err.response.data.message);
                } else{
                    toast.error("Something went wrong!!! Please make sure you have a good internet connection.");
                }
            });
    }

    const acceptPatch = () => {
        acceptFloatPatch(patchData?._id)
            .then(response => response.data)
            .then((res) => {
                if(res.status === 'success'){
                    setShowWarning(false);
                    toast.success("Patch request approved!")
                    getFloatDetail()
                }
                else {
                    toast.error("Something went wrong, please try again!!!")
                }
            })
            .catch((err) => {
                if(err.response){
                    toast.error(err.response.data.message);
                } else{
                    toast.error("Something went wrong!!! Please make sure you have a good internet connection.");
                }
            });
    }

    return (
        <div className="mx-10 mb-[30px]">
            <hr className="mb-[20px]"/>
            <Back to={()=>navigate(`/float`)} />
            <div className="w-full box-border mt-[20px]">
                <div className="flex">
                    <div>
                        <h3 className="text-ee-black font-medium text-[30px] capitalize">{requestData?.businessId?.businessName}</h3>
                        <p className="text-ee-grey-7">{requestData?.businessId?.CAC ? "RC Number":"BVN"}: {requestData?.businessId?.CAC || requestData?.businessId?.bvn} </p>
                    </div>
                    <p className="ml-auto">Applied on: {patchData?.createdAt ? dateFormatter(patchData?.createdAt, true):"DD/MM/YYYY"}</p>
                </div>
                <p className="text-base font-medium my-2.5">
                    Patch Option - {" "}
                    {
                        patchData?.category==="changeBalance" ? "Change Balance Amount"
                        : patchData?.category==="freezeCollection" ? "Freeze Collections"
                        : patchData?.category==="skipCollection" ? "Skip Collections"
                        : patchData?.category
                    }
                </p>
                <div className="mb-7">
                    <Row>
                        <Col md={6} lg={3} >
                            <div className="border-l-[4.5px] border-ee-grey-8 pl-3 py-1 pr-2.5 mb-[20px]">
                                <p className="text-ee-grey-7">Remark</p>
                                <p className="text-base mt-[20px]">{patchData?.remarks}</p>
                            </div>
                        </Col>
                        {
                            patchData?.category==="changeBalance" ?
                            <>
                                <Col md={6} lg={3} >
                                    <div className="border-l-[4.5px] border-ee-grey-8 pl-3 py-1 pr-2.5 mb-[20px]">
                                        <p className="text-ee-grey-7">Principal</p>
                                        <h3 className="mt-6 font-medium text-2xl">{formatMoney.format(patchData?.principal | 0)}</h3>
                                    </div>
                                </Col>
                                <Col md={6} lg={3} >
                                    <div className="border-l-[4.5px] border-ee-grey-8 pl-3 py-1 pr-2.5 mb-[20px]">
                                        <p className="text-ee-grey-7">Tenure</p>
                                        <h3 className="mt-6 font-medium text-2xl">{patchData?.tenure | 0} months</h3>
                                    </div>
                                </Col>
                            </>
                            : patchData?.category==="freezeCollection" ?
                            <>
                                <Col md={6} lg={3} >
                                    <div className="border-l-[4.5px] border-ee-grey-8 pl-3 py-1 pr-2.5 mb-[20px]">
                                        <p className="text-ee-grey-7">Duration</p>
                                        <h3 className="mt-6 font-medium text-2xl">{patchData?.duration | 0} months</h3>
                                    </div>
                                </Col>
                            </>
                            : patchData?.category==="skipCollection" ?
                            <>
                                <Col md={6} lg={3} >
                                    <div className="border-l-[4.5px] border-ee-grey-8 pl-3 py-1 pr-2.5 mb-[20px]">
                                        <p className="text-ee-grey-7">Next Schedule</p>
                                        <h3 className="mt-6 font-medium text-2xl">{patchData?.nextSchedule ? dateFormatter(patchData?.nextSchedule):"DD/MM/YYYY"}</h3>
                                    </div>
                                </Col>
                                <Col md={6} lg={3} >
                                    <div className="border-l-[4.5px] border-ee-grey-8 pl-3 py-1 pr-2.5 mb-[20px]">
                                        <p className="text-ee-grey-7">Duration</p>
                                        <h3 className="mt-6 font-medium text-2xl">{patchData?.newDate ? dateFormatter(patchData?.newDate):"DD/MM/YYYY"}</h3>
                                    </div>
                                </Col>
                            </>
                            :
                            <></>
                        }
                    </Row>
                </div>
                <hr/>
                {
                    patchData?.status==="pending" ?
                    <>
                        <div className="flex mt-7 mb-4">
                            <p className="text-base font-medium">Active Float Information</p>
                            <p className="ml-auto">Applied on: {requestData?.createdAt ? dateFormatter(requestData?.createdAt, true):"DD/MM/YYYY"}</p>
                        </div>
                        <div>
                            <Row>
                                <Col md={6} lg={3} >
                                    <div className="border-l-[4.5px] border-ee-grey-8 pl-3 py-1 pr-2.5 mb-[20px]">
                                        <p className="text-ee-grey-7">Principal Amount</p>
                                        <h3 className="mt-6 font-medium text-2xl"> {formatMoney.format(requestData?.approvedFloat?.approvedAmount | 0)}</h3>
                                    </div>
                                </Col>
                                <Col md={6} lg={3} >
                                    <div className="border-l-[4.5px] border-ee-grey-8 pl-3 py-1 pr-2.5 mb-[20px]">
                                        <p className="text-ee-grey-7">Loan Duration</p>
                                        <h3 className="mt-6 font-medium text-2xl">{requestData?.approvedFloat?.loanTenure | 0} months</h3>
                                    </div>
                                </Col>
                                <Col md={6} lg={6} >
                                    <div>
                                        <h4 className="text-md font-semibold mb-2.5">Bank Statement</h4>
                                        <div style={{display:'flex', alignItems: 'center'}}>
                                            <div className="bg-white border-[1px] border-ee-grey-3 rounded-[8px] flex justify-between items-center py-[15px] px-2.5">
                                                <img src={document} alt="document icon" />
                                                <div>
                                                    <p className="mr-[35px] ml-2.5">PDf Document</p>
                                                </div>
                                                <img className="cursor-pointer w-10" src={view} alt="view icon" onClick={()=> window.location.href = requestData?.pdfStatement?.url } />
                                            </div>
                                            <div className="ml-[2em]">
                                                <h4 className="text-md font-semibold">Bank Account Number</h4>
                                                <p style={{color:'#0046E6', cursor:'pointer'}} onClick={()=>openView('businessBankDetail')}>View</p>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </>
                    :<></>
                }
            </div>
            {
                patchData?.status==="pending" ?
                <>
                    <hr className="my-4"/>
                    <div className="grid grid-cols-2">
                        <div>
                            <h4 className="text-md font-semibold mb-2.5">Director 1 Information</h4>
                            <div className="grid grid-cols-2 gap-x-3 gap-y-[20px]">
                                <div>
                                    <p className="font-semibold text-sm mb-1 text-ee-grey-2">Full Name</p>
                                    <p className="font-semibold text-base text-ee-grey-9">{requestData?.director1?.fullName}</p>
                                </div>
                                <div>
                                    <p className="font-semibold text-sm mb-1 text-ee-grey-2">Email Address</p>
                                    <p className="font-semibold text-base text-ee-grey-9">{requestData?.director1?.email}</p>
                                </div>
                                <div>
                                    <p className="font-semibold text-sm mb-1 text-ee-grey-2">BVN</p>
                                    <p className="font-semibold text-base text-ee-primary cursor-pointer" onClick={director1Bvn}>View</p>
                                </div>
                                <div>
                                    <p className="font-semibold text-sm mb-1 text-ee-grey-2">Bank Account Number</p>
                                    <p className="font-semibold text-base text-ee-primary cursor-pointer" onClick={()=>openView('Director1BankResult')}>View</p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <h4 className="text-md font-semibold mb-2.5">Director 2 Information</h4>
                            <div className="grid grid-cols-2 gap-x-3 gap-y-[20px]">
                                <div>
                                    <p className="font-semibold text-sm mb-1 text-ee-grey-2">Full Name</p>
                                    <p className="font-semibold text-base text-ee-grey-9">{requestData?.director2?.fullName}</p>
                                </div>
                                <div>
                                    <p className="font-semibold text-sm mb-1 text-ee-grey-2">Email Address</p>
                                    <p className="font-semibold text-base text-ee-grey-9">{requestData?.director2?.email}</p>
                                </div>
                                <div>
                                    <p className="font-semibold text-sm mb-1 text-ee-grey-2">BVN</p>
                                    <p className="font-semibold text-base text-ee-primary cursor-pointer" onClick={director2Bvn}>View</p>
                                </div>
                                <div>
                                    <p className="font-semibold text-sm mb-1 text-ee-grey-2">Bank Account Number</p>
                                    <p className="font-semibold text-base text-ee-primary cursor-pointer" onClick={()=>openView('Director2BankResult')}>View</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className="my-4"/>
                    <div className="flex">
                        <div className="ml-auto">
                            <button className="border-[0px] bg-ee-red-3 text-white font-semibold px-6 py-2 mr-5 rounded-[6px]" onClick={()=>{setWarningType("decline"); setShowWarning(true);}}>Decline Request</button>
                            <button className="border-[0px] bg-ee-green-3 text-white font-semibold px-6 py-2 rounded-[6px]" onClick={()=>{setWarningType("accept"); setShowWarning(true);}}>Accept Request</button>
                        </div>
                    </div>
                    <BasicVerificationResult
                        show={basicVerificationModal}
                        cancel={()=>setBasicVerificationModal(false)}
                        data={basicData}
                        ngBanks={ngBanks}
                    />
                    <WarningModal
                        show={showWarning}
                        handleClose={()=>setShowWarning(false)}
                        handleAction={()=>warningType==="decline"?declinePatch():acceptPatch()}
                        title={`${warningType==="decline"?"Decline":"Accept"} Float Patch Request`}
                        text={`Are you sure you want to ${warningType} this Request? This action cannot be undone.`}
                    />
                </>
                :
                <>
                    <div className="flex">
                        <div className="ml-auto flex items-center mt-4">
                            <p className="font-semibold mr-2.5">Request Status:</p>
                            <button className={clsx(
                                    "border-[0px] text-white font-semibold px-6 py-2 rounded-[6px]",
                                    patchData?.status==="rejected" ? "bg-ee-red-3":"bg-ee-green-3",
                                )}
                            >
                                {patchData?.status==="rejected" ? "Declined":"Approved"}
                            </button>
                        </div>
                    </div>
                    <div>
                        <Row>
                            <Col md={6} lg={3} >
                                <div className="border-l-[4.5px] border-ee-grey-8 pl-3 py-1 pr-2.5 mb-[20px]">
                                    <p className="text-ee-grey-7">{patchData?.status==="rejected" ? "Declined on":"Approved On"}</p>
                                    <h3 className="mt-6 font-medium text-2xl">{patchData?.updatedAt ? dateFormatter(patchData?.updatedAt, true):"DD/MM/YYYY"}</h3>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </>
            }
        </div>
    )
}