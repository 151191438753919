import React, { useEffect, useState } from 'react';
import { MetricsService } from 'services/metrics.service';
import Filter from 'components/shared/Filter';
import selectArrow from "images/svg/select-arrow.svg";
import useSingleState from 'hooks/useSingleState';
import { handleRequestErrors } from 'utils/helpers';
import { useSearchParams } from 'react-router-dom';
import DataBox from 'components/shared/DataBox';
import CustomAreaChart from 'components/Charts/AreaChart';
import clsx from "clsx";
import viewmore_dropdown from "images/svg/recova/viewmore_dropdown.svg";
import Table from 'components/Table';
import dayjs from 'dayjs';


export default function NPSSurveyMetrics () {
    const [searchParams, setSearchParams] = useSearchParams();
    const query = searchParams.get("filter") || "this_month"
    const startDate = searchParams.get("customStart")||""
    const endDate = searchParams.get("customEnd")||""
    const stats = useSingleState(null)
    const graph = useSingleState(null)
    const tableData = useSingleState(null)
    const [dropDownView, setDropDownView] = useState(null)
    const search = searchParams.get("search") || ""
    const filter = searchParams.get("tag") || ""
    const page = searchParams.get("page") || 1

    useEffect(() => {
        getStats()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query])

    useEffect(() => {
        getTable()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query, search, filter, page])

    useEffect(()=>{
        getGraph()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getStats = () => {
        MetricsService.getNPSMetricsStat({
            type: query,
            start: startDate,
            end: endDate,
        })
            .then(response => response.data)
            .then((res) => {
                if (res.status==='success') {
                    stats.set(res.data)
                }
            })
            .catch((err) => {
                handleRequestErrors(err)
            });
    }

    const getGraph = () => {
        MetricsService.getNPSMetricsGraph()
            .then(response => response.data)
            .then((res) => {
                if (res.status==='success') {
                    graph.set(res.data)
                }
            })
            .catch((err) => {
                handleRequestErrors(err)
            });
    }

    const getTable = () => {
        MetricsService.getNPSMetricsSurvey({
            type: query,
            start: startDate,
            end: endDate,
            page: page,
            search: search,
            filter: filter,
        })
            .then(response => response.data)
            .then((res) => {
                if (res.status==='success') {
                    tableData.set(res.data)
                }
            })
            .catch((err) => {
                handleRequestErrors(err)
            });
    }

    const filterOptions = [
        { label: "Last 7 days", value: "last_seven_days"},
        { label: "This Month", value: "this_month"},
        { label: "Last Month", value: "last_month"},
        { label: "Last 3 Months", value: "last_three_months"},
        { label: "Last 6 Months", value: "last_six_months"},
        { label: "Last 12 Months", value: "last_twelve_months"},
        { label: "Custom", value: "custom"},
    ]

    return (
        <div>
            <div className='flex justify-end mb-10'>
                <Filter
                    placeHolder="This Month"
                    options={
                        filterOptions.map((item) => ({
                            label: item.label,
                            value: item.value
                        }))
                    }
                    containerClass="border-ee-primary text-base text-[#8A8A8A] flex-row-reverse"
                    icon={selectArrow}
                    iconClassName="!w-[10px]"
                    optionItemClass="text-sm text-xs font-semibold p-2 rounded-md m-0 hover:bg-ee-grey-3"
                />
            </div>
            <div className='grid grid-cols-3 gap-8 box-border'>
                {
                    [
                        {
                            title: "Total NPS Responses",
                            value: (stats?.get?.promoter||0)+(stats?.get?.passive||0)+(stats?.get?.detractors||0),
                            imageBg: "bg-[#2F49D1] bg-opacity-10",
                            icon: <CardIcon color="#0046E6" />
                        },
                        {
                            title: "Total Promoters",
                            value: stats?.get?.promoter||0,
                            imageBg: "bg-[#54BD95] bg-opacity-10",
                            icon: <CardIcon color="#54BD95" />
                        },
                        {
                            title: "Total Passive",
                            value: stats?.get?.passive||0,
                            imageBg: "bg-[#F8650C] bg-opacity-10",
                            icon: <CardIcon color="#F8650C" />
                        },
                        {
                            title: "Total Detractors",
                            value: stats?.get?.detractors||0,
                            imageBg: "bg-[#DE213C] bg-opacity-10",
                            icon: <CardIcon color="#DE213C" />
                        },
                        {
                            title: "NPS Score",
                            value: 0,
                            imageBg: "bg-[#2F49D1] bg-opacity-10",
                            icon: (
                                <svg width="25" height="16" viewBox="0 0 25 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M23.5501 9.30182C23.2318 9.30182 22.9266 9.17014 22.7016 8.93574C22.4766 8.70135 22.3502 8.38344 22.3502 8.05195V4.81479L16.0025 11.4391C15.8229 11.6246 15.5916 11.7463 15.3416 11.7867C15.0916 11.8271 14.8358 11.7842 14.6106 11.6641L8.26303 8.36442L2.87537 15.1012C2.6717 15.3565 2.37902 15.517 2.06173 15.5474C1.74444 15.5779 1.42852 15.4759 1.18348 15.2637C0.93843 15.0515 0.784331 14.7467 0.755077 14.4162C0.725824 14.0857 0.823812 13.7566 1.02749 13.5014L7.02711 6.00216C7.20123 5.78531 7.44079 5.63635 7.70667 5.57959C7.97255 5.52284 8.24905 5.56166 8.49102 5.6897L14.9106 9.02685L20.6583 3.05247H17.5505C17.2322 3.05247 16.927 2.92079 16.702 2.68639C16.4769 2.452 16.3505 2.13409 16.3505 1.8026C16.3505 1.47112 16.4769 1.15321 16.702 0.918813C16.927 0.684417 17.2322 0.552734 17.5505 0.552734H23.5501C23.8683 0.552734 24.1735 0.684417 24.3986 0.918813C24.6236 1.15321 24.75 1.47112 24.75 1.8026V8.05195C24.75 8.38344 24.6236 8.70135 24.3986 8.93574C24.1735 9.17014 23.8683 9.30182 23.5501 9.30182Z" fill="#0046E6"/>
                                </svg>
                            )
                            
                        },
                    ].map(item => (
                        <div key={item.title}>
                            <DataBox
                                title={item.title}
                                imageBg={item.imageBg}
                                icon={item.icon}
                                value={item.value}
                                className="!shadow-[0px_13.3657px_44.5523px_rgba(160,163,189,0.5)]"
                            />
                        </div>
                    ))
                }
            </div>
            <div className='my-10'>
                <CustomAreaChart
                    data={[
                        {
                            name: "Promoters",
                            slug: "promoters",
                            total: graph?.get?.promoter?.reduce((acc, val) => acc + val, 0) ?? 0,
                            items: graph?.get?.promoter ?? [],
                            color: "#3372FF"
                        },
                        {
                            name: "Detractors",
                            slug: "detractors",
                            total: graph?.get?.detractors?.reduce((acc, val) => acc + val, 0) ?? 0,
                            items: graph?.get?.detractors ?? [],
                            color: "#002F99"
                        },
                        {
                            name: "Passives",
                            slug: "passives",
                            total: graph?.get?.passive?.reduce((acc, val) => acc + val, 0) ?? 0,
                            items: graph?.get?.passive ?? [],
                            color: "#DC6803"
                        },
                    ]}
                    title="Response Overview"
                    months={["JAN","FEB","MAR","APR","MAY","JUN","JUL","AUG","SEP","OCT","NOV","DEC"]}
                    currencyValues={false}
                    hideFirstContainer={true}
                />
            </div>
            <div className="mb-10">
                <Table
                    noTitleIcon={true}
                    tableTitle={"NPS Survey Responses"}
                    hideSearch={false}
                    data={tableData?.get?.results || []}
                    backendPaginated={true}
                    totalPages={tableData?.get?.totalPages ?? 0}
                    isLoading={false}
                    searchPlaceHolder="Search business name..."
                    onSearchChange={(e)=>{
                        searchParams.set("search", e?.target?.value);
                        searchParams.set("page", 1);
                        setSearchParams(searchParams);
                    }}
                    slot={
                        <div>
                            <Filter 
                                keyName="tag"
                                options={[
                                    { label: "All", value: "all" },
                                    { label: "Passive", value: "passive" },
                                    { label: "Detractor", value: "detractor" },
                                    { label: "Promoter", value: "promoter" },
                                ]} 
                            />
                        </div>
                    }
                    theadClass="font-semibold text-[#667085] text-xs bg-[#F9FAFB]"
                    tdataClass="py-2.5 pl-[10px] text-sm"
                    tableTopClassName="!border-none !px-0"
                    hideTableBodyBorder={true}
                    hideLines={true}
                    hideOverallTableBorder={true}
                    searchContainerClassName="!py-0 !h-[37px]"
                    rows={[
                        {
                            header:'Response ID',
                            view: (item) => (
                                <span>
                                    {item?.id||"---"}
                                </span>
                            ),
                        },
                        {
                            header:'Business Name',
                            view: (item) => (
                                <span>
                                    {item?.businessId?.businessName||"---"}
                                </span>
                            ),
                        },
                        {
                            header:'Survey Source',
                            view: (item) => (
                                <span>
                                    {item?.source||"---"}
                                </span>
                            ),
                        },
                        {
                            header:'NPS Choice',
                            view: (item) => (
                                <span>
                                    {item?.rating||0}
                                </span>
                            ),
                        },
                        {
                            header:'NPS Tag',
                            view: (item) => (
                                <div 
                                    className={clsx(
                                        'text-white py-1 px-2.5 rounded w-fit capitalize text-sm',
                                        item?.npsRating==="detractor" ? "bg-[#DE213C]" : item?.npsRating==="promoter" ? "bg-[#54BD95]" : "bg-[#FEC84B]",
                                    )}
                                >
                                    {item?.npsRating}
                                </div>
                            ),
                        },
                        {
                            header:'Date of Survey',
                            view: (item) => (
                                <span>
                                    {dayjs(item.logDate).format("DD-MM-YYYY hh:mmA")}
                                </span>
                            ),
                        },
                        {
                            header: "",
                            view: (item) => (
                                <div>
                                    <img 
                                        src={viewmore_dropdown} 
                                        alt="" 
                                        onClick={()=>{
                                            if(!!dropDownView){
                                                setDropDownView(null)
                                            } else {
                                                setDropDownView(item)
                                            }
                                        }}
                                        className={clsx(
                                            "w-8 cursor-pointer transition-all",
                                            item===dropDownView ? "rotate-180":"rotate-0",
                                        )}
                                    />
                                    {
                                        item===dropDownView &&
                                        <div className="absolute w-full left-0 z-10">
                                            <div className="shadow-md bg-white rounded mx-6 my-[10px] border-[1px] text-sm border-[#0349FD] p-[20px]">
                                                <div className="grid grid-cols-5 gap-[20px]">
                                                    {
                                                        [
                                                            {
                                                                title: "Response ID",
                                                                value: item?.id||"---",
                                                            },
                                                            {
                                                                title: "Business Name",
                                                                value: item?.businessId?.businessName||"---",
                                                            },
                                                            {
                                                                title: "Survey Source",
                                                                value: item?.source||"---",
                                                            },
                                                            {
                                                                title: "NPS Choice",
                                                                value: item?.rating||0,
                                                            },
                                                            {
                                                                title: "NPS Tag",
                                                                value: (
                                                                    <div 
                                                                        className={clsx(
                                                                            'text-white py-1 px-2.5 rounded w-fit capitalize text-sm',
                                                                            item?.npsRating==="detractor" ? "bg-[#DE213C]" : item?.npsRating==="promoter" ? "bg-[#54BD95]" : "bg-[#FEC84B]",
                                                                        )}
                                                                    >
                                                                        {item?.npsRating}
                                                                    </div>
                                                                ),
                                                            },
                                                            {
                                                                title: "Date of Survey",
                                                                value: dayjs(item.logDate).format("DD-MM-YYYY hh:mmA"),
                                                            },
                                                        ].map((item, idx)=>(
                                                            <div key={idx} className="text-[#667085]">
                                                                <p className="font-medium">{item?.title}</p>
                                                                <p className="font-bold">{item?.value}</p>
                                                            </div>
                                                        ))
                                                    }
                                                    <div className="text-[#667085] col-span-2">
                                                        <p className="font-medium">Comment</p>
                                                        <p className="font-bold">{item?.comment||"---"}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            )
                        },
                    ]}
                />
            </div>
        </div>
    )
}

const CardIcon = ({color}) => {
    return(
        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.7437 0.35536C16.9623 0.00883283 17.4348 -0.103528 17.7991 0.104384L17.4033 0.731818C17.7991 0.104384 17.7987 0.104189 17.7991 0.104384L17.8005 0.105204L17.8021 0.10613L17.8059 0.108326L17.8157 0.114101C17.8233 0.11856 17.8327 0.124257 17.844 0.131204C17.8666 0.145086 17.8964 0.163994 17.9325 0.188023C18.0046 0.236043 18.1021 0.304823 18.2162 0.395321C18.444 0.575886 18.7419 0.845643 19.0387 1.21252C19.6364 1.95177 20.2238 3.08045 20.2238 4.63426C20.2238 6.18807 19.6364 7.31675 19.0387 8.05599C18.7419 8.42287 18.444 8.69261 18.2162 8.87319C18.1021 8.96373 18.0046 9.03251 17.9325 9.08051C17.8964 9.10451 17.8666 9.12344 17.844 9.13729C17.8327 9.14422 17.8233 9.14997 17.8157 9.15446L17.8059 9.16022L17.8021 9.16236L17.8005 9.16334C17.8001 9.16354 17.7991 9.16412 17.4033 8.5367L17.7991 9.16412C17.4348 9.37202 16.9623 9.25963 16.7437 8.91319C16.5261 8.56812 16.6423 8.12096 17.003 7.91191L17.0086 7.90845C17.0161 7.90386 17.0303 7.89494 17.0503 7.88162C17.0904 7.85494 17.1532 7.81092 17.2314 7.74898C17.3882 7.62467 17.6031 7.43101 17.8192 7.16374C18.2471 6.6347 18.6853 5.81216 18.6853 4.63426C18.6853 3.45635 18.2471 2.63382 17.8192 2.10477C17.6031 1.83751 17.3882 1.64385 17.2314 1.51955C17.1532 1.45759 17.0904 1.41357 17.0503 1.3869C17.0303 1.37358 17.0161 1.36467 17.0086 1.36006L17.003 1.35662C16.6423 1.14756 16.5261 0.700423 16.7437 0.35536Z" fill={color}/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.17176 0C5.48116 0 3.29999 2.07478 3.29999 4.63415C3.29999 7.19351 5.48116 9.2683 8.17176 9.2683C10.8624 9.2683 13.0435 7.19351 13.0435 4.63415C13.0435 2.07478 10.8624 0 8.17176 0ZM4.83844 4.63415C4.83844 2.883 6.33082 1.46341 8.17176 1.46341C10.0127 1.46341 11.5051 2.883 11.5051 4.63415C11.5051 6.3853 10.0127 7.80488 8.17176 7.80488C6.33082 7.80488 4.83844 6.3853 4.83844 4.63415Z" fill={color}/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.1725 10.7324C6.08089 10.7324 4.14946 11.1916 2.71422 11.9718C1.30073 12.7401 0.223816 13.913 0.223816 15.3666C0.223816 16.8201 1.30073 17.993 2.71422 18.7613C4.14946 19.5414 6.08089 20.0007 8.1725 20.0007C10.2641 20.0007 12.1956 19.5414 13.6308 18.7613C15.0443 17.993 16.1212 16.8201 16.1212 15.3666C16.1212 13.913 15.0443 12.7401 13.6308 11.9718C12.1956 11.1916 10.2641 10.7324 8.1725 10.7324ZM1.76227 15.3666C1.76227 14.6648 2.29253 13.8865 3.4775 13.2424C4.64071 12.6102 6.29901 12.1958 8.1725 12.1958C10.046 12.1958 11.7043 12.6102 12.8675 13.2424C14.0525 13.8865 14.5827 14.6648 14.5827 15.3666C14.5827 16.0683 14.0525 16.8466 12.8675 17.4907C11.7043 18.123 10.046 18.5373 8.1725 18.5373C6.29901 18.5373 4.64071 18.123 3.4775 17.4907C2.29253 16.8466 1.76227 16.0683 1.76227 15.3666Z" fill={color}/>
            <path d="M15.7483 2.05556C15.384 1.84764 14.9115 1.96 14.6929 2.30653L14.9475 3.30506L14.9555 3.31025C14.9675 3.31825 14.9903 3.33407 15.0203 3.35791C15.081 3.406 15.1677 3.4838 15.2556 3.59253C15.4271 3.8045 15.6089 4.13923 15.6089 4.63421C15.6089 5.12919 15.4271 5.46392 15.2556 5.67589C15.1677 5.78462 15.081 5.86242 15.0203 5.91051C14.9903 5.93435 14.9675 5.95017 14.9555 5.95817L14.9475 5.96336C14.5905 6.17356 14.4762 6.61832 14.6929 6.96189C14.9115 7.30841 15.384 7.42078 15.7483 7.21286L15.3525 6.58543C15.7483 7.21286 15.7479 7.21306 15.7483 7.21286L15.7496 7.21207L15.7512 7.21123L15.7544 7.20935L15.7621 7.20487L15.7818 7.19295C15.7968 7.18369 15.8156 7.17174 15.8378 7.15705C15.8818 7.1277 15.9392 7.08713 16.0052 7.03474C16.1368 6.93039 16.3066 6.77648 16.4751 6.56814C16.8164 6.14597 17.1474 5.50509 17.1474 4.63421C17.1474 3.76333 16.8164 3.12245 16.4751 2.70028C16.3066 2.49194 16.1368 2.33803 16.0052 2.23368C15.9392 2.1813 15.8818 2.14072 15.8378 2.11137C15.8156 2.09668 15.7968 2.08474 15.7818 2.07547L15.7621 2.06356L15.7544 2.05907L15.7512 2.0572L15.7496 2.05635C15.7493 2.05615 15.7483 2.05556 15.3525 2.68299L15.7483 2.05556Z" fill={color}/>
        </svg>
    )
}