import Back from '../../../../back'
import { useNavigate } from "react-router-dom";


function RolesDetails () {

    const navigate = useNavigate();

    return(

        <div className='settings_members'>
            <Back to={()=> navigate("/settings/team")} />
        <div className="settings_members-table">
             
            <div className="settings_members-table-data">
                <div className="settings_members-table-top">
                    <h3>Manage Roles</h3>
                </div>
                <hr />
                <div className="settings_roles">

                    <div className='cards'>

                   

                        <div className='cards-green'>
                            <div className='cards-green-top'>
                                <p>What this role can do </p>
                            </div>

                            <div className='cards-content'>
                                <p> This Role has full access </p>
                                <p> Any team member with this role can access all the sections of the dashboard.</p>
                            </div>
 
                        </div>
                        <div className='cards-red'>
                            <div className='cards-red-top'>
                                <p>What this role cannot do </p>

                            </div>

                        </div>

                    </div>
                    
                </div>
            </div>
        </div>
        </div>
    )
}

export default RolesDetails;