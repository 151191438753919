import Tabs from 'components/shared/Tabs';
import { useSearchParams } from 'react-router-dom';
import GeneralMetrics from './components/GeneralMetrics';
import NPSSurveyMetrics from './components/NPSSurveyMetrics';


const Metrics = () => {
    const [searchParams] = useSearchParams();
    const currentTab = searchParams.get("tab") ?? "general";

    return (
        <div>
            <Tabs 
                tabs={[
                    {
                        label: "General Metrics",
                        key: "general"
                    },
                    {
                        label: "NPS Survey Metrics",
                        key: "nps"
                    },
                ]}
                variant="filled"
            />
            { currentTab==="general" && <GeneralMetrics /> }
            { currentTab==="nps" && <NPSSurveyMetrics /> }
        </div>
    )
}

export default Metrics;