import React, { useEffect, useState } from 'react'
import { months } from '../../../utils/constants';
import { BusinessService } from 'services/business.service';
import DataBox from 'components/shared/DataBox';
import { currencyFormatter, formatBigNumber } from 'utils/helpers';
import api from 'images/svg/box/api.svg'
import walletBlue from 'images/svg/box/walletBlue.svg'
import walletDarkBlue from 'images/svg/box/wallet/walletDarkBlue.svg'
import wallet from 'images/svg/box/wallet.svg'
import graph from 'images/svg/box/graph.svg'
import graphRed from 'images/svg/box/graphRed.svg'
import clsx from 'clsx';
import CustomBarChart from 'components/Charts/BarChart';
import { WalletService } from 'services/wallet.service';
import TopBusinesses from './components/TopBusinesses';
import useSingleState from 'hooks/useSingleState';
import Filter from 'components/shared/Filter';
import selectArrow from "images/svg/select-arrow.svg";
import { useSearchParams } from 'react-router-dom';
import CustomAreaChart from 'components/Charts/AreaChart';
import RevenueBarChart from './components/RevenueBarChart';
import Toggle from 'react-toggle';
import "react-toggle/style.css"


const Overview = ({setDetailName}) => {
  const [fundingGraphData, setFundingGraphData] = useState([]);
  const [revenueGraphData, setRevenueGraphData] = useState([]);
  const [growth, setGrowth] = useState([]);
  const [searchParams] = useSearchParams();
  const apiTrends = useSingleState(null)
  const query = searchParams.get("filter") || "this_month"
  const startDate = searchParams.get("customStart")||""
  const endDate = searchParams.get("customEnd")||""
  const overviewData = useSingleState(null)
  const [showRevenueBarChart, setShowRevenueBarChart] = useState(false)

  useEffect(() => {
    setDetailName("");
  }, [setDetailName]);

  var today = new Date();
  var d;
  var month = [];

  for(var i = 12; i > 0; i -= 1) {
    d = new Date(today.getFullYear(), today.getMonth() - i, 1);
    month.push(months[d.getMonth()]);
  }

  useEffect(() => {
    fetchOverview()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  useEffect(()=>{
    getWalletFunding()
    getRevenueActivity()
    getBusinessGraph()
    getApiTrendsGraph()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getWalletFunding = () => {
    WalletService.prodIncomeActivity({
        type: query,
        start: startDate,
        end: endDate,
    })
      .then((response) => response.data)
      .then((res) => {
        setFundingGraphData(res.data)
      })
      .catch((err) => {})
  }

  const getRevenueActivity = () => {
    BusinessService.revenueActivity()
      .then((response) => response.data)
      .then((res) => {
        setRevenueGraphData(res.data)
      })
      .catch((err) => {})
  }

  const getBusinessGraph = () => {
    BusinessService.businessGrowth()
      .then((response) => response.data)
      .then((res) => {
        setGrowth(res.data)
      })
      .catch((err) => {})
  }

  const getApiTrendsGraph = () => {
    BusinessService.getApiTrends()
      .then((response) => response.data)
      .then((res) => {
        apiTrends.set(res.data)
      })
      .catch((err) => {})
  }

  const fetchOverview = () => {
    BusinessService.fetchOverview({
      type: query,
      start: startDate,
      end: endDate,
    })
      .then((response) => response.data)
      .then((res) => {
        overviewData.set(res.data)
      })
      .catch((err) => {})
  }

  const filterOptions = [
    { label: "Last 7 days", value: "last_seven_days"},
    { label: "This Month", value: "this_month"},
    { label: "Last Month", value: "last_month"},
    { label: "Last 3 Months", value: "last_three_months"},
    { label: "Last 6 Months", value: "last_six_months"},
    { label: "Last 12 Months", value: "last_twelve_months"},
    { label: "Custom", value: "custom"},
  ]

  return (
    <div className='mt-10'>
      <div className='flex justify-end mb-10'>
        <Filter
          placeHolder="This Month"
          options={
            filterOptions.map((item) => ({
              label: item.label,
              value: item.value
            }))
          }
          containerClass="border-ee-primary text-base text-[#8A8A8A] flex-row-reverse"
          icon={selectArrow}
          iconClassName="!w-[10px]"
          optionItemClass="text-sm text-xs font-semibold p-2 rounded-md m-0 hover:bg-ee-grey-3"
        />
      </div>
      <div className='grid grid-cols-5 gap-x-10 box-border'>
        <div className='col-span-3'>
          <div className='grid grid-cols-2 gap-y-8 gap-x-[20px] box-border'>
            {
              [
                {
                  title: "Total Approved Businesses",
                  value: formatBigNumber(overviewData?.get?.totalApprovedBusiness || 0, true),
                  icon: walletBlue,
                },
                {
                  title: "Approved Businesses",
                  value: formatBigNumber(overviewData?.get?.filteredApprovedBusiness||0, true),
                  icon: walletBlue,
                  percentageDifference: overviewData?.get?.ApprovedBusinessPercentChange,
                },
                { 
                  title: "Total Revenue",
                  value: currencyFormatter(overviewData.get?.totalRevenue||0),
                  icon: walletDarkBlue,
                },
                { 
                  title: "Revenue",
                  value: currencyFormatter(overviewData.get?.filteredTotalRevenue||0),
                  icon: walletDarkBlue,
                  percentageDifference: overviewData?.get?.revenuePercentChange,
                },
                {
                  title: "Total Wallet Funding",
                  value: currencyFormatter(overviewData.get?.totalWalletFunding||0),
                  icon: wallet,
                },
                { 
                  title: "Wallet Funding",
                  value: currencyFormatter(overviewData.get?.filteredWalletFunding||0),
                  icon: wallet,
                  percentageDifference: overviewData?.get?.walletFundingPercentChange,
                },
                {
                  title: "Total API Calls",
                  value: formatBigNumber(overviewData.get?.totalApiCalls||0, true),
                  icon: api,
                },
                {
                  title: "API Calls",
                  value: formatBigNumber(overviewData.get?.filteredApiCalls||0, true),
                  icon: api,
                  percentageDifference: overviewData?.get?.apiCallsPercentChange,
                },
              ].map(item => (
                <div key={item.title}>
                  <DataBox
                    title={item.title}
                    icon={<img src={item.icon} alt={item.title} className='w-[20px]' />}
                    value={item.value}
                    className="!shadow-[0px_13.3657px_44.5523px_rgba(160,163,189,0.5)]"
                    slot={
                      !!item?.percentageDifference ?
                      <div className='flex items-center justify-between'>
                        <div className='flex items-center gap-x-0.5'>
                          <img src={item?.percentageDifference < 0 ? graphRed : graph} alt="icon" />
                          <span
                            className={clsx(
                              "text-sm",
                              item?.percentageDifference < 0 ? 'text-[#FA582E]':"text-[#4BDE97]"
                            )}
                          >
                            {Math.abs(item?.percentageDifference||0)}%
                          </span>
                        </div>
                        <p className='text-sm text-[#B9B9B9]'>{filterOptions.find(x => x.value===query)?.label || "Custom"}</p>
                      </div>
                      : null
                    }
                  />
                </div>
              ))
            }
          </div>
        </div>
        <div className='col-span-2 bg-white h-fit rounded-[10px] p-[15px] shadow-[0px_13.3657px_44.5523px_rgba(160,163,189,0.5)]'>
          <TopBusinesses query={query} startDate={startDate} endDate={endDate} />
        </div>
      </div>
      <div className='mt-10 mb-[60px] flex flex-col gap-10'>
        <CustomBarChart
          data={fundingGraphData}
          title="Wallet Funding"
          yAxisName="Amount Funded"
          removeFirst={true}
          currencyFormat={true}
          months={month ?? []}
        />
        <div className='relative'>
          <div
           className='absolute right-10 top-[50px]'
           onClick={()=>{
            setShowRevenueBarChart(!showRevenueBarChart)
           }}
          >
            <div className='flex items-center gap-1'>
              <p className={clsx(!showRevenueBarChart && 'text-[#2BBD35]')}>Line</p>
              <Toggle
                defaultChecked={showRevenueBarChart}
                className="revenue-toggle"
                icons={false}
                onChange={()=>setShowRevenueBarChart(!showRevenueBarChart)}
              />
              <p className={clsx(showRevenueBarChart && 'text-[#2BBD35]')}>Graph</p>
            </div>
          </div>
          {
            !showRevenueBarChart ?
            <CustomAreaChart
              data={[
                {
                  name: "Gross Revenue",
                  slug: "gross_revenue",
                  total: revenueGraphData?.grossData?.reduce((acc, val) => acc + val, 0) ?? 0,
                  // items: revenueGraphData?.grossData ?? [],
                  items: [],
                  color: "#3372FF"
                },
                {
                  name: "Net Revenue",
                  slug: "net_revenue",
                  total: revenueGraphData?.netData?.reduce((acc, val) => acc + val, 0) ?? 0,
                  items: revenueGraphData?.netData ?? [],
                  color: "#002F99"
                },
              ]}
              months={revenueGraphData?.months ?? []}
              title="Revenue Overview"
              currencyValues={true}
            />
            :
            <RevenueBarChart 
              revenueGraphData={revenueGraphData} 
              topData={
                [
                  {
                    name: "Gross Revenue",
                    slug: "gross_revenue",
                    total: revenueGraphData?.grossData?.reduce((acc, val) => acc + val, 0) ?? 0,
                    color: "#3372FF"
                  },
                  {
                    name: "Net Revenue",
                    slug: "net_revenue",
                    total: revenueGraphData?.netData?.reduce((acc, val) => acc + val, 0) ?? 0,
                    color: "#002F99"
                  },
                ]
              }
            />
          }
        </div>
        <CustomBarChart
          data={growth?.results ?? []}
          title="Businesses Growth"
          yAxisName="Growth"
          removeFirst={false}
          currencyFormat={false}
          months={growth?.months ?? []}
        />
        <CustomAreaChart
          data={[
            {
              name: "Total APIs",
              slug: "totalAPIData",
              total: apiTrends.get?.totalAPIData?.reduce((acc, val) => acc + val, 0) ?? 0,
              items: apiTrends.get?.totalAPIData ?? [],
              color: "#3372FF"
            },
            {
              name: "Total Billable APIs",
              slug: "totalBillableData",
              total: apiTrends.get?.totalBillableData?.reduce((acc, val) => acc + val, 0) ?? 0,
              items: apiTrends.get?.totalBillableData ?? [],
              color: "#002F99"
            },
          ]}
          title="API Trends"
          months={apiTrends.get?.months ?? []}
          currencyValues={false}
        />
      </div>
    </div>

  )
}

export default Overview