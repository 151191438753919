import {Modal} from "react-bootstrap";


function GenerateKeys ({show, handleKeys, cancel}) {
    return(
        <Modal show={show} size="md" onHide={cancel} aria-labelledby="contained-modal-title-vcenter" centered >
            <div className="myModal_main-widget">
                <h4>Generate New Keys</h4>
                <form>
                <div className="myModal_main-linkBank">
                    <div>
                        <p>Are you sure you want to permanemtly replace the existing key pairs?</p>
                    </div>
                </div></form>
                <div className="myModal_main-widget-action">
                    <button className="cancel" onClick={cancel}>Cancel</button>
                    <button onClick={handleKeys} className="publish">Generate New Keys</button>
                </div>
            </div>
        </Modal>
    )
}

export default GenerateKeys;