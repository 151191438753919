import { APIKit, loadToken } from "services";

const timeout = 15000;

export const BusinessService = {
    getTopBusinesses: (args) => {
        return APIKit(timeout).get(`eagleeyeauth/business/business-activity`, {
            headers: {
                Authorization: `Bearer ${loadToken()}`
            },
            params: {
                ...args,
            }
        })
    },

    revenueActivity: () => {
        return APIKit(120000).get(`eagleeyeauth/business/revenue-graph`, {
            headers: {
                Authorization: `Bearer ${loadToken()}`
            }
        })
    },

    businessGrowth: () => {
        return APIKit(timeout).get(`eagleeyeauth/business/growth-graph`, {
            headers: {
                Authorization: `Bearer ${loadToken()}`
            }
        })
    },

    businessStats: (type) => {
        return APIKit(timeout).get(`eagleeyeauth/business/business-stats?type=${type}`, {
            headers: {
                Authorization: `Bearer ${loadToken()}`
            }
        })
    },

    getBusinessByStatus: (args) => {
        return APIKit(120000).get(`eagleeyeauth/business/business-data`, {
            headers: {
                Authorization: `Bearer ${loadToken()}`
            }, 
            params: {
                ...args // status, page, search
            }
        })
    },

    getBusinessByType: (args) => {
        return APIKit(120000).get(`eagleeyeauth/business/business-type`, {
            headers: {
                Authorization: `Bearer ${loadToken()}`
            }, 
            params: {
                ...args // type, page, search
            }
        })
    },

    fetchBusinessByStatus: (status) => {
        return APIKit(120000).get(`eagleeyeauth/business/fetch-business?filter=${status}`, {
            headers: {
                Authorization: `Bearer ${loadToken()}`
            }
        })
    },

    getApiTrends: () => {
        return APIKit(120000).get(`eagleeyeauth/business/api-graph`, {
            headers: {
                Authorization: `Bearer ${loadToken()}`
            }
        })
    },

    fetchOverview: (args) => {
        return APIKit(120000).get(`eagleeyeauth/business/fetch-overview`, {
            headers: {
                Authorization: `Bearer ${loadToken()}`
            },
            params: {
                ...args,
            }
        })
    },

    approvalRequests: (status, args) => {
        return APIKit(timeout).get(`eagleeyeauth/business/status/${status}`, {
            headers: {
                Authorization: `Bearer ${loadToken()}`
            },
            params: {
                ...args,
            }
        })
    },

    approvalStats: (args) => {
        return APIKit(timeout).get(`eagleeyeauth/business/approval/stats`, {
            headers: {
                Authorization: `Bearer ${loadToken()}`
            },
            params: {
                ...args,
            }
        })
    },

    viewBusiness: (id) => {
        return APIKit(timeout).get(`eagleeyeauth/business/get-business/${id}`, {
            headers: {
                Authorization: `Bearer ${loadToken()}`
            }
        })
    },

    approvalAction: (payload, ipDeets ) => {
        return APIKit(timeout).put(`eagleeyeauth/business/status/action`, payload, {
            headers: {
                'ipcountry': ipDeets.country_name,
                'ipaddress': ipDeets.IPv4,
                Authorization: `Bearer ${loadToken()}`
            }
        })
    },

    deleteBusiness: (id, ipDeets) => {
        return APIKit(timeout).delete(`eagleeyeauth/business/delete/${id}`, {
            headers: {
                'ipcountry': ipDeets.country_name,
                'ipaddress': ipDeets.IPv4,
                Authorization: `Bearer ${loadToken()}`
            }
        })
    }
}