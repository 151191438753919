import Back from '../../../back'
import { useNavigate } from "react-router-dom";
import { getAllModules, getAllRoles } from '../../../../lib/auth';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import CustomRoles from '../../../Modal/CustomRoles';


function Roles () {
    const [data, setData] = useState([])
    const [showModal, setShowModal] = useState(false)
    const navigate = useNavigate();

    useEffect(() => {
      getAllRoles()
      .then(response => response.data)
      .then(res => {
          console.log(res)
          if(res.success === true){
              setData(res.data)
              sessionStorage.setItem( "allRoles", JSON.stringify(res.data));
          }else{
              toast.error("Something went wrong!!!")
          }
      })
      .catch((err) => {
        console.log(err.response)
        console.log(err)
        if(err.response){
            toast.error(err.response.data.message);
        } else{
            toast.error("Something went wrong!!! Please make sure you have a good internet connection.");
        }
    });
    }, [])

    useEffect(() => {
      getAllModules()
      .then(response => response.data)
      .then(res => {
          console.log(res)
          if(res.success === true){
            //   setData(res.data)
              sessionStorage.setItem( "allModules", JSON.stringify(res.data));
          }else{
              toast.error("Something went wrong!!!")
          }
      })
      .catch((err) => {
        console.log(err.response)
        console.log(err)
        if(err.response){
            toast.error(err.response.data.message);
        } else{
            toast.error("Something went wrong!!! Please make sure you have a good internet connection.");
        }
    });
    }, [])

    const viewPermission = (id) => {
        window.location= `/settings/team/roles/permission/${id}`;
    }
    
    
    return(

        <div className='settings_members'>
            <Back to={()=> navigate("/settings/team")} />
        <div className="settings_members-table">
             
            <div className="settings_members-table-data">
                <div className="settings_members-table-top">
                    <h3>Manage Roles</h3>
                </div>
                <hr />
                <div className="settings_roles">
                    <p className="settings_roles-default">Default roles</p>

                    {
                        data && data?.map((item, index)=>(
                            <div key={index}>
                                <div className="settings_roles-item">
                                    <div className="settings_roles-item-text">
                                        <p className="title">{item?.name}</p>
                                        <p className="desc">{item?.description} </p>
                                    </div>
                                    <button onClick={() => viewPermission(item?._id)}>View Permission</button>
                                </div>
                                <hr/>
                            </div>
                        ))
                       
                    }
                    
                    {/* <div>
                        <div className="settings_roles-item">
                            <div className="settings_roles-item-text">
                                <p className="title">Finance </p>
                                <p className="desc">This role grants users the permission for neccessary financial assessment </p>
                            </div>
                            <button>View Permission</button>
                        </div>
                        <hr/>
                    </div>
                    <div>
                        <div className="settings_roles-item">
                            <div className="settings_roles-item-text">
                                <p className="title">Investors </p>
                                <p className="desc">This role grants investors permission to view relevant data </p>
                            </div>
                            <button>View Permission</button>
                        </div>
                        <hr/>
                    </div>
                    <div>
                        <div className="settings_roles-item">
                            <div className="settings_roles-item-text">
                                <p className="title">Engineering</p>
                                <p className="desc">This role grants users the permissions necessary for providing technical support </p>
                            </div>
                            <button>View Permission</button>
                        </div>
                        <hr/>
                    </div>
                    <div>
                        <div className="settings_roles-item">
                            <div className="settings_roles-item-text">
                                <p className="title">Customer Success</p>
                                <p className="desc">This role grants users the permissions necessary for providing customer support </p>
                            </div>
                            <button>View Permission</button>
                        </div>
                        <hr/>
                    </div> */}

                     <p className='custom' onClick={()=> setShowModal(true)}> Custom Roles </p> 
                </div>
            </div>
        </div>
        { showModal && <CustomRoles cancel={()=> setShowModal(false)} />  }
        </div>
    )
}

export default Roles;