import Select from 'react-select';
import { useEffect, useState } from "react";
// import { useParams } from "react-router-dom";
import Back from '../../../../components/back'
import CurrencyFormat from 'react-currency-format';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getFloatBreakdown, makeFloatOffer } from '../../../../lib/auth';
import Table from '../../../../components/Table/general.js';
import Modal from '../../../../components/Modal';
import { Row, Col } from "react-bootstrap";
// import SelectInput from "../../Form/SelectInput";
// import view from '../../../images/svg/float/view.svg'
// import document from '../../../images/svg/float/document.svg'

function Offer ({setHeader}) {
    const [showSendOffer, setShowSendOffer] = useState(false)
    let requestData = JSON.parse(sessionStorage.getItem('requestDetails')).requestData
    const [floatBreakdown, setFloatBreakdown] = useState({
        tableFor: "float-breakdown",
        headings: ["Principal", "Interest", "Total"],
        items: []
    })
    let navigate = useNavigate();
    const [data, setData] = useState({
        // businessId,
        floatId: requestData?._id ,
        approvedLoanAmount: '',
        approvedLoanDuration: '',
        interestRate: '',
        interestType: ''
    })

    const options = [
        { value: "4", label: "4 months" },
        { value: "5", label: "5 months" },
        { value: "6", label: "6 months" },
        { value: "7", label: "7 months" },
        { value: "8", label: "8 months" },
        { value: "9", label: "9 months" },
        { value: "10", label: "10 months" },
        { value: "11", label: "11 months" },
    ];

    const interestOptions = [
        { value: "fixed", label: "fixed" },
        { value: "reducing", label: "reducing" }
    ];

    var formatMoney = new Intl.NumberFormat('en-NG', {
        style: 'currency',
        currency: 'NGN',
        //minimumFractionDigits: 0, 
        maximumFractionDigits: 2, 
      });

    console.log(data)
    useEffect(() => {
      setHeader()
       // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    const handleChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }


const getBreakdown = (e) => {
    e.preventDefault();
    if(data.floatId && data.approvedLoanAmount && data.approvedLoanDuration && data.interestRate && data.interestType){
        let payload = {
            ...data
        }
        console.log(payload)
        getFloatBreakdown(payload)
        .then((response) => response.data)
        .then((res) => {
            if (res.status === 'success') {
                setFloatBreakdown({
                    ...floatBreakdown,
                    items: res.data
                })
            } else {
                toast.error("Something went wrong, please try again!!!")
            }
        })
        .catch((err) => {
            if(err.response){
                toast.error(err.response.data.message);
            } else{
                toast.error("Something went wrong!!! Please make sure you have a good internet connection.");
            }
        });
    }else{
        toast.error('Please fill all missing field(s)')
    }
    
}

const handleShowModal = () => {
    if(data.floatId && data.approvedLoanAmount && data.approvedLoanDuration && data.interestRate && data.interestType){
       setShowSendOffer(true)
    }else{
        toast.error('Please fill all missing field(s)')
    }
}

const handleFloatOffer = () => {
    let payload = {
        ...data
    }
    makeFloatOffer(payload)
    .then((response) => response.data)
        .then((res) => {
            if (res.status === 'success') {
                toast.success('Float Offer Sent')
                setTimeout(() => {
                    navigate('/float')
                }, 1500);
            } else {
                toast.error("Something went wrong, please try again!!!")
            }
        })
        .catch((err) => {
            if(err.response){
                toast.error(err.response.data.message);
            } else{
                toast.error("Something went wrong!!! Please make sure you have a good internet connection.");
            }
        });
}
   
    
    return (
        <div className="home_main">
            <div className="accounts">
            <Back to={()=> navigate(`/float/request/${requestData?._id}`)} />
            <div style={{margin: '2em 0', borderBottom: '1px solid #DFDBDB', paddingBottom: '1em' }}>
                <h5>Float Request Offer</h5>
            </div>
               
            </div>

            <div className="float_offer">
                <div className="float_offer-top">
                    <h3>{requestData?.businessId?.businessName}</h3>
                    <p>RC Number: {requestData?.rcNumber} </p>
                </div>
                <Row>
                    <Col lg={7}>
                        <div className="float_offer-loan" style={{paddingTop:'2.5em'}} >
                            <h5>Loan Offer Adjustment</h5>

                            <form onSubmit={getBreakdown}>

                                <div className="form-input"> 
                                    <label> Approved Loan Amount</label>
                                    <CurrencyFormat 
                                        thousandSeparator={true} 
                                        placeholder={'Enter approved loan amount'}
                                        prefix={'₦'}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        allowNegative={false}
                                        onValueChange={(values) => {
                                            setData({
                                                ...data,
                                                approvedLoanAmount: values.value
                                            })
                                        }}
                                    />
                                </div>
                                <div className="form-input"> 
                                    <label> Loan Duration</label>
                                </div>
                                <div className="form-input-select">
                                    <Select
                                    placeholder={"Select Duration"}
                                        onChange={(e)=>{
                                            setData({
                                                ...data,
                                                approvedLoanDuration: e.value
                                            })
                                        }}
                                        options={options}
                                    />  
                                </div>
                                <div className="form-input"> 
                                    <label> Interest Rate </label>
                                    <input type="text" placeholder="Enter Interest Rate"  name="interestRate" onChange={handleChange} />
                                </div>
                                <div className="form-input"> 
                                    <label> Interest type</label>
                                </div>
                                <div className="form-input-select">
                                    <Select
                                        placeholder={"Select Type"}
                                        onChange={(e)=>{
                                            setData({
                                                ...data,
                                                interestType: e.value
                                            })
                                        }}
                                        options={interestOptions}
                                    /> 
                                </div>
                                <button type='submit'> Generate breakdown </button>
                            </form>

                            <div className="form-breakdown">
                                <h5>Repayment Breakdown</h5>
                                <div style={{margin:'0 -15px'}}>
                                    <Table data={floatBreakdown} />
                                </div>
                            </div>
                            <div style={{padding:'0 20px'}}>
                                <button className="next" style={{marginLeft:'-15px'}} onClick={handleShowModal}> Send Offer </button>
                            </div>
                        </div>
                    </Col> 
                    <Col lg={5}>
                        <div style={{marginLeft:'4em'}}>
                            <div className="amount">
                                <p>Loan Amount Requested</p>
                                <h3> {formatMoney.format(requestData?.loanAmount)}</h3>
                            </div>
                            <div className="duration">
                                <p>Loan Duration</p>
                                <h3>{requestData?.loanDuration} </h3>
                            </div>
                        </div>
                    </Col> 
                </Row>
            </div>
            {showSendOffer && <Modal type={"send-offer"} cancel={()=>{setShowSendOffer(false);}} doAction={(id)=> handleFloatOffer(id)} />}
        </div>
    )
}

export default Offer;