import clsx from "clsx";

export default function DataBox ({
    icon, value, title, className, slot,
    imageBg="bg-ee-blue-1"
}) {
    return(
        <div 
            className={clsx(
                'h-[120px] shadow-2xl rounded-[8px] flex p-[15px] items-center',
                className
            )}
        >
            <div 
                className={clsx(
                    'w-[45px] h-[45px] mr-1.5 shrink-0 rounded-[50%] flex items-center justify-center',
                    imageBg,
                )}
            >{icon}</div>
            <div className="w-full">
                <h5 className='font-bold text-[18px] text-ee-black-1 mb-1'>{value}</h5>
                <p className="text-sm font-medium text-ee-grey-1">{title}</p>
                <div>{slot}</div>
            </div>
        </div>
    )
}