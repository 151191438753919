
import { useEffect, useState } from "react";
import { Row, Col, Modal } from "react-bootstrap";
import Back from '../../../../components/back'
import { useNavigate, useLocation } from 'react-router-dom';
import Table from '../../../../components/Table/general.js';
import { activateDefaultFee, deactivateDefaultFee, repayFloatAction, repaymentHistoryById, getFloatLiquidationDetails } from '../../../../lib/auth';
import clsx from "clsx";
import { toast } from "react-toastify";
import WarningModal from "components/Modal/WarningModal";
import { currencyFormatter, handleRequestErrors } from "utils/helpers";

function OfferDetails ({setHeader}) {
    let requestData = JSON.parse(sessionStorage.getItem('requestDetails')).requestData
    const [floatBreakdown, setFloatBreakdown] = useState({
        tableFor: "repay-breakdown",
        headings: ["Schedule", "Principal", "Interest", "Total", "Repayment Date", ""],
        items: []
    })
    const [requestHistory, setRequestHistory] = useState({})
    let navigate = useNavigate();
    const location = useLocation().pathname;
    const [warning, setWarning] = useState({
        show: false,
        action: ''
    })
    const [liquidateLoading, setLiquidateLoading] = useState(false)
    const [lienLoading, setLienLoading] = useState(false)
    const [showRepaymentOptions, setShowRepaymentOptions] = useState(false)
    const [liquidatioData, setLiquidationData] = useState(null)
 
    var formatMoney = new Intl.NumberFormat('en-NG', {
        style: 'currency',
        currency: 'NGN',
        maximumFractionDigits: 2, 
    });

    function convertDate(inputFormat) {
        function pad(s) { return (s < 10) ? '0' + s : s; }
        var d = new Date(inputFormat)
        return [pad(d.getDate()), pad(d.getMonth()+1), d.getFullYear()].join('-')
    }

    useEffect(() => {
      setHeader()
      getRepaymentHistory()
      getLiquidationDetail()
       // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getRepaymentHistory = () => {
      repaymentHistoryById(requestData?._id)
        .then(response => response.data)
        .then((res) => {
            if(res.status === 'success'){
                setRequestHistory(res?.data)
                setFloatBreakdown({
                    ...floatBreakdown,
                    items: res.data.repaymentschedule
                })
            }
            else {
            }
        })
        .catch(() => {});
    }

    const getLiquidationDetail = () => {
        getFloatLiquidationDetails(
            JSON.stringify({
                floatId: requestData?._id
            })
        )
        .then(response => response.data)
        .then((res) => {
            if(res.success === true){
                setLiquidationData(res?.data)
            }
        })
        .catch(() => {});
    }

    const goBack = () => {
        if(location.includes('sales-ops')){
            // when user comes from sales-ops section
            navigate(`/sales-ops/request/${requestData?._id}`)
        } else {
            // when user comes directly from float
            navigate(`/float/request/${requestData?._id}`)
        }
    }

    const activateLien = () => {
        setLienLoading(true)
        activateDefaultFee(requestData?._id)
            .then(response => response.data)
            .then((res) => {
                setLienLoading(false)
                if(res.status === 'success'){
                    getRepaymentHistory()
                    setWarning({...warning, show:false})
                }
            })
            .catch((err) => {
                setLienLoading(false)
                handleRequestErrors(err, false)
            });
    }

    const deactivateLien = () => {
        setLienLoading(true)
        deactivateDefaultFee(requestData?._id)
            .then(response => response.data)
            .then((res) => {
                setLienLoading(false)
                setWarning({...warning, show:false})
                if(res.status === 'success'){
                    setWarning({...warning, show:false})
                    getRepaymentHistory()
                }
            })
            .catch((err) => {
                setLienLoading(false)
                handleRequestErrors(err, false)
            });
    }

    const liquidateLoan = () => {
        const payload = JSON.stringify({
            floatId: requestData._id,
            type: warning.action==="next-repayment"?"next":"all",
            chargePenalty: warning.action==="liquidate_w/_default"
        })
        setLiquidateLoading(true)
        repayFloatAction(payload)
            .then(response => response.data)
            .then((res) => {
                setLiquidateLoading(false)
                setWarning({...warning, show:false})
                if(res.status === 'success'){
                    toast.success("Loan liquidation successful")
                    getRepaymentHistory()
                }
            })
            .catch((err) => {
                setLiquidateLoading(false)
                handleRequestErrors(err, false)
            });
    }
    
    return (
        <div className="home_main">
            <div className="accounts">
                <Back to={()=> goBack() }/>
                <div style={{margin: '2em 0', borderBottom: '1px solid #DFDBDB', paddingBottom: '1em' }}>
                    <h5>Float Approved Offer</h5>
                </div>
            </div>
            <div className="float_offer">
                <div style={{marginBottom: '50px'}}>
                    <Row>
                        <Col md={6} lg={9} >
                            <div className="float_offer-top">
                                <h3 className="font-semibold text-2xl capitalize">{requestData?.businessId?.businessName}</h3>
                                <p className="font-medium">RC Number: {requestData?.rcNumber} </p>
                            </div>
                        </Col>
                        <Col md={6} lg={3} >
                            <div className="border-l-[4.5px] border-ee-grey-8 pl-3 py-1 pr-2.5 mb-[20px]">
                                <p className="text-ee-grey-7">Liquidate Total Outstanding</p>
                                <button 
                                    onClick={()=>setShowRepaymentOptions(true)}
                                    className={clsx(
                                        "border-[0px] mt-[15px] text-white font-semibold px-6 py-1.5 rounded-[6px]",
                                        requestHistory?.outstandingBalance > 0 ? "bg-ee-primary cursor-pointer":"bg-ee-grey-7 cursor-not-allowed"
                                    )}
                                    disabled={requestHistory?.outstandingBalance===0}
                                >
                                    Initiate
                                </button>
                            </div>
                        </Col>
                        <Col md={6} lg={3} >
                            <div className="border-l-[4.5px] border-ee-grey-8 pl-3 py-1 pr-2.5 mb-[20px]">
                                <p className="text-ee-grey-7">Loan Amount Requested</p>
                                <h3 className="mt-3 font-medium text-2xl">{formatMoney.format(requestData?.loanAmount | 0)}</h3>
                            </div>
                        </Col>
                        <Col md={6} lg={3} >
                            <div className="border-l-[4.5px] border-ee-grey-8 pl-3 py-1 pr-2.5 mb-[20px]">
                                <p className="text-ee-grey-7">Loan Duration</p>
                                <h3 className="mt-3 font-medium text-2xl">{requestData?.loanDuration}</h3>
                            </div>
                        </Col>
                        <Col md={6} lg={3} >
                            <div className="border-l-[4.5px] border-ee-grey-8 pl-3 py-1 pr-2.5 mb-[20px]">
                                <p className="text-ee-grey-7">Date Applied</p>
                                <h3 className="mt-3 font-medium text-2xl">{new Date(requestData.createdAt).getDate()}-{new Date(requestData.createdAt).getMonth() + 1}-{new Date(requestData.createdAt).getFullYear()}</h3>
                            </div>
                        </Col>
                        <Col md={6} lg={3} >
                            <div className="border-l-[4.5px] border-ee-grey-8 pl-3 py-1 pr-2.5 mb-[20px]">
                                <p className="text-ee-grey-7">Wallet Balance (current)</p>
                                <h3 className="mt-3 font-medium text-2xl">{formatMoney.format(requestHistory?.walletBalance | 0)}</h3>
                            </div>
                        </Col>
                        <Col md={6} lg={3} >
                            <div className="border-l-[4.5px] border-ee-grey-8 pl-3 py-1 pr-2.5 mb-[20px]">
                                <p className="text-ee-grey-7">Start Date</p>
                                <h3 className="mt-3 font-medium text-2xl">
                                    { 
                                        floatBreakdown?.items?.[0]?.dueDate ? 
                                        convertDate(floatBreakdown?.items?.[0]?.dueDate?.slice(0,10)):
                                        'YYYY-MM-DD'
                                    } 
                                </h3>
                            </div>
                        </Col>
                        <Col md={6} lg={3} >
                            <div className="border-l-[4.5px] border-ee-grey-8 pl-3 py-1 pr-2.5 mb-[20px]">
                                <p className="text-ee-grey-7">End Date</p>
                                <h3 className="mt-3 font-medium text-2xl">
                                    { 
                                        floatBreakdown?.items?.[floatBreakdown?.items?.length-1]?.dueDate ? 
                                        convertDate(floatBreakdown?.items?.[floatBreakdown?.items?.length-1]?.dueDate?.slice(0,10)):
                                        'YYYY-MM-DD'
                                    } 
                                </h3>
                            </div>
                        </Col>
                        <Col md={6} lg={3} >
                            <div className="border-l-[4.5px] border-ee-grey-8 pl-3 py-1 pr-2.5 mb-[20px]">
                                <p className="text-ee-grey-7">Next Repayment Date</p>
                                <h3 className="mt-3 font-medium text-2xl">{requestHistory?.nextRepaymentDate ? convertDate(requestHistory?.nextRepaymentDate?.slice(0,10)) :'YYYY-MM-DD'}</h3>
                            </div>
                        </Col>
                        <Col md={6} lg={3} >
                            <div className="border-l-[4.5px] border-ee-grey-8 pl-3 py-1 pr-2.5 mb-[20px]">
                                <p className="text-ee-grey-7">Activate Default Fee</p>
                                <button 
                                    onClick={()=>setWarning({show:true, action:'lien'})}
                                    className={clsx(
                                        "border-[0px] mt-[15px] text-white font-semibold px-6 py-1.5 rounded-[6px]",
                                        requestHistory.lien ? "bg-ee-green-4":"bg-ee-primary"
                                    )}
                                >
                                    {requestHistory.lien ? "Stop":"Initiate"}
                                </button>
                            </div>
                        </Col>
                    </Row>
                </div>
                <Row>
                    <Col md={6} lg={4} >
                        <div className="float_offer-loan" style={{paddingTop:'2em'}} >
                            <h5>Loan Approved Offer </h5>
                                <div className="form-input"> 
                                    <label> Approved Loan Amount</label>
                                    <h3>{formatMoney.format(requestData?.approvedFloat?.approvedAmount?requestData?.approvedFloat?.approvedAmount:0)}</h3>
                                    
                                </div>
                                <div className="form-input"> 
                                    <label> Loan Duration</label>
                                    <h3>{requestData?.approvedFloat?.loanTenure} months</h3> 
                                </div>
                                <div className="form-input"> 
                                    <label> Interest Rate </label>
                                    <h3>{requestData?.approvedFloat?.monthlyIntrestRate}%</h3> 
                                </div>
                                <div className="form-input"> 
                                    <label> Interest type</label>
                                    <h3 style={{textTransform: 'capitalize'}}>{requestData?.approvedFloat?.monthlyIntrestType}</h3> 
                                </div>
                        </div>
                    </Col>
                    <Col md={6} lg={8} >
                        <div className="form-breakdown" style={{background: '#FFFFFF', boxShadow: '0px 13.3657px 44.5523px rgb(160 163 189 / 50%)', borderRadius: '7px', padding:'2em 1em 0.5em'}}>
                            <h5>Loan Offer Breakdown</h5>
                            <div style={{margin:'0 -20px'}}>
                                <Table data={floatBreakdown} />
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            <WarningModal
                show={warning.show}
                isLoading={warning.action==="liquidate_w/_default"||warning.action==="liquidate"||warning.action==="next-repayment" ? liquidateLoading:lienLoading}
                handleClose={()=>setWarning({...warning, show:false})}
                handleAction={()=>warning.action==="liquidate_w/_default"||warning.action==="liquidate"||warning.action==="next-repayment" ? liquidateLoan() : requestHistory.lien?deactivateLien():activateLien()}
                title={
                    (warning.action==="liquidate_w/_default"||warning.action==="liquidate") ? "Liquidate Float"
                    : warning.action==="next-repayment" ? "Float Repayment" 
                    : "Float Lien"
                }
                text={
                    `Are you sure you want to ${
                        warning.action==="lien"? 
                        (requestHistory.lien?"deactivate lien on":"activate lien on") 
                        : 
                        warning.action==="next-repayment" ? "clear the next repayment of":"liquidate"
                    } this float?`
                }
            />
            <Modal show={showRepaymentOptions} size="md" onHide={()=>setShowRepaymentOptions(false)} aria-labelledby="contained-modal-title-vcenter" centered >
            <div className="float_modal">
                    <h5 className="text-[22px] font-medium -mb-2.5">Float Liquidate</h5>
                    <div className="float_modal-options">
                        <div className='radio'>
                            <p 
                                onClick={()=>setWarning({...warning, action:'next-repayment'})}
                            >
                                <input 
                                    type="radio" 
                                    value='next' 
                                    checked={warning.action==="next-repayment"}
                                />
                                    <label>Next Repayment</label>
                            </p>
                            <h5 className="!mb-2.5">{currencyFormatter(liquidatioData?.nextInstalment?.totalAmount || 0)}</h5>
                            <p
                                onClick={()=>setWarning({...warning, action:'liquidate'})}
                            >
                                <input type="radio" value='all' checked={warning.action==="liquidate"}/>
                                <label>Liquidate Loan</label>
                            </p>
                            <h5 className="!mb-2.5">{currencyFormatter(liquidatioData?.liquidationAmount || 0)}</h5>
                            <p
                                onClick={()=>setWarning({...warning, action:'liquidate_w/_default'})}
                            >
                                <input type="radio" value='all' checked={warning.action==="liquidate_w/_default"}/>
                                <label>Liquidate Loan + 5% fee</label>
                            </p>
                            <h5 className="!mb-2.5">{currencyFormatter(liquidatioData?.liquidationWithPenalty || 0)}</h5>
                        </div>
                    </div>
                    <button className="float_modal-action mt-3" onClick={()=>{setWarning({...warning, show:true}); setShowRepaymentOptions(false);}}>Continue</button>
                </div>
            </Modal>
        </div>
    )
}

export default OfferDetails;