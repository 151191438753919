import { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { ResetPasswordAction } from "../../lib/auth";
import { Link, useParams } from "react-router-dom";

import notvisible from "../../images/svg/auth/not-visible.svg";
import visible from "../../images/svg/auth/visible.svg";
import loader from "../../images/gif/white-loader.gif";

function ForgotPassword () {
    let params = useParams()
    const reset_token = params.token
    const [formValid, setFormValid] = useState(false)
    const [showPassword, setShowPassword] = useState(false);
    const [showConPassword, setShowConPassword] = useState(false);
    const [data, setData] = useState({
        password:"",
        confirmPassword:""
    })

    useEffect(() =>{
        if(data.password.length && data.confirmPassword.length){
            setFormValid(true)
        } 
    }, [data.password, data.confirmPassword])

    const handleChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    const doReset = (e) => {
        e.preventDefault();
        var btn = document.getElementById("actionBtn");
        btn.innerHTML=`<img src=${loader} width="25px" />`;

        let payload = JSON.stringify({
            password: data.password
        });

        if(data.confirmPassword !== data.password){
            toast.error("Passwords must match!!!")
        } else {
            ResetPasswordAction(reset_token, payload)
                .then((response) => response.data)
                .then((res) => {
                    console.log(res);
                    if (res.status === "success") {
                        toast.success(`Password reset successful, redirecting to login...`);
                        btn.innerHTML=`Reset Password`;
                        setTimeout( function (){
                            window.location = '/auth/login';
                        }, 2500);
                    } else {
                        toast.error("Something went wrong, please try again!!!")
                    }
                })
                .catch((err) => {
                    // console.log(err.response);
                    console.log(err);
                    if(err.response.data.message){
                        // toast.error(err.response.data.message);
                        btn.innerHTML=`Reset Password`;
                    } else{
                        toast.error("Something went wrong!!! Please make sure you have a good internet connection.");
                        btn.innerHTML=`Reset Password`;
                    }
                });
        }
    }

    return(
        <div className="auth_signup" >
            <ToastContainer />
            <h3>Set New Password</h3>
            <p>Your new password should be different from  previously used passwords</p>
            <form onSubmit={doReset} 
            >
                <div className="auth_signup-password">
                    <input type={showPassword?"text":"password"} required placeholder="Password" name="password" onChange={handleChange}  />
                    <img src={showPassword?visible:notvisible} alt="visible" className={showPassword?"visible":"notvisible"} onClick={()=>setShowPassword(!showPassword)} />
                </div>
                <div className="auth_signup-password">
                    <input type={showConPassword?"text":"password"} required placeholder="Confirm Password" name="confirmPassword" onChange={handleChange}  />
                    <img src={showConPassword?visible:notvisible} alt="visible" className={showConPassword?"visible":"notvisible"} onClick={()=>setShowConPassword(!showConPassword)} />
                </div>
                <button 
                    className= {formValid ? "auth_signup-active" : "auth_signup-submit"}
                    id="actionBtn"
                    disabled={!formValid}
                >
                    Reset Password
                </button>
                <p className="auth_signup-text1"><Link to="/auth/login"><span>Back to Login</span></Link></p>
            </form>
        </div>
    )
}

export default ForgotPassword;