import React, { useEffect, useState } from 'react'
import Table from 'components/Table/index.js';
import { mkConfig, generateCsv, download } from "export-to-csv";
import { handleRequestErrors } from 'utils/helpers.js';
import Status from 'components/Status.js';
import Button from 'components/shared/Button.js';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { SalesOpsService } from 'services/salesops.service.js';
import SearchField from 'components/shared/SearchField';

const SalesOps = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [tableData, setTableData] = useState([]);
    let navigate = useNavigate()
    const [searchParams] = useSearchParams();
    const search = searchParams.get("search")
    const page = searchParams.get("page") ?? 1

    useEffect(() => {
        setIsLoading(true)
        SalesOpsService.getAllBusinesses({
            businessName: search,
            page
        })
            .then(response => response.data)
            .then((res) => {
                setIsLoading(false)
                if(res.status === 'success'){
                    setTableData(res?.data)
                    setIsLoading(false)
                }
            } )
            .catch((err) => {
                handleRequestErrors(err)
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, page])

    const csvConfig = mkConfig({ 
        useKeysAsHeaders: false,
        columnHeaders: [
            { displayLabel: "ID", key: "_id" },
            { displayLabel: "Business Name", key: "businessName" },
            { displayLabel: "Email", key: "email" },
            { displayLabel: "Phone", key: "phoneNumber" },
            { displayLabel: "Status", key: "isActive" },
        ],
        filename: "sales-ops"
    });

    return (
        <div>
            <SearchField
                placeholder="Search by name, ID"
                containerClass='ml-auto my-6'
            />
            <Table
                loading={isLoading}
                data={tableData?.results||[]}
                backendPaginated={true}
                totalPages={tableData?.totalPages ?? 0}
                theadClass="!font-bold py-2.5 text-[#667085] bg-[#F0F0F0] text-sm"
                tdataClass="py-6 pl-[10px] text-sm"
                trClassName="shadow-[-1px_4px_17px_-11px_rgba(0,0,0,0.25)]"
                hideTableTop={true}
                hideOverallTableBorder={true}
                hideTableBodyBorder={true}
                hideLines={true}
                paginationContainer="!px-0"
                showDownload={true}
                onDownloadClick={()=>{
                  if(tableData?.results?.length>0){
                    const csv = generateCsv(csvConfig)(tableData?.results);
                    download(csvConfig)(csv)
                  }
                }}
                rows={[
                    {
                        header:'ID',
                        view: (item) => (
                            <div className='!w-[150px] text-ellipsis overflow-hidden'>
                                <span>
                                    #{item?._id||"---"}
                                </span>
                            </div>
                        ),
                    },
                    {
                        header:'Business Name',
                        view: (item) => (
                            <div className='flex items-center gap-x-1.5 max-w-[300px]'>
                                <div
                                    className='w-8 h-8 shrink-0 bg-no-repeat bg-center bg-cover'
                                    style={{
                                        backgroundImage: !!item?.logo ? `url(${item?.logo})` : "url('/profile-placeholder.svg')"
                                    }}
                                />
                                <span>{item?.businessName||"---"}</span>
                            </div>
                        ),
                    },
                    {
                        header:'Email',
                        view: (item) => (
                            <span className='lowercase'>
                                {item?.email||"---"}
                            </span>
                        ),
                    },
                    {
                        header:'Phone',
                        view: (item) => (
                            <span>
                                {item?.phoneNumber||"---"}
                            </span>
                        ),
                    },
                    {
                        header:'Status',
                        view: (item) => (
                            <span>
                                <Status 
                                    text={
                                        item?.isActive === 'pending'  ? "Pending" 
                                        :  item?.isActive === 'declined' ? "Declined"
                                        : item?.isActive === 'suspended' ? "Suspended" 
                                        : "Verified"
                                    }
                                    type={
                                        item?.isActive === 'pending' || 
                                            item?.isActive === 'declined' || 
                                                item?.isActive === 'suspended'  
                                                ? "unverified" : 'verified'
                                    }
                                    addClass="!text-sm py-1.5 px-4"
                                    hideDot={true}
                                />
                            </span>
                        ),
                    },
                    {
                        header:'Action',
                        view: (item) => (
                            <Button
                                text="View"
                                onClick={()=>{
                                    navigate(`/sales-ops/${item?._id}`)
                                }}
                                className="bg-[#47D800] px-3 py-1 !text-sm !rounded"
                            />
                        ),
                    },
                ]}
            />
        </div>
    )
}

export default SalesOps