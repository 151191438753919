import {BrowserRouter, Routes, Route} from "react-router-dom";
import ProtectedRoutes from "./components/ProtectedRoute";
import Dashboard from "./pages/Dashboard";
import Auth from "./pages/Auth";
import "./scss/main.css";
import "./index.css";
import 'react-toastify/dist/ReactToastify.css';
import 'react-tooltip/dist/react-tooltip.css'
import CreateAccount from "./components/Auth/CreateAccount";
import { ToastContainer } from "react-toastify";


function App() {

  return (
    <div>
      <ToastContainer style={{zIndex: "10000"}} />
      <BrowserRouter>
        <Routes>
          <Route path="auth/*" element={<Auth/>}/>
          <Route path="admin/invite/:adminId" element={<CreateAccount />} />
          <Route element={<ProtectedRoutes />}>
            <Route path="/*" element={<Dashboard  />}/>
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
