import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import Table from '../../../components/Table';
import TopBanks from './components/TopBanks';
import { formatBigNumber, handleRequestErrors } from 'utils/helpers';
import DataBox from 'components/shared/DataBox';
import bank from 'images/svg/box/bank.svg'
import { BankService } from 'services/bank.service';
import SearchField from 'components/shared/SearchField';
import dayjs from 'dayjs';
import { mkConfig, generateCsv, download } from "export-to-csv";
import { useSearchParams } from 'react-router-dom';
import Filter from 'components/shared/Filter';
import selectArrow from "images/svg/select-arrow.svg";

const Banks = ({setDetailName}) => {
    const [data, setData] = useState(null)
    const [tableData, setTableData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [searchParams] = useSearchParams();
    const search = searchParams.get("search")
    const query = searchParams.get("filter") || "all"
    // const startDate = searchParams.get("customStart")||""
    // const endDate = searchParams.get("customEnd")||""

    useEffect(() => {
        setDetailName("");
    }, [setDetailName]);

    useEffect(() => {
        const filtered = tableData?.filter((item) => 
            item?.businessId?.businessName?.toLowerCase()?.includes(search.toLowerCase()) || 
            item?.institution?.name?.toLowerCase()?.includes(search.toLowerCase()) || 
            item?.name?.toLowerCase()?.includes(search.toLowerCase()) || 
            item?.accountNumber?.toLowerCase()?.includes(search.toLowerCase())
        )
        if(!!search){
            setTableData(filtered)
        } else {
            setTableData(data?.totalAccountLinkedData)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search])
  
    useEffect(() => {
        getBankStat()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query])

    const getBankStat = () => {
        setIsLoading(true)
        BankService.bankStats(query)
            .then(response => response.data)
            .then((res) => {
                if(res.status === 'success'){
                    console.log("stats", res.data)
                    setData(res.data)
                    setTableData(res?.data?.totalAccountLinkedData?.reverse())
                    setIsLoading(false)
                }    else {
                    toast.error("Something went wrong, please try again!!!")
                    setIsLoading(false)
                }
            })
            .catch((err) => {
                setIsLoading(false)
                handleRequestErrors(err)
            });
    }

    const csvConfig = mkConfig({ 
        useKeysAsHeaders: false,
        columnHeaders: [
            { displayLabel: "User Name", key: "name" },
            { displayLabel: "Bank Name", key: "institution.name" },
            { displayLabel: "Business Name", key: "businessId.businessName" },
            { displayLabel: "Account Number", key: "accountNumber" },
            { displayLabel: "Date", key: "updatedAt" },
        ],
        filename: "banks"
    });

    const filterOptions = [
      { label: "All", value: "all"},
      { label: "Last 7 days", value: "last_seven_days"}, 
      { label: "This Month", value: "this_month"}, 
      { label: "Last Month", value: "last_month"}, 
      { label: "Last 3 Months", value: "last_three_months"}, 
      { label: "Last 6 Months", value: "last_six_months"}, 
      { label: "Last 12 Months", value: "last_twelve_months"}, 
      { label: "Custom", value: "custom"},
    ]

    return (
        <div>
            <div className='flex justify-end mb-10'>
              <Filter
                placeHolder="All"
                options={
                  filterOptions.map((item) => ({
                    label: item.label,
                    value: item.value
                  }))
                } 
                containerClass="border-ee-primary text-base text-[#8A8A8A] flex-row-reverse"
                icon={selectArrow}
                iconClassName="!w-[10px]"
                optionItemClass="text-sm"
              />
            </div>
            <div className='grid grid-cols-3 gap-x-10 box-border mt-7'>
                <div className='col-span-1'>
                    <div className='flex flex-col gap-y-[20px] box-border'>
                        {
                            [
                                {
                                    title: "Total Bank Account Linked as at today",
                                    value: formatBigNumber(data?.totalAccountLinkedToday || 0, true),
                                    icon: bank,
                                },
                                {
                                    title: "Bank Accounts Linked",
                                    value: formatBigNumber(data?.totalAccountLinked || 0, true),
                                    icon: bank,
                                },
                            ].map(item => (
                                <div key={item.title}>
                                    <DataBox 
                                        title={item.title}
                                        icon={<img src={item.icon} alt={item.title} className='w-[20px]' />}
                                        value={item.value}
                                        className="!shadow-[0px_13.3657px_44.5523px_rgba(160,163,189,0.5)]"
                                    />
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className='col-span-2 bg-white rounded-[10px] p-[15px] shadow-[0px_13.3657px_44.5523px_rgba(160,163,189,0.5)]'>
                    <TopBanks query={query} />
                </div>
            </div>
            <div className='my-6'>
                <div className='flex justify-between items-center'>
                    <p className='text-xl font-bold'>Recent Bank Accounts Linked</p>
                    <SearchField
                        containerClass='ml-auto my-6'
                    />
                </div>
                <Table
                    loading={isLoading}
                    data={tableData || []}
                    theadClass="!font-bold py-2.5 text-[#667085] bg-[#F0F0F0] text-sm"
                    tdataClass="py-6 pl-[10px] text-sm"
                    trClassName="shadow-[-1px_4px_17px_-11px_rgba(0,0,0,0.25)]"
                    hideTableTop={true}
                    hideOverallTableBorder={true}
                    hideTableBodyBorder={true}
                    hideLines={true}
                    paginationContainer="!px-0"
                    showDownload={true}
                    onDownloadClick={()=>{
                        if(tableData.length>0){
                            const csv = generateCsv(csvConfig)(tableData);
                            download(csvConfig)(csv)
                        }
                    }}
                    rows={[
                        {
                            header:'Bank Name',
                            view: (item) => (
                                <span className='capitalize'>
                                    {item?.institution?.name||"---"}
                                </span>
                            ),
                        },
                        {
                            header:'User Name',
                            view: (item) => (
                                <span>
                                    {item?.name||"---"}
                                </span>
                            ),
                        },
                        {
                            header:'Business Name',
                            view: (item) => (
                                <span>
                                    {item?.businessId?.businessName||"---"}
                                </span>
                            ),
                        },
                        {
                            header:'Account Number',
                            view: (item) => (
                                <span>
                                    {item?.accountNumber||"---"}
                                </span>
                            ),
                        },
                        {
                            header:'Date',
                            view: (item) => (
                                <span>
                                    {dayjs(item?.updatedAt).format("DD/MM/YYYY")}
                                </span>
                            ),
                        },
                    ]}
                />
            </div>
        </div>
    )
}

export default Banks