import {Routes, Route, Navigate} from "react-router-dom";
import Login from "../components/Auth/Login";
import CreateAccount from "../components/Auth/CreateAccount";
// import Otp from "../components/Auth/Otp";
import ForgotPassword from "../components/Auth/ForgotPassword";
import ResetPassword from "../components/Auth/ResetPassword";

import pattern from "../images/svg/auth/pattern.png";
import CheckMail from "../components/Auth/CheckMail";
import AccountSuccess from "../components/Auth/AccountSuccess";

function Auth () {
    
   
    return(
        <div className="auth">
            <div className="auth_layout">
                <div className="auth_section1">
                    <div className="auth_section1_container">
                        <Routes>
                            <Route path="/" element={<Navigate to="/auth/login" />}/>
                            <Route path="/admin/invite/:adminId" element={<CreateAccount/>}/>
                            <Route path="/login" element={<Login/>}/>
                            <Route path="/forgot-password" element={<ForgotPassword/>}/>
                            <Route path="/reset-password/:token" element={<ResetPassword/>}/>
                            <Route path="/account-success" element={<AccountSuccess/>}/>
                            <Route path="/check-mail" element={<CheckMail/>}/>
                        </Routes>
                    </div>
                </div>
                <div className="auth_section2">

                    <div className="auth_section2-pattern" >
                    <img src={pattern} alt="pattern" />
                    
                    
                    
                      <h3> The Eagle Eye Platform</h3>
                      <p>CreditCheK Eagle Eye lets you view insights and data in realtime.</p>
                   
                </div>
                </div>
            </div>
        </div>
    )
}

export default Auth;