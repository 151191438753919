import React, { useEffect, useState } from 'react'
import { Modal } from "react-bootstrap";
import {  floatPaymentHistory, floatStats } from '../../../lib/auth';
import { toast, ToastContainer } from 'react-toastify';
import Box from '../../../components/Box'
import Table from '../../../components/Table/general.js';
// import Modal from '../../Modal';
// import search from '../../../images/svg/search.svg'
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/theme/default.css';
import 'react-date-range/dist/styles.css';
import SelectInput from '../../../components/Form/SelectInput';
import Loader from '../../../components/shared/Loader';

const PaymentHistory = ({setHeader}) => {
    const [query, setQuery] = useState('all')
    const [queryVal, setQueryVal] = useState('all')
    // const [searchTerm, setSearchTerm] = useState('');
    // const [filteredData, setFilteredData] = useState([]);
    const [startDateee, setStartDateee] = useState("");
    const [endDateee, setEndDateee] = useState("");
    const [show, setShow] = useState(false);
    const [isBoxLoading, setIsBoxLoading] = useState(false);
    const [isTableLoading, setIsTableLoading] = useState(false);

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
]);

// const handleChange = (e) => {
//   setSearchTerm(e.target.value)
// }

const handleClose = () => setShow(false);

function formatPickerDate(date) {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [year, month, day].join('-');
}

    const [boxData, setBoxData] = useState({
      boxFor: "float-history",
      item: []
    });
    const [data, setData] = useState({
      tableFor: "float-history",
      headings: ["Payment ID", "Loan Amount", "Date",  "Time"],
      items: []
  });

useEffect(()=>{
  setHeader();
  setIsTableLoading(true)
  floatPaymentHistory(query)
        .then((response) => response.data)
        .then((res) => {
            if (res.status === 'success') {
                // setFilteredData(res.data)
                setData({
                  ...data,
                  items: res.data
                })
                setIsTableLoading(false)
            } else {
                toast.error("Something went wrong, please try again!!!")
                setIsTableLoading(false)
            }
        })
        .catch((err) => {
            if(err.response){
                toast.error(err.response.data.message);
                setIsTableLoading(false)
            } else{
                toast.error("Something went wrong!!! Please make sure you have a good internet connection.");
                setIsTableLoading(false)
            }
        });
// eslint-disable-next-line react-hooks/exhaustive-deps
},[query])

// useEffect(() => {
//   const filtered = filteredData?.filter((item) => 
//   item?.businessName?.toLowerCase()?.includes(searchTerm.toLowerCase()) || 
//   item?.businessSector?.toLowerCase()?.includes(searchTerm.toLowerCase()) || 
//   item?.email?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
//   item?.phoneNumber?.toLowerCase()?.includes(searchTerm.toLowerCase())
//   )
//   setData({
//     ...data,
//     items: filtered
//   })
//    // eslint-disable-next-line react-hooks/exhaustive-deps
// }, [searchTerm, filteredData])

const handleSelectChange = (name, val) => {
  if
  (val === 'All'){
    setQuery('all')
  }else if
  (val=== 'Last 7 days'){
    setQuery('last_seven_days')
    setQueryVal('Last 7 days')
  }else if(val === 'This Month'){
    setQuery('this_month')
    setQueryVal('This Month')
  }else if(val === 'Last Month'){
    setQuery('last_month')
    setQueryVal('Last Month')
  }else if(val === 'Last 3 Months'){
    setQuery('last_three_months')
    setQueryVal('Last 3 Months')
  }else if(val === 'Last 6 Months'){
    setQuery('last_six_months')
    setQueryVal('Last 6 Months')
  }else if(val === 'Last 12 Months'){
    setQuery('last_twelve_months')
    setQueryVal('Last 12 Months')
  }else if(val === 'Custom'){
    setQuery('custom')
    setQueryVal('Custom')
    setShow(true)
  }
}

useEffect(()=>{
  setIsBoxLoading(true)
  floatStats(query, "naira")
    .then((response) => response.data)
    .then((res) => {
        if (res.status === 'success') {
            setBoxData({
              ...boxData,
              item:res.data
            })
            setIsBoxLoading(false)
        } else {
            toast.error("Something went wrong, please try again!!!")
            setIsBoxLoading(false)
        }
    })
    .catch((err) => {
        if(err.response){
            toast.error(err.response.data.message);
            setIsBoxLoading(false)
        } else{
            toast.error("Something went wrong!!! Please make sure you have a good internet connection.");
            setIsBoxLoading(false)
        }
    });
// eslint-disable-next-line react-hooks/exhaustive-deps
},[query])  

console.log(data)
    return (
      <div className='home_main'>
        <ToastContainer />

        <Modal show={show} onHide={handleClose}>
          <DateRange
              editableDateInputs={true}
              onChange={item => {
                      setStartDateee(formatPickerDate(item.selection.startDate))
                      setEndDateee(formatPickerDate(item.selection.endDate))
                      setState([item.selection])
                  }
              }
              moveRangeOnFirstSelection={false}
              ranges={state}
          />
          <button 
              style={{
                  color:"white",
                  padding:"7px 25px",
                  textAlign:"center",
                  border:"1px solid #0046E6",
                  background:"#0046E6"
              }} 
              onClick={()=>{   
                      handleClose();
                      setQuery(`custom&start=${startDateee}&end=${endDateee}`)
                  }}
              >Proceed</button>
        </Modal>

        <div className='w-[150px] block ml-auto'>
          
          <SelectInput 
              placeholder={"All"}
              options={["All", "Last 7 days", "This Month", "Last Month", "Last 3 Months",  "Last 6 Months", "Last 12 Months", "Custom"]}
              selectChange={(item)=>handleSelectChange("Query", item)}
          />
        </div>
        {
          isBoxLoading ?
          <Loader /> :
          <Box boxData={boxData} query={queryVal} />
        } 
        <div className='home_main-tableHeader' >
          <div className="table_top" style={{display:"flex", alignItems:"center"}}>
              <div>
                <h4>Payment History</h4>
              </div>
          </div>
        </div>
        {
          isTableLoading ?
          <Loader /> :
          <Table 
            data={data} 
         />
        }
          
      </div>
    )
  }

export default PaymentHistory