import { APIKit, loadToken } from 'services';


export const ApiService = {
    getCummulativeData: (args) => {
        return APIKit(90000).get(`eagleeyeauth/api/data-stats`, {
            headers: {
                Authorization: `Bearer ${loadToken()}`
            },
            params: {
                ...args,
            }
        })
    },

    getApiCallStats: (args) => {
        return APIKit(90000).get(`eagleeyeauth/business/apicall-activity`, {
            headers: {
                Authorization: `Bearer ${loadToken()}`
            },
            params: {
                ...args,
            }
        })
    },

    apiStats: (args) => {
        return APIKit(90000).get(`eagleeyeauth/api/stats`, {
            headers: {
                Authorization: `Bearer ${loadToken()}`
            },
            params: {
                ...args,
            }
        })
    },
}