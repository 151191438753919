import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { LoginAction } from "../../lib/auth";
import {Link} from "react-router-dom";
import notvisible from "../../images/svg/auth/not-visible.svg";
import visible from "../../images/svg/auth/visible.svg";
import loader from "../../images/gif/white-loader.gif";


function Login () {
    const [formValid, setFormValid] = useState(false)
    const [showPassword, setShowPassword] = useState(false);
    const [data, setData] = useState({
        email:"",
        password:""
    })
    // let businessId = ''

    useEffect(()=> {
        if (data.email.length && data.password.length) {
            setFormValid(true)
        } else {
            setFormValid(false)
        }
    }, [data.email, data.password])

    const handleChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
        if(data.email.length&&data.password.length){
            setFormValid(true)
        }
    }

    const doLogin = (e) => {
        e.preventDefault();
        var btn = document.getElementById("actionBtn");
        btn.innerHTML=`<img src=${loader} width="25px" />`;
        const { email, password } = data;
        
        let payload = JSON.stringify({
            email: email,
            password: password
        });

        LoginAction(payload)
            .then((response) => response.data)
            .then((res) => {
                if (res.status === "success") {
                    toast.success(`Login successful! Redirecting to dashboard...`);
                    sessionStorage.setItem(
                        "eagleEye_details",
                        JSON.stringify({
                            email: res.data.admin.email,
                            adminRole: res.data.admin.role,
                            token: res.data.tokens.access.token,
                            refreshToken: res.data.tokens.refresh.token,
                            adminId: res.data.admin.id,
                            profilePhoto: res.data.admin.profilePhoto,
                        }) 
                    );
                    sessionStorage.setItem("adminName", JSON.stringify(res.data.admin.name));
                
                    btn.innerHTML=`Login`;
                    setTimeout(() => {
                        if(res.data.admin.role==="Life Gate"){
                            window.location = '/third-party-apps';
                        } else {
                            window.location = '/';
                        }
                    }, 1500);
               
                  
         
            }
                 else {
                    toast.error("Something went wrong, please try again!!!");
                    btn.innerHTML=`Login`;
                }
            })
            .catch((err) => {
                if(err.response){
                    toast.error(err.response.data.message);
                    btn.innerHTML=`Login`;
                } else{
                    toast.error("Something went wrong!!! Please make sure you have a good internet connection.");
                    btn.innerHTML=`Login`;
                    console.log('casted')
                }
            });
    }

    return(
        <div>
            <ToastContainer />
            <form className="auth_signup" onSubmit={doLogin} >

                <h3> Login</h3>
                <p>Welcome to CreditChek Eagle Eye. Please input your credentials to get started</p>

                <label> Email
                <input type="email" required placeholder="Email Address" name="email" onChange={handleChange} />
                </label>

                <div className="auth_signup-password">
                   
                    <label> Password
                    <input type={showPassword?"text":"password"} required placeholder="Password" name="password" onChange={handleChange}  />
                    <img src={showPassword?visible:notvisible} alt="visible" className={showPassword?"visible":"notvisible"} onClick={()=>setShowPassword(!showPassword)} />
                     </label>
                </div>

                <p className="auth_signup-forgot"> <Link to="/auth/forgot-password"> Forgot Password?</Link>  </p>
                <button 
                    className= {formValid?"auth_signup-active":"auth_signup-submit"}
                    id="actionBtn"
                    disabled={!formValid}
                >
                    Login
                </button>
                {/* <p className="auth_signup-text1">Don't have an account? <Link to="/auth/create-account"><span>Sign up</span></Link></p> */}
                 </form>
        </div>
    )
}

export default Login;