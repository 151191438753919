import { useState } from "react";
import selectArrow from "../../images/svg/select-arrow.svg";

function  SelectInput ({placeholder, options, selectChange, arrayType}) {
    const [selected, setSelected] = useState(placeholder);
    const [showOptions, setShowOptions] = useState(false);
    return(
        <div className="select">
            <div className="select_box" onClick={()=>setShowOptions(!showOptions)}>{selected}</div>
                { showOptions &&
                    <div className="select_options">
                        {
                            arrayType === "countryObject" ?
                            options?.map((item, idx)=>(
                                <p 
                                    className={selected===item.name?"select_options_selected":""} 
                                    key={idx} 
                                    onClick={()=>{
                                        setSelected(item.name); 
                                        setShowOptions(false); 
                                        selectChange(item.name, item.id)
                                    }}
                                >
                                    {item.name}
                                </p>
                            )) :
                            options.map((item, idx)=>(
                                <p 
                                    className={selected===item?"select_options_selected":""} 
                                    key={idx} 
                                    onClick={()=>{
                                        setSelected(item); 
                                        setShowOptions(false); 
                                        selectChange(item)
                                    }}
                                >
                                    {item}
                                </p>
                            )) 
                        }
                    </div>
                }
            <img src={selectArrow} alt="arrow" className="select_arrow" />
        </div>
    )
}

export default SelectInput;