import React, { useEffect, useState } from 'react'
import { apiStats } from '../../../lib/auth';
import ApiCallTypes from './ApiTypes';
import Table from '../../../components/Table';
import Filter from 'components/shared/Filter';
import selectArrow from "images/svg/select-arrow.svg";
import { useSearchParams } from 'react-router-dom';
import { currencyFormatter } from 'utils/helpers';
import dayjs from 'dayjs';
import Status from 'components/Status';


const Apis = ({ setDetailName }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [searchParams] = useSearchParams();
    const query = searchParams.get("filter") || "all"
    const startDate = searchParams.get("customStart")||""
    const endDate = searchParams.get("customEnd")||""

    useEffect(() => {
        setDetailName("");
    }, [setDetailName]);

    const [boxData, setBoxData] = useState({
        boxFor: "apis",
        item: []
    });

    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        setIsLoading(true)
        apiStats({
            type: query,
            start: startDate,
            end: endDate,
        })
            .then(response => response.data)
            .then((res) => {
                if (res.status === 'success') {
                    setBoxData({
                        ...boxData,
                        item: res.data
                    })
                    setFilteredData(res?.data?.totalApiData);
                }
                setIsLoading(false)
            })
            .catch(() => {
                setIsLoading(false)
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query])

    useEffect(() => {
        const filtered = filteredData?.filter((item) =>
            item?.service?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
            item?.action?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
            item?.businessName?.toLowerCase()?.includes(searchTerm.toLowerCase())
        )
        setTableData(filtered)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTerm, filteredData])

    const handleChange = (e) => {
        setSearchTerm(e.target.value)
    }

    const filterOptions = [
        { label: "All", value: "all"},
        { label: "Last 7 days", value: "last_seven_days"},
        { label: "This Month", value: "this_month"},
        { label: "Last Month", value: "last_month"},
        { label: "Last 3 Months", value: "last_three_months"},
        { label: "Last 6 Months", value: "last_six_months"},
        { label: "Last 12 Months", value: "last_twelve_months"},
        { label: "Custom", value: "custom"},
    ]

    return (
        <div>
            <div className='flex justify-end mb-10'>
                <Filter
                    placeHolder="All"
                    options={
                        filterOptions.map((item) => ({
                            label: item.label,
                            value: item.value
                        }))
                    }
                    containerClass="border-ee-primary text-base text-[#8A8A8A] flex-row-reverse"
                    icon={selectArrow}
                    iconClassName="!w-[10px]"
                    optionItemClass="text-sm text-xs font-semibold p-2 rounded-md m-0 hover:bg-ee-grey-3"
                />
            </div>
            <ApiCallTypes 
                queryVal={filterOptions.find(x => x.value===searchParams.get("filter"))?.label || ""} 
                query={{
                    type: query,
                    start: startDate,
                    end: endDate,
                }} 
            />
            <div className='my-5'>
                <Table
                    loading={isLoading}
                    data={tableData || []}
                    theadClass="!font-bold py-2.5 text-[#667085] bg-[#F9FAFB] text-sm"
                    tdataClass="py-6 pl-[10px] text-sm"
                    noTitleIcon={true}
                    tableTitle="API Calls"
                    hideSearch={false}
                    searchPlaceHolder={"Search api calls"}
                    onSearchChange={handleChange}
                    hideTableBodyBorder={true}
                    hideLines={true}
                    showDownload={false}
                    rows={[
                        {
                            header:'Service Endpoint',
                            view: (item) => (
                                <span className='capitalize'>{item?.service||"---"}</span>
                            ),
                        },
                        {
                            header:'Action',
                            view: (item) => (
                                <span className='capitalize'>{item?.action||"---"}</span>
                            ),
                        },
                        {
                            header:'Business',
                            view: (item) => (
                                <div className='max-w-[300px]'>
                                    <span>{item?.businessName||"---"}</span>
                                </div>
                            ),
                        },
                        {
                            header:'Total Cost',
                            view: (item) => (
                                <span>{currencyFormatter(item?.amount||0)}</span>
                            ),
                        },
                        {
                            header:'When',
                            view: (item) => (
                                <span>
                                    {dayjs(item?.created_at).format("DD/MM/YYYY hh:mmA")}
                                </span>
                            ),
                        },
                        {
                            header:'Status',
                            view: (item) => (
                                <Status 
                                    text={item?.status}
                                    type={
                                        item?.status === 'success' ? "verified" : 'unverified'
                                    }
                                    addClass="!text-sm py-1.5 px-4"
                                    hideDot={true}
                                />
                            ),
                        },
                    ]}
                />
            </div>
        </div>
    )
}

export default Apis