import {Container} from "react-bootstrap";
import {Routes, Route, useLocation} from "react-router-dom";
import { useState, useEffect } from "react";
import Roles from "./Roles";
import Main from "./Main";
import RolesDetails from "./sub-component/RolesDetails";
import Permissions from "./sub-component/Permissions";

function Team () {
    const location = useLocation().pathname;
    const [path, setPath] = useState("member");
    
    useEffect(()=>{
        setPath(location);
    }, [path, location]);

    return(
        <div>
            
            <Container>
                <Routes>
                    
                    <Route path="/" element={<Main />}/>
                    <Route path="/roles" element={<Roles />} />
                    <Route path="/roles/:role" element={<RolesDetails />} />
                    <Route path="/roles/permission/:id" element={<Permissions />} />
                </Routes>
            </Container>
        </div>
    )
}

export default Team;