import { toast } from 'react-toastify';
import jsPDF from "jspdf";
import * as htmlToImage from 'html-to-image';


export const dateFormatter = (
  date,
  withTime=false,
) => {
  let result = ''; 
  const d = new Date(date).getDate();
  const m = new Date(date).getMonth();
  const y = new Date(date).getFullYear(); 

  // time
  let time = new Date(date);
  var hours = time?.getHours();
  var minutes = time?.getMinutes();
  var ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0'+minutes : minutes;
  var strTime = hours + ':' + minutes + ampm;

  // 29/01/2023
  result = `${d<10 ? '0':""}${d}/${m<9 ? '0':""}${m+1}/${y}${withTime ? `, ${strTime}`:""}`
  return result;
}

export const handleRequestErrors = (err, noMessageToast=false, timeout="15000") => {
    if(err.response){
        !noMessageToast && toast.error(err.response.data.message);
    } else{
        if(err.message === `timeout of ${timeout}ms exceeded`){
            toast.error('Request timed out, please try again!')
        } else if (err.message==="Network Error"){
            toast.error('Network error, please make sure you are connected to the internet!')
        } else{
            toast.error("Your request can't be processed at this time, please try again later!");
        }
    }
}

export const currencyFormatter = (
    amount, 
    currency="NGN", 
    maxDigits=2,
    compact=false,
) => {
    var formatMoney = new Intl.NumberFormat(
        currency==="USD" ? 'en-US':'en-NG', 
        {
            style: 'currency',
            currency: currency,
            maximumFractionDigits: maxDigits, 
            compactDisplay: "short",
            notation: compact ? "compact" : undefined,
        }
    );
    return formatMoney.format(isNaN(amount)||amount===Infinity ? 0:amount)
}

export const formatBigNumber = (num, withCommas=false) => {
    if(!withCommas){
        if (num >= 1000000) {
            return (num / 1000000).toFixed(1) + 'M';
        } else if (num >= 1000) {
            return (num / 1000).toFixed(1) + 'K';
        } else {
            return num.toString();
        }
    } else {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
}

export const generatePDF = (
    id, 
    fileName, 
    handleSuccess,
    toBeDownloaded=true,
) => {
    const content = document.getElementById(id);
    const pdf = new jsPDF('p', 'mm', 'a4', true); // true value indicates that pdf should be compressed
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();

    let yOffset = 0;

    const addPage = () => {
        htmlToImage
            .toPng(content, {
                quality: 0.95,
                style: {
                    transform: `translateY(-${yOffset}px)`,
                },
            })
            .then((dataUrl) => {
                const imgProps = pdf.getImageProperties(dataUrl);
                const imgHeight = (imgProps.height * pdfWidth) / imgProps.width;

                if (yOffset !== 0) pdf.addPage();
                pdf.addImage(dataUrl, 'PNG', 0, 0, pdfWidth, imgHeight);

                yOffset += pdfHeight * 3.7795275591;

                if (yOffset < content.scrollHeight) {
                    addPage();
                } else {
                    if(toBeDownloaded){
                        pdf.save(`${fileName}.pdf`);
                        handleSuccess()
                    } else {
                        let blob = pdf.output('blob') // blob/datauristring/pdf
                        handleSuccess(blob)
                    }
                }
            });
    };

    addPage();
}

export const capitalizeFirstLetter = (item) => {
    let capitalizedString = item.charAt(0).toUpperCase() + item.slice(1);
    return capitalizedString;
}

export const getInitials = function (string) {
    var names = string?.split(' '),
    initials = names?.[0]?.substring(0, 1).toUpperCase();
    if (names?.length > 1) {
        initials += names?.[names?.length - 1]?.substring(0, 1).toUpperCase();
    }
    return initials;
};