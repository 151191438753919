import Pagination from "./Pagination";
import { useState } from "react";
import { GrDocumentText } from "react-icons/gr";
import clsx from "clsx";
import tableIcon from "images/svg/table-icon.svg";
import search from "images/svg/search.svg";
import { useDebouncedCallback } from "use-debounce";


export default function Table ({
    tableTop=null,
    data,
    rows,
    trClassName,
    loading=false,
    theadClass,
    tdataClass,
    tableClassName="",
    tableShadow=false,
    showDownload=false,
    onDownloadClick,
    hideLines=false,
    hideSearch=true,
    searchPlaceHolder,
    onSearchChange,
    titleIcon=tableIcon,
    noTitleIcon=false,
    tableTitle, 
    slot,
    hideTableTop=false,
    hideOverallTableBorder=false,
    hideTableBodyBorder=false,
    paginationContainer="",
    backendPaginated=false,
    totalPages, // optional
    tableTopClassName="",
    searchContainerClassName="",
}) {
    const [displayData, setDisplayData] = useState([]);

    const debounced = useDebouncedCallback((e) => {
        onSearchChange(e);
    }, 1000);

    return(
        <div 
            className={clsx(
                tableShadow && "shadow-2xl rounded-[10px]"
            )}
        >
            {
                !hideTableTop &&
                <div  
                    className={clsx(
                        "border border-solid border-cc-grey-1 py-2.5 px-2.5 mt-[30px] relative rounded-t-lg",
                        tableTopClassName,
                    )}
                >
                    {
                        !!tableTop ?
                        tableTop 
                        :
                        <div className="flex items-center max-[560px]:block">
                            <div 
                                className={clsx(
                                    "flex items-center",
                                    hideSearch && "my-2.5",
                                )}
                            >
                                <img 
                                    src={titleIcon} 
                                    alt="table" 
                                    className={clsx(
                                        "mr-1.5 w-[25px] -mt-[3px]",
                                        noTitleIcon && "hidden"
                                    )} 
                                />
                                <h4 className="mt[3px] text-[18px] font-medium">{tableTitle}</h4>
                            </div>
                            <div className="ml-auto flex gap-x-4 items-center max-[560px]:mt-[15px]">
                                {
                                    !hideSearch &&
                                    <div 
                                        className={clsx(
                                            "ml-auto rounded flex w-[230px] h-10 items-center px-[10px] py-[3px] bg-white border border-solid border-cc-grey-2 border-box max-[769px]:ml-auto max-[560px]:w-[100%]",
                                            searchContainerClassName
                                        )}
                                    >
                                        <input 
                                            placeholder={searchPlaceHolder} 
                                            onChange={debounced} 
                                            className="leading-4 opacity-50 flex-1 bg-transparent outline-none border-0 pl-[8px] font-normal text-sm text-cc-black-1"/>
                                        <img src={search} alt="search" />
                                    </div>
                                }
                                {slot}
                            </div>
                        </div>
                    }
                </div>
            }
            <div 
                className={clsx(
                    "border-box pb-[15px] relative rounded-b-lg capitalize",
                    !hideOverallTableBorder && "border border-solid border-cc-grey-1"
                )
            }>
                   {
                    loading &&
                    <div className=" h-1 bg-white absolute w-full overflow-hidden">
                        <div className="h-1 bg-ee-primary loader-loop"></div>
                    </div>
                }
                {
                    data?.length>0 ?
                    <div>
                        <div className="overflow-x-auto">
                            <table 
                                className={clsx(
                                    "w-[100%]",
                                    tableClassName,
                                )}
                            >
                                <thead className="border-b border-solid border-cc-grey-1">
                                    <tr className="border-b border-solid border-cc-grey-1 text-cc-grey-4 text-sm font-medium text-base">
                                        {
                                            rows.map((item,idx)=>(
                                                <th 
                                                    className={clsx(
                                                        "py-[12px] pl-[10px] pr-[5px] text-cc-grey-8 font-normal capitalize",
                                                        theadClass,
                                                    )}
                                                    key={idx}
                                                >
                                                    {item.header}
                                                </th>
                                            ))
                                        }
                                    </tr>
                                </thead>
                                <tbody 
                                    className={clsx(
                                        !hideTableBodyBorder && "border border-solid border-cc-grey-1"
                                    )}
                                >
                                    {
                                        (backendPaginated ? data : displayData)?.map((item, itemIndex) =>(
                                        <tr 
                                            key={itemIndex} 
                                            className={clsx(
                                                "text-cc-black-2 text-base",
                                                trClassName,
                                                !hideLines && "border-b border-solid border-cc-grey-1",
                                            )}
                                        >
                                            {
                                                rows.map((row)=>{
                                                    const view = row.view(item, itemIndex);
                                                    return <td className={tdataClass}>{view}</td>
                                                })
                                            }
                                        </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className={clsx("pt-2.5 px-2.5", paginationContainer)}>
                            <Pagination
                                setDisplayData={(items)=>setDisplayData(items)}
                                data={data}
                                showDownload={showDownload}
                                onDownloadClick={onDownloadClick}
                                backendPaginated={backendPaginated}
                                totalPages={totalPages}
                            /> 
                        </div>
                    </div>
                    :
                    <div className="py-[100px] mx-[20px] text-center">
                        <GrDocumentText className="mx-auto mb-2.5 block" size="50px" />
                        <h1 className="text-xl font-bold">Empty</h1>
                        <p>No data available</p>
                    </div>
                }
            </div>
        </div>
    )
}