import { useEffect, useState } from "react";

function Idle({ cancel }) {
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(30);

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
          window.location="/auth/login"
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });
  return (
    <div className="myModal_main-widget" style={{ textAlign: "center" }}>
      <h4>Idle Session</h4>
      <form>
        <div className="myModal_main-linkBank">
          <div>
            <p>
              You have been inactive for 10mins and are about to be logged out,
              do you still want to remain logged in?
            </p>
            <h3 style={{ color: "#0046E6" }}>
              {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
            </h3>
          </div>
        </div>
      </form>
      <div className="myModal_main-widget-action">
        {/* <button className="cancel">Cancel</button> */}
        <button className="publish" onClick={cancel}>
          Stay Logged In
        </button>
      </div>
    </div>
  );
}

export default Idle;
