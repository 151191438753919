import { useState } from 'react'
import { Container } from 'react-bootstrap'
import { Link, Route, Routes } from 'react-router-dom'
import Details from './Details'
import home from '../../../images/svg/float/home.svg'
import paymentHistory from '../../../images/svg/float/paymentHistory.svg'
import Main from './Main'
import ApiSecurity from './ApiSecurity'


const TechSupport = ({setDetailName}) => {
  const [requestPath, setRequestPath] = useState("home");
  const [header, setHeader] = useState(true);
  
  return (
    <div className='home_main'>
          <Container>

          {
                  header &&
                <div className='float_top'>
                    <Link to='/tech-support' >
                        <div 
                            className={requestPath==="home"?"float_top-toggle active":'float_top-toggle'}
                            onClick={()=> { 
                                setRequestPath("home")
                            }}
                        >
                            <img src={home} alt="icon" />
                            <p> Home </p>
                        </div>
                    
                    </Link>
                    
                    <Link to='/tech-support/api-security'>
                        <div 
                            className={requestPath==="payment-history"?"float_top-toggle active":'float_top-toggle'}  
                            style={{marginLeft: '1.5em'}}
                            onClick={()=> { 
                                setRequestPath("payment-history")
                            }}
                            >
                            <img src={paymentHistory} alt="icon" />
                            <p> Api Security </p>
                        </div>
                    </Link>
                    
                </div>

              }
              <Routes>
                  <Route path="/" element={<Main setDetailName={setDetailName} setHeader={setHeader} />}/>
                  <Route path="/:service" element={<Details setDetailName={setDetailName}  />} />
                  <Route path="/api-security" element={<ApiSecurity setDetailName={setDetailName}  />} />
              </Routes>
          </Container>
          
    </div>
  )
}

export default TechSupport

