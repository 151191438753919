import CustomBarChart from "components/Charts/BarChart";
import PDFView from "components/PDFView";
import Button from "components/shared/Button";
import { useState } from "react";
import { formatBigNumber, generatePDF } from "utils/helpers";

export default function RevenueBarChart ({revenueGraphData, topData}) {
    const [isDownloading, setIsDownloading] = useState(false);

    return (
        <div>
            <div className="bg-white rounded-lg p-[40px] shadow-[0px_1.21px_2.41px_0px_#1018280F,0px_1.21px_3.62px_0px_#1018281A] border-[1px] border-[#EAECF0]">
                <Button
                    text="12 months"
                    type="button"
                    className="bg-[#CCDCFF] px-3 py-2.5 mb-[20px] !text-base !text-ee-primary !rounded-md"
                />
                <CustomBarChart
                    data={revenueGraphData?.netData ?? []}
                    title={
                        <div className="grid grid-cols-2 -mt-[20px] -ml-[20px]">
                            <div>
                                Revenue Overview
                                <Button
                                    text="Export PDF"
                                    type="button"
                                    isLoading={isDownloading}
                                    className="bg-white px-2 py-2 mt-[10px] border-[1px] border-[#D4D4D8] font-bold !text-sm !text-black !rounded-md"
                                    icon={
                                        <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.56271 7.59687V11.6442M9.56271 11.6442L7.33669 9.62053M9.56271 11.6442L11.7887 9.62053M13.2728 15.017H5.85267C5.03307 15.017 4.36865 14.4129 4.36865 13.6679V4.22411C4.36865 3.47902 5.03307 2.875 5.85267 2.875H9.99737C10.1942 2.875 10.3829 2.94607 10.5221 3.07257L14.5394 6.72475C14.6786 6.85125 14.7568 7.02283 14.7568 7.20173V13.6679C14.7568 14.4129 14.0924 15.017 13.2728 15.017Z" stroke="#18181B" stroke-width="1.66952" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    }
                                    iconPosition="left"
                                    onClick={()=>{
                                        if(!isDownloading){
                                            setIsDownloading(true)
                                            generatePDF(
                                                "revenue-barchart", 
                                                "Revenue Overview",
                                                ()=>{
                                                    setIsDownloading(false)
                                                }
                                            )
                                        }
                                    }}
                                />
                            </div>
                            <div className="flex gap-x-[20px]">
                                {
                                    topData?.map(item=>(
                                        <div key={item.slug} className="flex gap-x-1.5">
                                            <div 
                                                className="w-2.5 h-2.5 rounded-full" 
                                                style={{backgroundColor: item.color}}
                                            />
                                            <div>
                                                <p className="text-sm -mt-[5px]">{item.name}</p>
                                                <p className="text-[20px] font-bold">{formatBigNumber(item.total)}</p>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    }
                    yAxisName="Net Revenue"
                    removeFirst={false}
                    hideMonthButton={true}
                    currencyFormat={true}
                    months={revenueGraphData?.months ?? []}
                />
            </div>
            <PDFView id={"revenue-barchart"}>
                <div className="bg-white p-10 min-h-[275mm]">
                    <div className="border-[1px] rounded-lg -pl-[20px]">
                        <CustomBarChart
                            data={revenueGraphData?.netData ?? []}
                            title={
                                <div className="grid grid-cols-2">
                                    <h3 className="font-medium text-[24px] leading-[27px]">Revenue Overview</h3>
                                    <div className="flex gap-x-[20px]">
                                        {
                                            topData?.map(item=>(
                                                <div key={item.slug} className="flex gap-x-1.5">
                                                    <div 
                                                        className="w-2.5 h-2.5 rounded-full" 
                                                        style={{backgroundColor: item.color}}
                                                    />
                                                    <div>
                                                        <p className="text-sm -mt-[5px]">{item.name}</p>
                                                        <p className="text-[20px] font-bold">{formatBigNumber(item.total)}</p>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            }
                            yAxisName="Net Revenue"
                            removeFirst={false}
                            hideMonthButton={true}
                            currencyFormat={true}
                            months={revenueGraphData?.months ?? []}
                        />
                    </div>
                </div>
            </PDFView>
        </div>
    )
}