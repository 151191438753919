import { APIKit, loadToken } from "services";

const timeout = 15000;

export const SalesOpsService = {

    getAllBusinesses: (args) => {
        return APIKit(timeout).get(`eagleeyeauth/salesops/businesses`, {
            headers: {
                Authorization: `Bearer ${loadToken()}`
            },
            params:{
                ...args
            }
        })
    },

    getBusinessById: (id, args) => {
        return APIKit(120000).get(`eagleeyeauth/salesops/stats/${id}`, {
            headers: {
                Authorization: `Bearer ${loadToken()}`
            },
            params:{
                ...args, // type, start?, end?
            }
        })
    },

    getBusinessUsage: (businessId, args) => {
        return APIKit(120000).get(`eagleeyeauth/salesops/stats-usage/${businessId}`, {
            headers: {
                Authorization: `Bearer ${loadToken()}`
            },
            params:{
                ...args, // type, start?, end?
            }
        })
    },

    getBusinessBillableApis: (id, args) => {
        return APIKit(timeout).get(`eagleeyeauth/salesops/billable-apis/${id}`, {
            headers: {
                Authorization: `Bearer ${loadToken()}`
            },
            params:{
                ...args,
            }
        })
    },

    getBusinessLinkedAccounts: (id, args) => {
        return APIKit(timeout).get(`eagleeyeauth/salesops/account-linked/${id}`, {
            headers: {
                Authorization: `Bearer ${loadToken()}`
            },
            params:{
                ...args,
            }
        })
    },
    
    getBusinessBorrowers: (id, args) => {
        return APIKit(timeout).get(`eagleeyeauth/salesops/borrowers/${id}`, {
            headers: {
                Authorization: `Bearer ${loadToken()}`
            },
            params:{
                ...args,
            }
        })
    },
    
    getBusinessApiCalls: (id, args) => {
        return APIKit(timeout).get(`eagleeyeauth/salesops/apis/${id}`, {
            headers: {
                Authorization: `Bearer ${loadToken()}`
            },
            params:{
                ...args,
            }
        })
    },
    
    getBusinessFunding: (id, args) => {
        return APIKit(timeout).get(`eagleeyeauth/salesops/wallet-funding/${id}`, {
            headers: {
                Authorization: `Bearer ${loadToken()}`
            },
            params:{
                ...args,
            }
        })
    },
    
    // getBusinessFloatRepayments: (id, args) => {
    //     return APIKit(timeout).get(`eagleeyeauth/salesops/repayment-salesops/${id}`, {
    //         headers: {
    //             Authorization: `Bearer ${loadToken()}`
    //         },
    //         params:{
    //             ...args
    //         }
    //     })
    // },
}