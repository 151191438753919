// import { useState, useEffect } from "react";
import { ToastContainer, //toast 
} from "react-toastify";
import { Link } from "react-router-dom";
import mail from "../../images/svg/auth/mail.svg";



function CheckMail () {

    return(
        <div className="auth_signup" >
            <ToastContainer />
            <img src={mail} alt="pattern" />

            <h3> Check your Mail</h3>
            <p>We sent reset instructions to Tol***en@gmail.com</p>
            <p> Didn’t receive mail <Link to="/auth/login"><span>Resend Mail</span></Link></p>
            <p> Back to <Link to="/auth/login"><span>Login</span></Link></p>
          
        </div>
    )
}

export default CheckMail;