import React, { useEffect, useState } from 'react'
import Table from '../../../components/Table/general.js';
// import { Modal } from "react-bootstrap";
// import search from '../../../images/svg/search.svg';
import { 
    getDailyCurrencyRate, 
} from '../../../lib/auth.js';
// import { DateRange } from 'react-date-range';
import 'react-date-range/dist/theme/default.css';
import 'react-date-range/dist/styles.css';
import { toast } from 'react-toastify';
// import { CSVLink } from 'react-csv';
// import download from '../../../images/svg/download.svg';
import Loader from '../../../components/shared/Loader.js';


function ExchangeRate ({setHeader}) {
    const [isLoading, setIsLoading] = useState(false);
    const [tableData, setTableData] = useState({
        tableFor: "currency-rate",
        headings: ["ID", "Rate", "Date"],
        items: []
    });

    // const [query, setQuery] = useState('all')
    // const [searchTerm, setSearchTerm] = useState('');
    // const [filteredData, setFilteredData] = useState([]);
    // const [startDateee, setStartDateee] = useState("");
    // const [endDateee, setEndDateee] = useState("");
    // const [show, setShow] = useState(false);

    // const [state, setState] = useState([
    //     {
    //       startDate: new Date(),
    //       endDate: new Date(),
    //       key: 'selection'
    //     }
    // ]);

    
    // const handleClose = () => setShow(false);

    var monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    var today = new Date();
    var d;
    var month = [];
    // let monthSlice = [];

    for(var i = 6; i > 0; i -= 1) {
    d = new Date(today.getFullYear(), today.getMonth() - i, 1);
    month.push(monthNames[d.getMonth()]);
    // monthSlice = mon
    console.log(month);
    }

    // const handleChange = (e) => {
    //     setSearchTerm(e.target.value)
    //   }
      


    useEffect(() => {
        setHeader();
        setIsLoading(true)
        getDailyCurrencyRate()
        .then(response => response.data)
        .then((res)=> {
            if(res.status === "success"){
                setTableData({
                ...tableData,
                items: res.data
                })
                setIsLoading(false)
            } else {
                toast.error("Something went wrong, please try again!!!")
                setIsLoading(false)
            }          
        })
        .catch((err) => {
            console.log(err)
        });
          // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    //   function formatPickerDate(date) {
    //     var d = new Date(date),
    //         month = '' + (d.getMonth() + 1),
    //         day = '' + d.getDate(),
    //         year = d.getFullYear();
      
    //     if (month.length < 2) 
    //         month = '0' + month;
    //     if (day.length < 2) 
    //         day = '0' + day;
      
    //     return [year, month, day].join('-');
    //   }

    // const CSVHeaders = [
    //     { label: "ID", key: "businessId" },
    //     { label: "Business Name", key: "account_name" },
    //     { label: "Top-up Amount", key: "topUpAmount" },
    //     { label: "Previous Balance", key: "previousBalance" },
    //     { label: "New Balance", key: "newBalance" },
    //     { label: "Top-up By", key: "topUpBy" },
    //     { label: "Time", key: "createdAt" },
    // ]
    
    return(
        <div className='home_main'>

            {/* <Modal show={show} onHide={handleClose}>
              <DateRange
                  editableDateInputs={true}
                  onChange={item => {
                          setStartDateee(formatPickerDate(item.selection.startDate))
                          setEndDateee(formatPickerDate(item.selection.endDate))
                          setState([item.selection])
                      }
                  }
                  moveRangeOnFirstSelection={false}
                  ranges={state}
              />
              <button 
                  style={{
                      color:"white",
                      padding:"7px 25px",
                      textAlign:"center",
                      border:"1px solid #0046E6",
                      background:"#0046E6"
                  }} 
                  onClick={()=>{   
                          handleClose();
                        //   setQuery(`custom&start=${startDateee}&end=${endDateee}`)
                      }}
                  >Proceed</button>
          </Modal> */}
          
            <div className='home_wallet-topup-table'>
                <div className='home_main-tableHeader home_wallet-topup-table-header' >
                    <div className="table_top" style={{display:"flex", alignItems:"center"}}>
                        <div>
                            <h4>Exchange Rate</h4>
                        </div>
                    </div>
                </div>

                {
                    isLoading ?
                    <Loader /> :
                    <Table  
                        data={tableData} 
                    />
                }

                <div className='home_main-csv' style={{marginTop: tableData.items.length<11 ? '-30px':'-70px'}} >
                    {/* { 
                        tableData.items.length > 0 &&
                        <CSVLink data={tableData.items} filename={"wallet-topUp.csv"} headers={CSVHeaders} >
                        <img src={download} alt="download" />CSV
                        </CSVLink>
                    } */}
                </div>
            </div>
        </div>
    )
}

export default ExchangeRate