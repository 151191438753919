import {Row, Col} from "react-bootstrap";
import Back from "../../../../components/back";
import { useNavigate } from "react-router-dom";

import info from "../../../../images/svg/float/info.svg";


export default function RCResult () {
    const navigate = useNavigate();
    let rcRes = {};
    try {
        // let requestData = JSON.parse(sessionStorage.getItem('requestDetails')).requestData
        rcRes =  JSON.parse(sessionStorage.getItem('requestDetails')).requestData?.CAC;
    } catch (err) {
        //err
    } 

    console.log(rcRes)
    return(
        <div className="home_main">
            <div style={{margin:"-10px 0 20px"}}>
                <Back to={()=> navigate(-1)} />
            </div>
            <div className="identity_result">
                <div className="identity_result-top">
                    <div className="identity_result-top_image">
                        <h1>{rcRes?.company_name?.slice(0,1)}</h1>
                    </div>
                    <div>
                        <h5>{rcRes?.company_name}</h5>
                        <p>RC Number: {rcRes?.rc_number}</p>
                    </div>
                    <img src={info} alt="" className="identity_result-top_button" />
                </div>
                <hr/>
                <Row>
                    <Col lg={3} md={4}>
                        <div className="identity_result-box1">
                            <p className="text1">Email</p>
                            <p className="text2">{rcRes?.email_address==="" || rcRes?.email_address===null ?"Not available":rcRes?.email_address}</p>
                        </div>
                    </Col>
                    <Col lg={3} md={4}>
                        <div className="identity_result-box1">
                            <p className="text1">City</p>
                            <p className="text2">{rcRes?.city===""?"Not available":rcRes?.city}</p>
                        </div>
                    </Col>
                    <Col lg={3} md={4}>
                        <div className="identity_result-box1">
                            <p className="text1">Lga</p>
                            <p className="text2">{rcRes?.lga===""?"Not available":rcRes?.lga}</p>
                        </div>
                    </Col>
                    <Col lg={3} md={4}>
                        <div className="identity_result-box1">
                            <p className="text1">State</p>
                            <p className="text2">{rcRes?.state===""?"Not available":rcRes?.state}</p>
                        </div>
                    </Col>
                    <Col lg={3} md={4}>
                        <div className="identity_result-box1">
                            <p className="text1">Registered On</p>
                            <p className="text2">{rcRes?.date_of_registration===""?"Not available":rcRes?.date_of_registration}</p>
                        </div>
                    </Col>
                    <Col lg={3} md={4}>
                        <div className="identity_result-box1">
                            <p className="text1">Company Status</p>
                            <p className="text2">{rcRes?.company_status===""?"Not available":rcRes?.company_status}</p>
                        </div>
                    </Col>
                    <Col lg={6} md={8}>
                        <div className="identity_result-box1">
                            <p className="text1">Address</p>
                            <p className="text2">{rcRes?.address===""?"Not available":rcRes?.address}</p>
                        </div>
                    </Col>
                </Row>
                <hr/>
                <h5 style={{marginLeft:"10px"}}>Directors</h5>
                <div className="identity_result-tableBox hide-scrollBar">
                    <table className="identity_result-table">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Phone Number</th>
                                <th>Gender</th>
                                <th>Nationality</th>
                                <th>Date of Appointment</th>
                                <th>Date of Birth</th>
                                <th>Number of Shares Alloted</th>
                                <th>Type of Shares</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                rcRes?.directors.map((item, idx)=>(
                                    <tr key={idx}>
                                        <td>{item.firstname===null && item.surname===null ? "Not Available": `${item.firstname} ${item.surname}`}</td>
                                        <td>{item.email===""||item.email===null?"Not Available":item.email}</td>
                                        <td>{item.phoneNumber===""||item.phoneNumber===null?"Not Available":item.phoneNumber}</td>
                                        <td>{item.gender===""||item.gender===null?"Not Available":item.gender}</td>
                                        <td>{item.nationality===""||item.nationality===null?"Not Available":item.nationality}</td>
                                        <td>{item.dateOfAppointment===""||item.dateOfAppointment===null?"Not Available":item.dateOfAppointment.slice(0,10)}</td>
                                        <td>{item.dateOfBirth===""||item.dateOfBirth===null?"Not Available":item.dateOfBirth.slice(0,10)}</td>
                                        <td>{item.numSharesAlloted===""||item.numSharesAlloted===null?"Not Available":item.numSharesAlloted}</td>
                                        <td>{item.typeOfShares===""||item.typeOfShares===null?"Not Available":item.typeOfShares}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                    {  
                        rcRes?.directors?.length < 1 && <h5 style={{textAlign:"center", margin:"30px 0"}}>No directors data available</h5>
                    }
                </div>
            </div>
        </div>
    )
}