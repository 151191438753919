import React from 'react'
import deleteIcon from '../../images/svg/deleteIcon.svg'


const DeleteAdminModal = ({cancel, doAction}) => {

  
  return (
    <div className="myModal_main-delete">
   
      <img src={deleteIcon} alt="delete" />
        
        <h4>Delete admin request</h4>

        <p>Are you sure you want to delete this admin? This action cannot be undone.</p>

        <div className="myModal_main-delete-bottom">
            <button className="cancel" onClick={cancel}
            >Cancel</button>
            <button className="publish" onClick={doAction}
            >Delete</button>
        </div>
    </div>
  )
}

export default DeleteAdminModal