import { useNavigate, useParams } from "react-router-dom";
import Back from '../../../components/back'
import { handleRequestErrors } from "utils/helpers";
import { useEffect, useState } from "react";
import { BusinessService } from "services/business.service";
import DetailsBox from "./components/DetailsBox";

function Details () {
    let navigate = useNavigate()
    let params = useParams()
    const [businessData, setBusinessData] = useState(null);

    useEffect(() => {
        BusinessService.viewBusiness(params.id)
            .then(response => response.data)
            .then((res) => {
                if(res.status === 'success'){
                    setBusinessData(res.data)
                }
            })
            .catch((err) => {
                handleRequestErrors(err)
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div>
            <Back to={()=> navigate(-1)} />
            <DetailsBox businessData={businessData} />
        </div>
    )
}

export default Details;