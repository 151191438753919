import React from 'react'


const SendFloatOffer = ({cancel, doAction}) => {

  
  return (
    <div className="myModal_main-delete" style={{textAlign:'left', padding:'1em'}}>
        
        <h4>Send Float Offer</h4>

        <p>Are you sure you want to send this offer? This action cannot be undone.</p>

        <div className="myModal_main-delete-bottom" style={{padding: '1em 0'}}>
            <button className="cancel" onClick={cancel}
            >Cancel</button>
            <button className="publish" style={{background:'#0046E6', border:'none'}} onClick={doAction}
            >Send Offer</button>
        </div>
    </div>
  )
}

export default SendFloatOffer