import Loader from "../../../components/shared/Loader";
import { useState, useEffect } from "react";
import apiIcon from '../../../images/svg/box/api/api.svg';
import greenGraphIcon from '../../../images/svg/box/graph.svg';
import { ApiService } from "../../../services/api.service";
import { formatBigNumber } from "utils/helpers";


export default function ApiCallTypes({ queryVal, query }) {

    const headerNames = [
        ["Cumulative", "cumulative"],
        ["Credit Insight", "credit"],
        ["Income Insight", "income"],
        ["Identity Insight", "identity"],
        ["Float", "float"],
        ["RecovaPRO", "recover"],
        ["Radar", "radar"],
        // ["Others", "others"]
    ];

    const [selectedHeader, setSelectedHeader] = useState(headerNames[0][0]);
    const [selectedApi, setSelectedApi] = useState(headerNames[0][1]);
    const [isLoadingApiStats, setIsLoadingApiStats] = useState(true);
    const [statsData, setStatsData] = useState(null);

    function handleSelectHeader(headerArray) {
        setSelectedHeader(headerArray[0]);
        setSelectedApi(headerArray[1])
    }

    useEffect(() => {
        setIsLoadingApiStats(true);
        if (selectedApi === 'others' || selectedApi === 'cumulative') {
            ApiService.getCummulativeData(query)
            .then(response => response.data)
            .then(resp => {
                const resultCumulative = {
                    'Total API Calls': { 
                        value: formatBigNumber(resp?.data?.totalApiCalls||0, true), 
                        lastMonth: resp?.data?.apiCallsPercentChange||0, 
                        icon: apiIcon 
                    },
                    'Total Billable API Calls': { 
                        value: formatBigNumber(resp?.data?.totalBillableApiCalls||0, true), 
                        lastMonth: resp?.data?.billableApiCallsPercentChange||0, 
                        icon: apiIcon 
                    },
                    'Total Failed API Calls': { 
                        value: formatBigNumber(resp?.data?.totalFailed||0, true), 
                        lastMonth: resp?.data?.failedPercentChange||0, 
                        icon: apiIcon 
                    },
                    'Total Failed Billable API Calls': { 
                        value: formatBigNumber(resp?.data?.totalBillableFailed||0, true), 
                        lastMonth: resp?.data?.BillableFailedPercentChange||0, 
                        icon: apiIcon 
                    },
                    'Total Successful Billable API Calls': { 
                        value: formatBigNumber(resp?.data?.totalSuccessfulBillableApiCalls||0, true), 
                        lastMonth: resp?.data?.totalSuccessfulBillableApiCallsPercentChange||0, 
                        icon: apiIcon 
                    },
                    'Fail Rate': { 
                        value: resp?.data?.failRate?.toFixed(0)||0, 
                        lastMonth: resp?.data?.failedRatePercentChange||0, 
                        icon: apiIcon 
                    },
                };
                const resultOther = {
                    'VAT': { total: '4567', billable: '4567', lastMonth: 4.07, icon: 'VT' },
                    'Stamp Duty Fee': { total: '4567', billable: '4567', lastMonth: 4.07, icon: 'SD' },
                };

                if (selectedApi === 'cumulative') {
                    setStatsData(resultCumulative);
                } else {
                    setStatsData(resultOther);
                }
                setIsLoadingApiStats(false);
            })
            .catch((err) => {
                setIsLoadingApiStats(false);
            });
        } else {
            // Fetch specific API stats
            ApiService.getApiCallStats({
                ...query,
                name: selectedApi
            })
                .then(response => response.data)
                .then((res) => {
                    setStatsData(res.data);
                    setIsLoadingApiStats(false);
                })
                .catch((err) => {
                    setIsLoadingApiStats(false);
                });
        }
    }, [selectedApi, query]);

    return (
        <div>
            <ul className="list-none p-0 my-4 flex text-ee-grey-2 font-bold">
                {headerNames.map((item, index) => (
                    <li key={index} className="flex-1">
                        <button onClick={() => handleSelectHeader(item)}
                            className={`w-full p-2 cursor-pointer ${selectedHeader === item[0] ? "border-b-4 border-ee-blue-3 bg-ee-blue-1" : ""
                                }`}>{item[0]}</button>
                    </li>
                ))}
            </ul>
            <div>
                {
                    isLoadingApiStats ?
                        <Loader height="20px" /> :
                        <DataBox headerName={selectedHeader} boxData={statsData} queryVal={queryVal} activeApi={selectedApi} />
                }
            </div>
        </div>
    )
}

function DataBox({ headerName, boxData, queryVal, activeApi }) {
    return (
        <ul className="flex gap-4 text-sm flex-wrap">
            {Object.entries(boxData).map(([key, item], index) => (
                <Card item={item} title={key} headerName={headerName} key={index} queryVal={queryVal} activeApi={activeApi} />
            ))}
        </ul>
    );
}


function Card({ item, title, headerName, queryVal, activeApi }) {
    const normalizedTitle = normalizeTitle(activeApi, title);

    if (headerName === 'Cumulative')
        return (
            <li className="flex items-center gap-2 w-[250px] p-4 rounded-lg shadow-[2px_2px_20px_#eee]">
                <div className="flex items-center justify-center w-12 h-12 bg-ee-grey-10 rounded-full p-2">
                    <img src={item.icon} alt="api icon" width={30} />
                </div>
                <div>
                    <p className="font-bold text-lg text-ee-black-1">{item.value}</p>
                    <p className="text-ee-grey-1">{normalizedTitle}</p>
                    <p className="flex justify-between min-w-[166px] mt-2">
                        <span className="flex text-ee-green-3 gap-1"><img src={greenGraphIcon} alt="positive" /> <span>{item.lastMonth}%</span></span>
                        <span className="text-ee-grey-11">{queryVal}</span>
                    </p>
                </div>
            </li>
        );

    return (
        <li className="flex items-center gap-2 w-[250px] p-4 rounded-lg shadow-[2px_2px_20px_#eee]">
            <div className="flex items-center justify-center w-12 h-12 bg-ee-grey-10 rounded-full text-ee-blue-4 font-semibold text-3xl p-2">
                {item.icon}
            </div>
            <div>
                <p className="text-ee-grey-1 font-semibold">{normalizedTitle}</p>
                <p className="flex justify-between min-w-[166px]"><span className="text-ee-grey-1">Total</span><span className="font-bold text-ee-black-1">{item.total}</span></p>
                <p className="flex justify-between min-w-[166px]"><span className="text-ee-grey-1">Billable</span><span className="font-bold text-ee-black-1">{item.billable}</span></p>
                <p className="flex justify-between min-w-[166px] mt-2">
                    <span className="flex text-ee-green-3"><img src={greenGraphIcon} alt="positive" /> <span>{item.lastMonth ? item.lastMonth : 4.07}%</span></span>
                    <span className="text-ee-grey-11">{queryVal}</span>
                </p>
            </div>
        </li>
    );
}

function normalizeTitle(apiName, title) {
    const apis = {
        'credit': {
            "credit first central": "First Central Individual Summary",
            "credit first central full report": "First Central Full Report",
            "credit first central nano": "First Central Nano Report",
            "credit first central iscore": "First Central iScore",
            "credit sme first central": "First Central SME",
            "credit sme crc": "CRC SME",
            "credit crc fico": "CRC Fico Score",
            "credit crc": "CRC Individual Summary",
            "credit crc nano": "CRC Nano",
            "credit crc full report": "CRC Full Report",
            "credit registry full report": "Credit Registry Full report",
            "credit registry": "Credit Registry Individual Summary",
            "credit premium": "Credit Premium",
            "credit sme premium": "Credit SME Premium",
            "credit advanced": "Credit Advanced",
        },
        'income': {
            "pdf statement upload": "PDF Statement Upload",
            "income insights": "Open Banking (All Income Insight)",
        },
        'identity': {
            "borrower onboarding": "Borrowers Onboarded",
            "identity bvn verificationd": "BVN Verification",
            "identity bvn igree verification": "BVN Igree Verification",
            "identity nin verification": "NIN Verification",
            "drivers id verification": "Drivers ID Verification",
            "passport verification": "Passport Verification",
            "pvc verification": "Voters Card Verification",
            "identity cac verification": "CAC Verification",
            "cac basic verification": "CAC Basic Verification",
            "account number verification": "Account Number Verification",
            "account number basic verification": "Account Number Basic Verification"
        },
        'float': {
            "float default fee": "Float Default Fee",
            "float interest instalment": "Float Interest Instalment",
            "float principal instalment": "Float Principal Instalment",
            "float request": "Float Request",
        },
        'recover': {
            "balance enquiry": "Balance Enquiry",
            "bank account validation": "Bank Account Validation",
            "bank lookup": "Bank Lookup",
            "collections": "Collections",
            "mandate creation": "Mandate Creation",
            "payout request": "Payout Request",
        },
        'radar': {
            "get borrower connected banks": "Radar Phone Number",
            "radar bvn": "Radar BVN",
        }
    }
    if (!apis[apiName]) return title;
    title = title.toLowerCase();
    if (!apis[apiName][title]) return title;
    return apis[apiName][title];
}
