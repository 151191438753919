import React, { useRef } from 'react'
import service from "../../images/svg/tech-support/service.svg"
import refresh from "../../images/svg/tech-support/refresh.svg"
import add from "../../images/svg/tech-support/add.svg"
import eye from "../../images/svg/tech-support/eye.svg"
import {CopyToClipboard} from 'react-copy-to-clipboard';

const ApiBox = ({
    setShowModal, 
    setSelectedService, 
    data, 
    showKeys, 
    onCopyText, 
    toggleKeyVisibility
  }) => {
  const inputElement = useRef();

  const InputRef = () => {
    inputElement.current.focus();
  };

  return (
      data && data?.map((item, index)=> (
        <div className='main_box'>
          <div className='main_box-top'>
            <div className='main_box-top-title' >
              <img src={service} alt="" />
              <h5>{item?.service}</h5>
            </div>
            <div className='main_box-top-refresh' onClick={()=>{setSelectedService(item?.service); setShowModal(true) }}>
              <img src={refresh} alt="refresh icon" />
              <p>Generate New keys</p>
            </div>
          </div>
          <div className='main_box-body'>
            <h5>API Keys</h5>
    
            <div className='dataKey'>
              <input ref={inputElement} type={ showKeys ? "text" : "password"} value={item?.key} style={{padding:"0 5px", fontSize:"0.9rem"}} />
              <img 
              onClick={toggleKeyVisibility} 
                src={eye} alt="eye" 
              />
            </div>
            <CopyToClipboard text={ item?.key } onCopy={onCopyText}>
              <img src={add} onClick={InputRef} alt="copy" style={{cursor: "pointer"}} />
            </CopyToClipboard>
          </div>
        </div>
      ))
    
  )
}

export default ApiBox