import React, { useEffect, useState } from 'react';
import {Routes, Route} from "react-router-dom";
import Main from './Main';
import home from '../../../images/svg/float/home.svg'
import paymentHistory from '../../../images/svg/float/paymentHistory.svg'
import dollarIcon from '../../../images/svg/float/dollarIcon.svg'
import editPen from '../../../images/svg/float/edit_pen.svg'
import PaymentHistory from './PaymentHistory';
import Request from './components/Request';
import Offer from './components/Offer';
import RCResult from './results/RCResult';
import Director1BVNResult from './results/Director1BVNResult';
import Director2BVNResult from './results/Director2BVNResult';
import OfferDetails from './components/OfferDetails';
import MainUSD from './MainUSD';
import ExchangeRate from './ExchangeRate';
import UpcomingPayment from './UpcomingPayment';
import PatchMain from "./Patch";
import PatchDetails from './Patch/details';
import Tabs from 'components/shared/Tabs';


function Float ({setDetailName, roles, adminRole}) {
    const [header, setHeader] = useState(true);

    useEffect(() => {
        setDetailName("");
      }, [setDetailName]);

    return (
        <div>
            {
                header &&
                <Tabs
                    tabs={[
                        {
                            label: <span className='flex items-center gap-2.5'><img src={home} alt="icon" /> Home (Naira)</span>,
                            key: "/float"
                        },
                        {
                            label: <span className='flex items-center gap-2.5'><img src={home} alt="icon" /> Home (USD)</span>,
                            key: "/float/usd"
                        },
                        {
                            label: <span className='flex items-center gap-2.5'><img src={paymentHistory} alt="icon" /> Upcoming Payments</span>,
                            key: "/float/upcoming-payment"
                        },
                        {
                            label: <span className='flex items-center gap-2.5'><img src={paymentHistory} alt="icon" /> Payment History</span>,
                            key: "/float/payment-history"
                        },
                        {
                            label: <span className='flex items-center gap-2.5'><img src={dollarIcon} alt="icon" /> Exchange Rate</span>,
                            key: "/float/exchange-rate"
                        },
                        {
                            label: <span className='flex items-center gap-2.5'><img src={editPen} alt="icon" /> Float Patch</span>,
                            key: "/float/patch"
                        },
                    ]}
                    useAsLink
                    variant="line"
                    containerClass="!border-0"
                />
            }
            <Routes>
                <Route path="/" element={<Main setHeader={()=> setHeader(true)}  />}/>
                <Route path="/usd" element={<MainUSD setHeader={()=> setHeader(true)}  />}/>
                <Route path="/payment-history" element={<PaymentHistory setHeader={()=> setHeader(true)}  />} />
                <Route path="/upcoming-payment" element={<UpcomingPayment setHeader={()=> setHeader(true)} />} />
                <Route path="/exchange-rate" element={<ExchangeRate setHeader={()=> setHeader(true)} />} />
                <Route path="/request/:id" element={<Request setHeader={()=> setHeader(false)} roles={roles} adminRole={adminRole} />} />
                <Route path="/offer" element={<Offer setHeader={()=> setHeader(false)}/>} />
                <Route path="/details" element={<OfferDetails setHeader={()=> setHeader(false)}/>} />
                <Route path="/cac" element={<RCResult setHeader={()=> setHeader(false)}/>} />
                <Route path="/director1bvn" element={<Director1BVNResult setHeader={()=> setHeader(false)}/>} />
                <Route path="/director2bvn" element={<Director2BVNResult setHeader={()=> setHeader(false)}/>} />
                <Route path="/patch" element={<PatchMain setHeader={()=> setHeader(true)}  />} />
                <Route path="/patch/details/:id" element={<PatchDetails setHeader={()=> setHeader(false)} />} />
            </Routes>
        </div>
    )
}

export default Float