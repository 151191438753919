
import { useEffect, useState } from "react";
import Back from '../../../../components/back'
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import BasicVerificationResult from '../results/BasicVerificationResult'
import { changeFloatStatus, declineFloatOffer, floatDirectorConsent, getAllBankLogo, sendDisburseMail, viewFloatRequest } from '../../../../lib/auth';
import { toast } from 'react-toastify';
import Modal from "../../../../components/Modal";
import clsx from "clsx";
import { OtherService } from "services/other.services";
import dayjs from "dayjs";
import { currencyFormatter } from "utils/helpers";
import FilePreview from "components/shared/FilePreview";
import Button from "components/shared/Button";
import pdfIcon from "images/svg/pdfIcon.svg"


function Request ({setHeader, roles, adminRole}) {
    let navigate = useNavigate();
    const location = useLocation().pathname
    const [basicData, setBasicData] = useState({});
    const [basicVerificationModal, setBasicVerificationModal] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [pendingShowModal, setPendingShowModal] = useState(false);
    const [disbursedShowModal, setDisbursedShowModal] = useState(false);
    const [ngBanks, setNgBanks] = useState([]);
    const [requestData, setRequestData] = useState([]);
    const [ipDeets, setIpDeets] = useState({
        country_name: "Nigeria",
        IPv4: "102.89.45.161"
      });
    let params = useParams()
    const [resendLoading, setResendLoading] = useState({
        director1: false,
        director2: false
    })

    useEffect(() => {
      setHeader();
      getAllBankLogo()
        .then(resp=>{
            setNgBanks(resp.data);
        })
       // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    useEffect( () => {
        OtherService.getGeolocation()
            .then(response => response.data)
            .then((res) => {
                setIpDeets(res)
            })
            .catch((err)=>{
                // console.log(err)
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        viewFloatRequest(params?.id)
        .then(response => response.data)
        .then((res) => {
          if(res.status === 'success'){
            sessionStorage.setItem( "requestDetails", JSON.stringify({ requestData: res.data }));
            setRequestData(res.data )
          }
          else {
            toast.error("Something went wrong, please try again!!!")
        }
      })
      .catch((err) => {
          if(err.response){
            toast.error(err.response.data.message);
          } else{
            toast.error("Something went wrong!!! Please make sure you have a good internet connection.");
          }
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

      const openView = (item) => {
          if(item === 'businessBankDetail'){
            setBasicData(requestData?.businessBankDetail)
            setBasicVerificationModal(true)
          }else if(item === 'Director1BankResult'){
            setBasicData(requestData?.director1BankDetail)
            setBasicVerificationModal(true)
          }else if(item === 'Director2BankResult'){
            setBasicData(requestData?.director2BankDetail)
            setBasicVerificationModal(true)
          }
      }

      const director1Bvn = (item) => {
          sessionStorage.setItem('floatBvn', JSON.stringify(requestData?.director1BVN));
          window.location= '/float/director1bvn'
      }

      const director2Bvn = (item) => {
          sessionStorage.setItem('floatBvn', JSON.stringify(requestData?.director2BVN));
          window.location= '/float/director2bvn'
      }

      const handleDecline = () => {
        declineFloatOffer(requestData?._id)
        .then((response) => response.data)
        .then((res) => {
            if (res.status === 'success') {
                toast.success('Float Offer Rejected')
                setTimeout(() => {
                    navigate('/float')
                }, 1500);
            } else {
                toast.error("Something went wrong, please try again!!!")
            }
        })
        .catch((err) => {
            if(err.response){
                toast.error(err.response.data.message);
            } else{
                toast.error("Something went wrong!!! Please make sure you have a good internet connection.");
            }
        });
      }

      const onchangeFloatStatus = () => {
        let payload = {
            id: requestData?._id,
            status: "pending" ,
          }
        changeFloatStatus(payload, ipDeets)
        .then((response) => response.data)
        .then((res) => {
            if (res.status === 'success') {
                toast.success('Successful')
                setTimeout(() => {
                    navigate('/float')
                }, 1500);
            } else {
                toast.error("Something went wrong, please try again!!!")
            }
        })
        .catch((err) => {
            if(err.response){
                toast.error(err.response.data.message);
            } else{
                toast.error("Something went wrong!!! Please make sure you have a good internet connection.");
            }
        });
      }

      const handleDisbursedFloat = () => {
        let payload = {
            id: requestData?._id,
            businessName: requestData?.businessId?.businessName,
            amount: requestData?.approvedFloat?.approvedAmount,
            accountNumber: requestData?.bankAccountNumber,
            bankName: requestData?.bankName,
            email: requestData.businessId.email
          }
        sendDisburseMail(payload)
        .then((response) => response.data)
        .then((res) => {
            if (res.status === 'success') {
                toast.success('Disburse mail sent successfully')
                setTimeout(() => {
                    navigate('/float')
                }, 1500);
            } else {
                toast.error("Something went wrong, please try again!!!")
            }
        })
        .catch((err) => {
            if(err.response){
                toast.error(err.response.data.message);
            } else{
                toast.error("Something went wrong!!! Please make sure you have a good internet connection.");
            }
        });
      }

      const resendDirectorEmail = (path) => {
        let payload = {
            businessName: requestData?.businessId?.businessName,
            floatId: requestData?._id,
            rcNumber: requestData?.rcNumber,
            businessEmail: requestData?.businessId?.email,
            amount: requestData?.loanAmount,
            directorType: path,
            directorBVN: path === 'director1' ? requestData?.director1?.bvn : requestData.director2?.bvn,
            directorBankName: path === 'director1' ? requestData.director1?.bankName : requestData.director2?.bankName,
            directorPosition: path === 'director1' ? requestData.director1?.position : requestData.director2?.position,
            directorPhoneNumber: path === 'director1' ? requestData.director1?.phone : requestData.director2?.phone,
            directorEmail: path === 'director1' ? requestData.director1?.email : requestData.director2?.email,
            directorFullname: path === 'director1' ? requestData.director1?.fullName : requestData.director2?.fullName,
            directorBankAccountNumber: path === 'director1' ? requestData.director1?.bankAccountNumber : requestData.director2?.bankAccountNumber, 
        }
        if(path === 'director1'){
            setResendLoading({...resendLoading, director1: true})
            floatDirectorConsent(payload)
            .then((response) => response.data)
            .then((res) => {
                setResendLoading({...resendLoading, director1: false})
                if (res.status === 'success') {
                    toast.success('Consent email sent')
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                setResendLoading({...resendLoading, director1: false})
                if(err.response){
                    toast.error(err.response.data.message);
                    // sessionStorage.setItem('floatVerification', JSON.stringify({acc:'failed'}))
                    
                } else{
                if(err.message === "timeout of 15000ms exceeded"){
                    toast.error('Request timed out, please try again!')
                    // sessionStorage.setItem('floatVerification', JSON.stringify({acc:'failed'}))
                    
                } else if (err.message==="Network Error"){
                    toast.error('Network error, please make sure you are connected to the internet!')
                    // sessionStorage.setItem('floatVerification', JSON.stringify({acc:'failed'}))
                    
                } else{
                    toast.error("Your request can't be processed at this time, please try again later!");
                    // sessionStorage.setItem('floatVerification', JSON.stringify({acc:'failed'}))
                    
                }
                }
            });
        } 
        if(path === 'director2'){
            setResendLoading({...resendLoading, director2: true})
            floatDirectorConsent(payload)
            .then((response) => response.data)
            .then((res) => {
                setResendLoading({...resendLoading, director2: false})
                if (res.status === 'success') {
                    toast.success( 'Consent email sent')
                } else {
                    toast.error(res.message) 
                }
            })
            .catch((err) => {
                setResendLoading({...resendLoading, director2: false})
                if(err.response){
                    toast.error(err.response.data.message);
                    // sessionStorage.setItem('floatVerification', JSON.stringify({acc:'failed'}))
                    
                } else{
                if(err.message === "timeout of 15000ms exceeded"){
                    toast.error('Request timed out, please try again!')
                    // sessionStorage.setItem('floatVerification', JSON.stringify({acc:'failed'}))
                    
                } else if (err.message==="Network Error"){
                    toast.error('Network error, please make sure you are connected to the internet!')
                    // sessionStorage.setItem('floatVerification', JSON.stringify({acc:'failed'}))
                    
                } else{
                    toast.error("Your request can't be processed at this time, please try again later!");
                    // sessionStorage.setItem('floatVerification', JSON.stringify({acc:'failed'}))
                    
                }
                }
            });
        }
    }

    const goBack = () => {
        if(location.includes('sales-ops')){
            // when user comes from sales-ops section
            navigate(`/sales-ops/${requestData?.businessId?._id}`)
        } else {
            // when user comes directly from float
            navigate(`/float`)
        }
    }

    const isConsent = () => {
        if(requestData?.director1Consent === false || requestData?.director2Consent === false ){
            toast.error("Remind the business to give director consent to the float!!!", {
                className: "toast.error"
            })
            return false
        }else{
            navigate('/float/offer')
        }
    }

    return (
        <div>
            <div>
                <Back to={()=>goBack()} />
                <h5 className="my-[2em]">Float Request</h5>
            </div>
            <div>
                <h3 className="text-[26px] font-medium">{requestData?.businessId?.businessName}</h3>
                <p className="text-[#656565]">RC Number: {requestData?.rcNumber} <span><Link to='/float/cac' className="text-[#0046E6] underline">View</Link></span></p>
            </div>
            <div className="border-t-[1px] border-t-[#DFDBDB] w-full my-6" />
            <div>
                <div className="flex justify-between">
                    <div className="flex my-[20px]">
                        <div className="border-l-[4.5px] border-l-[#D0D7E6] pl-[12px]">
                            <p className="font-medium text-base text-[#656565] mb-[1em]">Loan Amount Requested</p>
                            <h3 className="fomt-medium text-[36px] text-[#373737]"> {currencyFormatter(requestData?.loanAmount)}</h3>
                        </div>
                        <div className="border-l-[4.5px] border-l-[#D0D7E6] pl-[12px] ml-[3em]">
                            <p className="font-medium text-base text-[#656565] mb-[1em]">Loan Duration</p>
                            <h3 className="fomt-medium text-[36px] text-[#373737]"> {requestData?.loanDuration} </h3>
                        </div>
                    </div>
                    <div className="font-semibold">
                        {
                            !!requestData?.createdAt &&
                            <p>Applied on: {dayjs(requestData.createdAt).format("DD MMMM YYYY @ hh:mmA")}</p>
                        }
                        {
                            !!requestData?.acceptedAt &&
                            <p>Accepted on: {dayjs(requestData.acceptedAt).format("DD MMMM YYYY @ hh:mmA")}</p>
                        }
                    </div>
                </div>
                <div className="mb-[2em]">
                    <h4 className="text-[18px] font-medium">Bank Statement</h4>
                    <div className="flex items-center gap-6">
                        <div className="max-w-[336px]">
                            <FilePreview
                                name="PDF Document"
                                hideDownload
                                downloadLink={requestData?.pdfStatement?.url}
                            />
                        </div>
                        <div>
                            <h4 className="text-[18px] font-medium mt-2.5">Bank Account Number</h4>
                            <p className="text-[#0046E6] cursor-pointer underline" onClick={()=>openView('businessBankDetail')}>View</p>
                        </div>
                    </div>
                </div>
                <div className="border-t-[1px] border-t-[#DFDBDB] py-8">
                    <h4 className="mx-0 mt-0 mb-2.5 text-[18px] font-medium">Business Information</h4>
                    <div className="flex flex-wrap gap-x-8 gap-y-4">
                        {
                            [
                                {name: "Business Name", value: requestData?.businessId?.businessName || ""},
                                {name: "Email Address", value: requestData?.businessId?.email || ""},
                                {name: "Phone Number", value: requestData?.businessId?.phoneNumber || ""},
                                {name: "Business Address", value: requestData?.businessId?.address || ""},
                                {name: "RC Number", value: <span><Link to='/float/cac' className="text-[#0046E6] underline">View</Link></span>},
                                {name: "BVN", value: "---"},
                                {name: "Bank Account Number", value: <p className="text-[#0046E6] cursor-pointer underline" onClick={()=>openView('businessBankDetail')}>View</p>},
                                {
                                    name: "Agreement Consent", 
                                    value: <div className="-mt-2.5">
                                        <FilePreview
                                            name="Agreement Consent.pdf"
                                            additionalName="Signed Copy"
                                            downloadLink={requestData?.agreementConsent?.url}
                                        />
                                    </div>,
                                    hide: !requestData?.agreementConsent?.url,
                                },
                                {
                                    name: "Mandate Status", 
                                    value: <div>
                                        <p className="text-base font-bold my-1 text-[#F8650C]">Pending</p>
                                        <Button
                                            text="View Details"
                                            onClick={()=>{
                                            }}
                                            className="px-2 py-1 !text-sm !rounded bg-ee-primary"
                                        />
                                    </div>,
                                },
                            ].map((item, i)=>(
                                <div 
                                    key={i} 
                                    className={clsx(
                                        "font-medium",
                                        item.hide && "hidden"
                                    )}
                                >
                                    <p className="text-sm text-[#667085]">{item.name}</p>
                                    <div className="text-base font-normal text-[#344054]">{item.value}</div>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <DirectorInfo
                    data={requestData?.director1}
                    director={1}
                    viewBVN={director1Bvn}
                    viewBankAcc={()=>openView('Director1BankResult')}
                    hasConsent={requestData?.director1Consent}
                    resendConsent={()=>resendDirectorEmail('director1')}
                    address={requestData?.director1Address}
                    resendLoading={resendLoading.director1}
                    passportDocument={requestData?.director1InternationalPassport}
                />
                <DirectorInfo
                    data={requestData?.director2}
                    director={2}
                    viewBVN={director2Bvn}
                    viewBankAcc={()=>openView('Director2BankResult')}
                    hasConsent={requestData?.director2Consent}
                    resendConsent={()=>resendDirectorEmail('director2')}
                    address={requestData?.director2Address}
                    showBottomBorder={true}
                    resendLoading={resendLoading.director1}
                    passportDocument={requestData?.director2InternationalPassport}
                />
                <div className="float_request-action">
                    {
                        requestData?.isApproved === 'pending' && roles?.includes("edit float") ?
                        <div>
                            <button className="decline !w-[185px]" onClick={()=>setShowModal(true)}>Reject Offer</button>
                            <button className="next !w-[185px]" onClick={isConsent}>
                                Next Steps 
                            </button>
                        </div> 
                        :
                        requestData?.isApproved === 'rejected' && roles?.includes("edit float") ?
                        <div>
                            <button className="decline !w-[185px]" onClick={()=>setPendingShowModal(true)}>Make Pending</button>
                        </div>
                        :null
                    }
                    {
                        requestData?.isApproved === 'pending' && roles?.includes("view sales-ops") && adminRole!=='CreditChek Executive' ?
                        <button className="decline !w-[185px]" >Pending</button>:null
                    }
                    {
                        (requestData?.isApproved === 'accepted' || requestData?.isApproved === 'approved') &&
                         roles?.includes("view sales-ops") && adminRole!=='CreditChek Executive'  &&
                        <div>
                            {/* Link to sales-ops/details url when user comes to this page from sales section. */}
                            <Link 
                                to={location.includes('sales-ops')?'/sales-ops/details':'/float/details'}
                            >
                                <button className="next !w-[185px]"> View Offer</button>
                            </Link>
                        </div>
                    }
                    {
                        (requestData?.isApproved === 'accepted' || requestData?.isApproved === 'approved') &&
                        roles?.includes("edit float") &&
                        
                         
                            requestData?.isDisbursed === false ?  
                            <div>
                                <button className="next !w-[185px]" onClick={()=>setDisbursedShowModal(true)}>Trigger Disburse Mail</button>
                                <button className="decline !w-[185px]" onClick={()=>setPendingShowModal(true)}>Make Pending</button>
                                <Link 
                                    to={location.includes('sales-ops')?'/sales-ops/details':'/float/details'}
                                >
                                    <button className="next !w-[185px]"> View Offer</button>
                                </Link>
                            </div>: 
                            requestData?.isDisbursed === true && (requestData?.isApproved === 'accepted' || requestData?.isApproved === 'approved') ?  
                            <Link 
                                to={location.includes('sales-ops')?'/sales-ops/details':'/float/details'}
                            >
                                <button className="next !w-[185px]"> View Offer</button>
                            </Link>
                        :null    
                    }
                </div>
            </div>
            <BasicVerificationResult
                show={basicVerificationModal}
                cancel={()=>setBasicVerificationModal(false)}
                data={basicData}
                ngBanks={ngBanks}
            />
            {showModal && <Modal type={"reject-float"} cancel={()=>setShowModal(false)} doAction={()=> handleDecline()} />}
            {pendingShowModal && <Modal type={"pending-float"} cancel={()=>setPendingShowModal(false)} doAction={()=> onchangeFloatStatus()} />}
            {disbursedShowModal && <Modal type={"disbursed-float"} cancel={()=>setDisbursedShowModal(false)} doAction={()=> handleDisbursedFloat()} />}
        </div>
    )
}


const DirectorInfo = ({
    data, 
    director, 
    viewBVN, 
    viewBankAcc, 
    showBottomBorder=false,
    hasConsent,
    resendConsent,
    resendLoading,
    address,
    passportDocument,
}) => {
    return (
        <div 
            className={clsx(
                "border-t-[1px] border-t-[#DFDBDB] py-8",
                showBottomBorder && "border-b-[1px] border-b-[#DFDBDB]",
            )}
        >
            <h4 className="mx-0 mt-0 mb-2.5 text-[18px] font-medium">Director {director} Information</h4>
            <div className="flex flex-wrap gap-x-8 gap-y-4">
                <div className="font-medium">
                    <p className="text-sm text-[#667085]">Full Name</p>
                    <p className="text-base text-[#344054] whitespace-nowrap">{data?.fullName}</p>
                </div>
                <div className="font-medium">
                    <p className="text-sm text-[#667085]">Email Address</p>
                    <p className="text-base text-[#344054]">{data?.email}</p>
                </div>
                <div className="font-medium">
                    <p className="text-sm text-[#667085]">Phone Number</p>
                    <p className="text-base text-[#344054]">{data?.phone || "n/a"}</p>
                </div>
                <div className="font-medium">
                    <p className="text-sm text-[#667085]">Residential Address</p>
                    <p className="text-base text-[#344054] max-w-[300px]">{address || "n/a"}</p>
                </div>
                <div className="font-medium">
                    <p className="text-sm text-[#667085]">BVN</p>
                    <p className="text-base text-[#0046E6] cursor-pointer" onClick={viewBVN}>View</p>
                </div>
                <div className="font-medium">
                    <p className="text-sm text-[#667085]">Bank Account Number</p>
                    <p className="text-base text-[#0046E6] cursor-pointer" onClick={viewBankAcc}>View</p>
                </div>
                <div className="font-medium">
                    <p className="text-sm text-[#667085]">Consent Status</p>
                    <p 
                        className={clsx(
                            "text-base font-bold",
                            hasConsent ? "text-[#2BBD35]":"text-[#DE213C]"
                        )}
                    >{hasConsent ? "True":"False"}</p>
                    {
                        !hasConsent &&
                        <Button
                            text="Resend Email"
                            isLoading={resendLoading}
                            onClick={()=>{
                                if(!resendLoading){
                                    resendConsent()
                                }
                            }}
                            className="px-2 py-1 !text-sm !rounded bg-ee-primary"
                        />
                    }
                </div>
                <div className="font-medium">
                    <p className="text-sm text-[#667085]">Mandate Status</p>
                    <p 
                        className={clsx(
                            "text-base font-bold",
                            false ? "text-[#2BBD35]" : true ? "text-[#F8650C]" : "text-[#DE213C]"
                        )}
                    >Pending</p>
                    {
                        // !hasConsent &&
                        <Button
                            text="View Details"
                            // isLoading={resendLoading}
                            // onClick={()=>{
                            //     if(!resendLoading){
                            //         resendConsent()
                            //     }
                            // }}
                            className="px-2 py-1 !text-sm !rounded bg-ee-primary"
                        />
                    }
                </div>
                {
                    !!passportDocument?.url &&
                    <div className="font-medium">
                        <p className="text-sm text-[#667085]">Identity Document</p>
                        <div className="-mt-2.5 max-w-[350px]">
                            <FilePreview
                                name="International Passport.pdf"
                                additionalName="Identity document"
                                downloadLink={passportDocument?.url}
                                containerClass="bg-[#EF45651A]"
                                icon={pdfIcon}
                            />
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default Request;