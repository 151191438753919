import clsx from 'clsx';
import search from 'images/svg/search.svg'
import { useSearchParams } from 'react-router-dom'
import { useDebouncedCallback } from "use-debounce";


export default function SearchField ({
    placeholder="Search ...",
    containerClass="",
}) {
    const [searchParams, setSearchParams] = useSearchParams()
    const debounced = useDebouncedCallback((e) => {
        handleChange(e);
    }, 1000);

    const handleChange = (e) => {
        searchParams.set("search", e.target.value);
        searchParams.set("page", 1);
        setSearchParams(searchParams);
    }

    return (
        <div 
            className={clsx(
                "rounded flex w-[250px] h-10 px-2.5 items-center bg-white border-[1px] border-solid border-[#D0D5DD] box-border",
                containerClass
            )}
        >
            <input 
                placeholder={placeholder}
                onChange={debounced}
                className='flex-1 border-0 outline-0 font-normal leading-[16px] text-[#06152B] text-sm'

            />
            <img src={search} alt="search" />
        </div>
    )
}