// import Modal from "../../../Modal"
import { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import account from "../../../../images/svg/settings/account.svg"
import { editAdminProfile } from "../../../../lib/auth";


function Profile () {

    const [form, setForm] = useState({
        name:"",
    })

    let adminId = '';
    try {
        let details = sessionStorage.getItem('eagleEye_details');
        adminId = JSON.parse(details).adminId;
    } catch (err) {
        //err
    }

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
       
        let payload = JSON.stringify({
            adminId,
            name: form.name
        })
        console.log(payload)
        console.log(adminId)
        editAdminProfile(payload)
            .then((response) => response.data)
            .then((res) => {
                console.log(res);
                if (res.status === 'success') {
                    toast.success("Profile updated successfully");
                    sessionStorage.setItem("adminName", JSON.stringify(res.data.name));
                    setTimeout(() => {
                        window.location.reload()
                    }, 1500);
                   
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                if(err.response){
                    toast.error(err.response.data.message);
                } else{
                    toast.error("Something went wrong!!! Please make sure you have a good internet connection.")
                }
            });
    }

    return(
        <div className="apps_create">
            <ToastContainer />
            <h4>Your Profile</h4>
           
            <form className="settings_profile-form">

                <div className="settings_profile-form-div">
                    <label>Name</label>
                    <div className="input">
                        <img src={account} alt="icon" />
                        <input type="text" placeholder="Full Name" name="name" onChange={handleChange} /><br/>
                    </div>
                    
                </div>
               
             

                <button className="apps_create-button settings_profile-form-btn2" onClick={handleSubmit}>Save Changes</button>
            </form>
        </div>
    )
}

export default Profile;