import React from 'react'

import mono from '../../images/svg/box/app/mono.svg'
import crc from '../../images/svg/box/app/crc.svg'
import fc from '../../images/svg/box/app/fc.svg'
import smile from '../../images/svg/box/app/smile.svg'
import cr from '../../images/svg/box/app/cr.svg'
import identity from '../../images/svg/box/app/identity.svg'
import mtech from '../../images/svg/box/app/mtech.svg'
import providus from '../../images/svg/box/app/providus.svg'
import lifeGateIcon from '../../images/svg/box/app/lifeGate.svg'

const ThirdPartyBox = ({boxData, lifeGate, devBoxData, devLifeGateData}) => {
//mono
    const monoCount = (boxData?.item?.mono?.accounts?.count ? boxData?.item?.mono?.accounts?.count : 0) + (devBoxData?.mono?.accounts?.count ? devBoxData?.mono?.accounts?.count : 0);
    const monoCost = (boxData?.item?.mono?.accounts?.cost ? boxData?.item?.mono?.accounts?.cost : 0) + (devBoxData?.mono?.accounts?.cost ? devBoxData?.mono?.accounts?.cost : 0);
    const identityCount = (boxData?.item?.mono?.identity?.count ? boxData?.item?.mono?.identity?.count : 0) + (devBoxData?.mono?.identity?.count ? devBoxData?.mono?.identity?.count : 0)
    const identityCost = (boxData?.item?.mono?.identity?.cost ? boxData?.item?.mono?.identity?.cost : 0) + (devBoxData?.mono?.identity?.cost ? devBoxData?.mono?.identity?.cost : 0)
    const transactionCount = (boxData?.item?.mono?.transaction?.count ? boxData?.item?.mono?.transaction?.count : 0) + (devBoxData?.mono?.transaction?.count ? devBoxData?.mono?.transaction?.count : 0)
    const transactionCost = (boxData?.item?.mono?.transaction?.cost ? boxData?.item?.mono?.transaction?.cost : 0) + (devBoxData?.mono?.transaction?.cost ? devBoxData?.mono?.transaction?.cost : 0)
    // const dataSyncCount = (boxData?.item?.mono?.dataSync?.count ? boxData?.item?.mono?.dataSync?.count : 0) + (devBoxData?.mono?.dataSync?.count ? devBoxData?.mono?.dataSync?.count : 0)
    // const dataSyncCost = (boxData?.item?.mono?.dataSync?.cost ? boxData?.item?.mono?.dataSync?.cost : 0) + (devBoxData?.mono?.dataSync?.cost ? devBoxData?.mono?.dataSync?.cost : 0)

 //first central
    const firstCentralFullReportCount = (boxData?.item?.firstCentral?.fullReport?.count ? boxData?.item?.firstCentral?.fullReport?.count : 0) + (devBoxData?.firstCentral?.count ? devBoxData?.firstCentral?.count : 0)
    const firstCentralFullReportCost = (boxData?.item?.firstCentral?.fullReport?.cost ? boxData?.item?.firstCentral?.fullReport?.cost : 0) + (devBoxData?.firstCentral?.fullReport?.cost ? devBoxData?.firstCentral?.fullReport?.cost : 0)
    const firstCentralSmeCount = (boxData?.item?.firstCentral?.sme?.count ? boxData?.item?.firstCentral?.sme?.count : 0) + (devBoxData?.firstCentral?.count ? devBoxData?.sme?.count : 0)
    const firstCentralSmeCost = (boxData?.item?.firstCentral?.sme?.cost ? boxData?.item?.firstCentral?.sme?.cost : 0) + (devBoxData?.firstCentral?.fullReport?.cost ? devBoxData?.firstCentral?.sme?.cost : 0)
    const firstCentralTotalCount = (boxData?.item?.firstCentral?.totalCount ? boxData?.item?.firstCentral?.totalCount : 0) + (devBoxData?.firstCentral?.totalCount ? devBoxData?.firstCentral?.totalCount : 0 )
    const firstCentralTotalCost = (boxData?.item?.firstCentral?.totalCost ? boxData?.item?.firstCentral?.totalCost : 0) + (devBoxData?.firstCentral?.totalCost ? devBoxData?.firstCentral?.totalCost : 0)
 
//crc
    const CrcClassicStandardCount = (boxData?.item?.crc?.classicStandard?.count ? boxData?.item?.crc?.classicStandard?.count : 0) + (devBoxData?.crc?.classicStandard?.count ? devBoxData?.crc?.classicStandard?.count : 0)
    const CrcClassicStandardCost = (boxData?.item?.crc?.classicStandard?.cost ? boxData?.item?.crc?.classicStandard?.cost : 0) + (devBoxData?.crc?.classicStandard?.cost ? devBoxData?.crc?.classicStandard?.cost : 0)
    const CrcMicroStandardCount = (boxData?.item?.crc?.microStandard?.count ? boxData?.item?.crc?.microStandard?.count : 0) + (devBoxData?.crc?.classicStandard?.count ? devBoxData?.crc?.microStandard?.count : 0)
    const CrcMicroStandardCost = (boxData?.item?.crc?.microStandard?.cost ? boxData?.item?.crc?.microStandard?.cost : 0) + (devBoxData?.crc?.classicStandard?.cost ? devBoxData?.crc?.microStandard?.cost : 0)
    const CrcSmeCount = (boxData?.item?.crc?.sme?.count ? boxData?.item?.crc?.sme?.count : 0) + (devBoxData?.crc?.sme?.count ? devBoxData?.crc?.classicStandard?.count : 0)
    const CrcSmeCost = (boxData?.item?.crc?.sme?.cost ? boxData?.item?.crc?.sme?.cost : 0) + (devBoxData?.crc?.sme?.cost ? devBoxData?.crc?.sme?.cost : 0)
 
//credit registry
    const creditRegistryReportCount = (boxData?.item?.creditRegistry?.report?.count ? boxData?.item?.creditRegistry?.report?.count : 0) + (devBoxData?.creditRegistry?.report?.count ? devBoxData?.creditRegistry?.report?.count : 0)
    const creditRegistryReportCost = (boxData?.item?.creditRegistry?.report?.cost ? boxData?.item?.creditRegistry?.report?.cost : 0) + (devBoxData?.creditRegistry?.report?.cost ? devBoxData?.creditRegistry?.report?.cost : 0)
 
//blacklist
    const blacklistCrcNanoCount = (boxData?.item?.blacklist?.crcNano?.count ? boxData?.item?.blacklist?.crcNano?.count : 0) + (devBoxData?.blacklist?.crcNano?.count ? devBoxData?.blacklist?.crcNano?.count : 0)
    const blacklistCrcNanoCost = (boxData?.item?.blacklist?.crcNano?.cost ? boxData?.item?.blacklist?.crcNano?.count : 0) + (devBoxData?.blacklist?.crcNano?.count ? devBoxData?.blacklist?.crcNano?.count : 0)
    const blacklistFirstCentralNanoCount = (boxData?.item?.blacklist?.firstCentralNano?.count ? boxData?.item?.blacklist?.firstCentralNano?.count : 0) + (devBoxData?.blacklist?.firstCentralNano?.count ? devBoxData?.blacklist?.firstCentralNano?.count : 0)
    const blacklistFirstCentralNanoCost = (boxData?.item?.blacklist?.firstCentralNano?.cost ? boxData?.item?.blacklist?.firstCentralNano?.count : 0) + (devBoxData?.blacklist?.firstCentralNano?.count ? devBoxData?.blacklist?.firstCentralNano?.count : 0)

//mtech
    const radarCount = (boxData?.item?.mtech?.radar?.count ? boxData?.item?.mtech?.radar?.count : 0) + (devBoxData?.mtech?.radar?.count ? devBoxData?.mtech?.radar?.count : 0 )
    const radarCost = (boxData?.item?.mtech?.radar?.cost ? boxData?.item?.mtech?.radar?.cost : 0) + (devBoxData?.mtech?.radar?.cost ? devBoxData?.mtech?.radar?.cost : 0 )
    const recovaCount = (boxData?.item?.mtech?.recova?.count ? boxData?.item?.mtech?.recova?.count : 0) + (devBoxData?.mtech?.recova?.count ? boxData?.item?.mtech?.recova?.count : 0) 
    const recovaCost = boxData?.item?.mtech?.recova?.cost + devBoxData?.mtech?.recova?.cost
console.log(devLifeGateData)
console.log(lifeGate)
//lifegate
    const microStandardCount =(boxData?.item?.lifeGate?.microStandard?.count ? boxData?.item?.lifeGate?.microStandard?.count : 0) + (devBoxData?.lifeGate?.microStandard?.count ? devBoxData?.lifeGate?.microStandard?.count : 0)
    const microStandardCost =(boxData?.item?.lifeGate?.microStandard?.cost ? boxData?.item?.lifeGate?.microStandard?.cost : 0) + (devBoxData?.lifeGate?.microStandard?.cost ? devBoxData?.lifeGate?.microStandard?.cost : 0)
    const classicCount =(boxData?.item?.lifeGate?.classicStandard?.count ? boxData?.item?.lifeGate?.classicStandard?.count : 0) + (devBoxData?.lifeGate?.classicStandard?.count ? devBoxData?.lifeGate?.classicStandard?.count : 0)
    const classicCost =(boxData?.item?.lifeGate?.classicStandard?.cost ? boxData?.item?.lifeGate?.classicStandard?.cost : 0) + (devBoxData?.lifeGate?.classicStandard?.cost ? devBoxData?.lifeGate?.classicStandard?.cost : 0)
    const smeCount =(boxData?.item?.lifeGate?.sme?.count ? boxData?.item?.lifeGate?.sme?.count : 0) + (devBoxData?.lifeGate?.sme?.count ? devBoxData?.lifeGate?.sme?.count : 0)
    const smeCost =(boxData?.item?.lifeGate?.sme?.cost ? boxData?.item?.lifeGate?.sme?.cost : 0) + (devBoxData?.lifeGate?.sme?.cost ? devBoxData?.lifeGate?.sme?.cost : 0)
    // const classicCount = (lifeGate?.classic?.count ? lifeGate?.classic?.count : 0) + (devLifeGateData?.classicStandard?.count ? lifeGate?.classicStandard?.count : 0)
    // const classicCost = (lifeGate?.classic?.cost ? lifeGate?.classic?.cost : 0) + (devLifeGateData?.classicStandard?.cost ? devLifeGateData?.classicStandard?.cost : 0)
    // const creditCRC = (lifeGate?.creditCRC?.count ? lifeGate?.creditCRC?.count : 0) + (devLifeGateData?.creditCRC?.count ? devLifeGateData?.creditCRC?.count : 0 )
    // const creditCRCCost = (lifeGate?.creditCRC?.cost ? lifeGate?.creditCRC?.cost : 0) + (devLifeGateData?.creditCRC?.cost ? devLifeGateData?.creditCRC?.cost : 0)
    
    const commissionCount = (lifeGate?.commission?.totalApiCalls ? lifeGate?.commission?.totalApiCalls : 0) + (devLifeGateData?.commission?.totalApiCalls ? devLifeGateData?.commission?.totalApiCalls : 0)
    const commissionCost = (lifeGate?.commission?.commission ? lifeGate?.commission?.commission : 0) + (devLifeGateData?.commission?.commission ? devLifeGateData?.commission?.commission : 0)

//identity
    const identityBvnCount = (boxData?.item?.identityPass?.bvn?.count ? boxData?.item?.identityPass?.bvn?.count : 0) + (devBoxData?.identityPass?.bvn?.count ? devBoxData?.identityPass?.bvn?.count : 0 )
    const identityBvnCost = (boxData?.item?.identityPass?.bvn?.cost ? boxData?.item?.identityPass?.bvn?.cost : 0) + (devBoxData?.identityPass?.bvn?.cost ? devBoxData?.identityPass?.bvn?.cost : 0 )
    const identityNinCount = (boxData?.item?.identityPass?.nin?.count ? boxData?.item?.identityPass?.nin?.count : 0) + (devBoxData?.identityPass?.nin?.count ? devBoxData?.identityPass?.nin?.count : 0 )
    const identityNinCost = (boxData?.item?.identityPass?.nin?.cost ? boxData?.item?.identityPass?.nin?.cost : 0) + (devBoxData?.identityPass?.nin?.cost ? devBoxData?.identityPass?.nin?.cost : 0 )
    const identityCacCount = (boxData?.item?.identityPass?.cac?.count ? boxData?.item?.identityPass?.cac?.count : 0) + (devBoxData?.identityPass?.cac?.count ? devBoxData?.identityPass?.cac?.count : 0 )
    const identityCacCost = (boxData?.item?.identityPass?.cac?.cost ? boxData?.item?.identityPass?.cac?.cost : 0) + (devBoxData?.identityPass?.cac?.cost ? devBoxData?.identityPass?.cac?.cost : 0 )
    const identityPvcCount = (boxData?.item?.identityPass?.identityPvc?.count ? boxData?.item?.identityPass?.identityPvc?.count : 0) + (devBoxData?.identityPass?.identityPvc?.count ? devBoxData?.identityPass?.identityPvc?.count : 0 )
    const identityPvcCost = (boxData?.item?.identityPass?.identityPvc?.cost ? boxData?.item?.identityPass?.identityPvc?.cost : 0) + (devBoxData?.identityPass?.identityPvc?.cost ? devBoxData?.identityPass?.identityPvc?.cost : 0 )
    const identityBasicCacCount = (boxData?.item?.identityPass?.cacBasic?.count ? boxData?.item?.identityPass?.cacBasic?.count : 0) + (devBoxData?.identityPass?.cacBasic?.count ? devBoxData?.identityPass?.cacBasic?.count : 0 )
    const identityBasicCacCost = (boxData?.item?.identityPass?.cacBasic?.cost ? boxData?.item?.identityPass?.cacBasic?.cost : 0) + (devBoxData?.identityPass?.cacBasic?.cost ? devBoxData?.identityPass?.cacBasic?.cost : 0 )
    const identityDriversLicenseCount = (boxData?.item?.identityPass?.driverLicense?.count ? boxData?.item?.identityPass?.driverLicense?.count : 0) + (devBoxData?.identityPass?.driverLicense?.count ? devBoxData?.identityPass?.driverLicense?.count : 0 )
    const identityDriversLicenseCost = (boxData?.item?.identityPass?.driverLicense?.cost ? boxData?.item?.identityPass?.driverLicense?.cost : 0) + (devBoxData?.identityPass?.driverLicense?.cost ? devBoxData?.identityPass?.driverLicense?.cost : 0 )
    const identityBankAccountCount = (boxData?.item?.identityPass?.bankAccount?.count ? boxData?.item?.identityPass?.bankAccount?.count : 0) + (devBoxData?.identityPass?.bankAccount?.count ? devBoxData?.identityPass?.bankAccount?.count : 0 )
    const identityBankAccountCost = (boxData?.item?.identityPass?.bankAccount?.cost ? boxData?.item?.identityPass?.bankAccount?.cost : 0) + (devBoxData?.identityPass?.bankAccount?.cost ? devBoxData?.identityPass?.bankAccount?.cost : 0 )
    const identityBasicAccountNumberCount = (boxData?.item?.identityPass?.basicAccountNumber?.count ? boxData?.item?.identityPass?.basicAccountNumber?.count : 0) + (devBoxData?.identityPass?.basicAccountNumber?.count ? devBoxData?.identityPass?.bankAccount?.count : 0 )
    const identityBasicAccountNumberCost = (boxData?.item?.identityPass?.basicAccountNumber?.cost ? boxData?.item?.identityPass?.basicAccountNumber?.cost : 0) + (devBoxData?.identityPass?.basicAccountNumber?.cost ? devBoxData?.identityPass?.bankAccount?.cost : 0 )                                                                             

    var formatMoney = new Intl.NumberFormat('en-NG', {
        style: 'currency',
        currency: 'NGN',
        //minimumFractionDigits: 0, 
        maximumFractionDigits: 2, 
      });

    let adminRole = ''

    try {
        let details = sessionStorage.getItem('eagleEye_details');
        adminRole = JSON.parse(details).adminRole;
    } catch (err) {
        //err
    }
    
  return (
    <>
        {
            adminRole!=="Life Gate"  && 
            <>
                {/* first step */}
                <div style={{marginTop: '4em'}}>
                <div style={{display:'flex', alignItems: 'center', margin: '20px 0'}}>
                        <div style={{width:'32.94px', height: '32.94px', marginRight: '10px'}}><img style={{width:'100%', height:'100%'}} src={mono} alt="" /></div>
                        <h5>Mono</h5>
                    </div>
                    
                
                    <div className='box_container'>
    
    
                        <div className='box_container-thirdParty'>
    
                            <div className='box_container-thirdParty-details'>
                                
                                <h6> Accounts </h6>
                                <div style={{display: 'flex', justifyContent: 'space-between', }}>
                                    <div>
                                        <h5> { monoCount ? monoCount : 0 }</h5>
                                        <p>Total API Calls</p>
                                    </div>
                                    <div>
                                        <h5>  {monoCost ? formatMoney.format(monoCost) : formatMoney.format(0)}</h5>
                                        <p>Total Value</p>
                                    </div>
                                </div>
                            </div>
    
                        </div>
    
                        <div className='box_container-thirdParty'>
    
                            <div className='box_container-thirdParty-details'>
                                
                                <h6> Identity </h6>
                                <div style={{display: 'flex', justifyContent: 'space-between', }}>
                                    <div>
                                        <h5> {identityCount ? identityCount : 0}</h5>
                                        <p>Total API Calls</p>
                                    </div>
                                    <div>
                                        <h5>  {identityCost ? formatMoney.format(identityCost) : formatMoney.format(0)}</h5>
                                        <p>Total Value</p>
                                    </div>
                                </div>
                            </div>
    
                        </div>
                        <div className='box_container-thirdParty'>
    
                            <div className='box_container-thirdParty-details'>
                                
                                <h6> Transactions </h6>
                                <div style={{display: 'flex', justifyContent: 'space-between', }}>
                                    <div>
                                        <h5> {transactionCount ? transactionCount : 0}</h5>
                                        <p>Total API Calls</p>
                                    </div>
                                    <div>
                                        <h5>  {transactionCost ? formatMoney.format(transactionCost) : formatMoney.format(0)}</h5>
                                        <p>Total Value</p>
                                    </div>
                                </div>
                            </div>
    
                        </div>
                        
                        {/* <div className='box_container-thirdParty'>
    
                            <div className='box_container-thirdParty-details'>
                                
                                <h6> Data Sync </h6>
                                <div style={{display: 'flex', justifyContent: 'space-between', }}>
                                    <div>
                                        <h5> {dataSyncCount ? dataSyncCount : 0}</h5>
                                        <p>Total API Calls</p>
                                    </div>
                                    <div>
                                        <h5>  {dataSyncCost ? formatMoney.format(dataSyncCost) : formatMoney.format(0)}</h5>
                                        <p>Total Value</p>
                                    </div>
                                </div>
                            </div>
    
                        </div> */}
                        
                    </div>
                </div>

                {/* second step >>> first central */}
                <div style={{marginTop: '4em'}}>
                    <div style={{display:'flex', alignItems: 'center', margin: '20px 0'}}>
                        <div style={{width:'32.94px', height: '32.94px', marginRight: '10px'}}><img style={{width:'100%', height:'100%'}} src={fc} alt="" /></div>
                        <h5>First Central</h5>
                    </div>
                    
                    <div className='box_container'>
    
    
                        <div className='box_container-thirdParty'>
    
                            <div className='box_container-thirdParty-details'>
                                
                                <h6> Full Report </h6>
                                <div style={{display: 'flex', justifyContent: 'space-between' }}>
                                    <div>
                                    <h5>  {firstCentralFullReportCount ? firstCentralFullReportCount : 0}</h5>
                                    <p>Total API Calls</p>
                                    </div>
                                    <div>
                                    <h5>  {firstCentralFullReportCost ? formatMoney.format(firstCentralFullReportCost) : formatMoney.format(0)}</h5>
                                    <p>Total Value</p>
                                    </div>
                                </div>
                            </div>
    
                        </div>
    
                        <div className='box_container-thirdParty'>
    
                            <div className='box_container-thirdParty-details'>
                                
                                <h6> SME Report </h6>
                                <div style={{display: 'flex', justifyContent: 'space-between', }}>
                                    <div>
                                        <h5> {firstCentralSmeCount ? firstCentralSmeCount : 0}</h5>
                                        <p>Total API Calls</p>
                                    </div>
                                    <div>
                                        <h5>  {firstCentralSmeCost ? formatMoney.format(firstCentralSmeCost) : formatMoney.format(0)}</h5>
                                        <p>Total Value</p>
                                    </div>
                                </div>
                            </div>
    
                        </div>
                        <div className='box_container-thirdParty'>
    
                            <div className='box_container-thirdParty-details'>
                                
                                <h6> Total </h6>
                                <div style={{display: 'flex', justifyContent: 'space-between', }}>
                                    <div>
                                        <h5> {firstCentralTotalCount ? firstCentralTotalCount : 0}</h5>
                                        <p>Total API Calls</p>
                                    </div>
                                    <div>
                                        <h5>  {firstCentralTotalCost ? formatMoney.format(firstCentralTotalCost) : formatMoney.format(0)}</h5>
                                        <p>Total Value</p>
                                    </div>
                                </div>
                            </div>
    
                        </div>
                        
                        {/* <div className='box_container-thirdParty'>
    
                            <div className='box_container-thirdParty-details'>
                                
                                <h6> Data Sync </h6>
                                <div style={{display: 'flex', justifyContent: 'space-between', }}>
                                    <div>
                                        <h5> {dataSyncCount ? dataSyncCount : 0}</h5>
                                        <p>Total API Calls</p>
                                    </div>
                                    <div>
                                        <h5>  {dataSyncCost ? formatMoney.format(dataSyncCost) : formatMoney.format(0)}</h5>
                                        <p>Total Value</p>
                                    </div>
                                </div>
                            </div>
    
                        </div> */}
                        
                    </div>
                </div>

                {/* step three >>> CRC */}
                <div style={{marginTop: '4em'}}>
                    <div style={{display:'flex', alignItems: 'center', margin: '20px 0'}}>
                        <div style={{width:'32.94px', height: '32.94px', marginRight: '10px'}}><img style={{width:'100%', height:'100%'}} src={crc} alt="" /></div>
                        <h5>CRC </h5>
                    </div>
                    
                    <div className='box_container'>
    
    
                        <div className='box_container-thirdParty'>
    
                            <div className='box_container-thirdParty-details'>
                                
                                <h6> CRC Classic </h6>
                                <div style={{display: 'flex', justifyContent: 'space-between', }}>
                                    <div>
                                    <h5>{CrcClassicStandardCount||0}</h5>
                                    <p>Total API Calls</p>
                                    </div>
                                    <div>
                                    <h5>  {CrcClassicStandardCost ? formatMoney.format(CrcClassicStandardCost) : formatMoney.format(0)}</h5>
                                    <p>Total Value</p>
                                    </div>
                                </div>
                            </div>
    
                        </div>
    
                        <div className='box_container-thirdParty'>
    
                            <div className='box_container-thirdParty-details'>
                                
                                <h6> SME report-classic </h6>
                                <div style={{display: 'flex', justifyContent: 'space-between', }}>
                                    <div>
                                        <h5> {CrcSmeCount ? CrcSmeCount : 0}</h5>
                                        <p>Total API Calls</p>
                                    </div>
                                    <div>
                                        <h5>  {CrcSmeCost ? formatMoney.format(CrcSmeCost) : formatMoney.format(0)}</h5>
                                        <p>Total Value</p>
                                    </div>
                                </div>
                            </div>
    
                        </div>
                        
                        <div className='box_container-thirdParty'>
    
                            <div className='box_container-thirdParty-details'>
                                
                                <h6> CRC Microstandard </h6>
                                <div style={{display: 'flex', justifyContent: 'space-between', }}>
                                    <div>
                                        <h5> {CrcMicroStandardCount ? CrcMicroStandardCount : 0}</h5>
                                        <p>Total API Calls</p>
                                    </div>
                                    <div>
                                        <h5>  {CrcMicroStandardCost ? formatMoney.format(CrcMicroStandardCost) : formatMoney.format(0)}</h5>
                                        <p>Total Value</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* step four >>> credit registry */}

                <div style={{marginTop: '2.5em'}}>

                    <div style={{display:'flex', alignItems: 'center', margin: '20px 0'}}>
                        <div style={{width:'32.94px', height: '32.94px', marginRight: '10px'}}><img style={{width:'100%', height:'100%'}} src={cr} alt="smile" /></div>
                        <h5>Credit Registry</h5>
                    </div>
                
                    <div className='box_container'>
    
    
                    <div className='box_container-thirdParty'>
    
                        <div className='box_container-thirdParty-details'>
                            
                            <h6> Full Report </h6>
                            <div style={{display: 'flex', justifyContent: 'space-between', }}>
                                <div style={{display: 'flex', justifyContent: 'space-between', width: "100%" }}>
                                    <div>
                                        <h5> {creditRegistryReportCount ? creditRegistryReportCount : 0}</h5>
                                        <p>Total API Calls</p>
                                    </div>
                                    <div>
                                        <h5>  {creditRegistryReportCost ? formatMoney.format(creditRegistryReportCount) : formatMoney.format(0)}</h5>
                                        <p>Total Value</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>

                {/* step five >>> blacklist */}

                <div style={{marginTop: '2.5em'}}>

                    <div style={{display:'flex', alignItems: 'center', margin: '20px 0'}}>
                        <div style={{width:'32.94px', height: '32.94px', marginRight: '10px'}}><img style={{width:'100%', height:'100%'}} src={smile} alt="smile" /></div>
                        <h5>Blacklist</h5>
                    </div>
                
                    <div className='box_container'>
    
    
                    <div className='box_container-thirdParty'>
    
                        <div className='box_container-thirdParty-details'>
                            
                            <h6> CRC Nano </h6>
                            <div style={{display: 'flex', justifyContent: 'space-between', }}>
                                <div style={{display: 'flex', justifyContent: 'space-between', width: "100%" }}>
                                    <div>
                                        <h5> {blacklistCrcNanoCount ? blacklistCrcNanoCount : 0}</h5>
                                        <p>Total API Calls</p>
                                    </div>
                                    <div>
                                        <h5>  {blacklistCrcNanoCost ? formatMoney.format(blacklistCrcNanoCost) : formatMoney.format(0)}</h5>
                                        <p>Total Value</p>
                                    </div>
                            </div>
                            </div>
                        </div>
    
                        </div>
                    <div className='box_container-thirdParty'>
    
                        <div className='box_container-thirdParty-details'>
                            
                            <h6> First Central Basic</h6>
                            <div style={{display: 'flex', justifyContent: 'space-between', }}>
                            <div>
                                        <h5> {blacklistFirstCentralNanoCount ? blacklistFirstCentralNanoCount : 0}</h5>
                                        <p>Total API Calls</p>
                                    </div>
                                    <div>
                                        <h5>  {blacklistFirstCentralNanoCost ? formatMoney.format(blacklistFirstCentralNanoCost) : formatMoney.format(0)}</h5>
                                        <p>Total Value</p>
                                    </div>
                            </div>
                        </div>
    
                        </div>
    
                    
                    </div>
                </div>


            {/* lifegate */}
            <div style={{marginTop: '2.5em'}}>
                    <div style={{display:'flex', alignItems: 'center', margin: '10px 0'}}>
                        <div style={{width:'32.94px', height: '32.94px', marginRight: '10px'}}><img style={{width:'100%', height:'100%'}} src={lifeGateIcon} alt="" /></div>
                        <h5>Lifegate</h5>
                    </div>
                    <div className='box_container'>
                        {/* <div className='box_container-thirdParty'>
                            <div className='box_container-thirdParty-details'>
                                
                                <h6>CRC Classic</h6>
                                <div style={{display: 'flex', justifyContent: 'space-between', }}>
                                    <div>
                                        <h5> {creditCRC ? creditCRC : 0}</h5>
                                        <p>Total API Calls</p>
                                    </div>
                                    <div>
                                        <h5>  {creditCRCCost ? formatMoney.format(creditCRCCost) : formatMoney.format(0)}</h5>
                                        <p>Total Value</p>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <div className='box_container-thirdParty'>
                            <div className='box_container-thirdParty-details'>
                                
                                <h6>Classic Standard</h6>
                                <div style={{display: 'flex', justifyContent: 'space-between', }}>
                                    <div>
                                    <h5> {classicCount ? classicCount : 0}</h5>
                                        <p>Total API Calls</p>
                                    </div>
                                    <div>
                                        <h5>  {classicCost ? formatMoney.format(classicCost) : formatMoney.format(0)}</h5>
                                        <p>Total Value</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='box_container-thirdParty'>
                        <div className='box_container-thirdParty-details'>
                            
                            <h6>SME report-classic</h6>
                            <div style={{display: 'flex', justifyContent: 'space-between', }}>
                                    <div>
                                        <h5> {smeCount ? smeCount : 0}</h5>
                                        <p>Total API Calls</p>
                                    </div>
                                    <div>
                                        <h5>  {smeCost ? formatMoney.format(smeCount) : formatMoney.format(0)}</h5>
                                        <p>Total Commission</p>
                                    </div>
                            </div>
                        </div>
                    </div>
                    <div className='box_container-thirdParty'>
                        <div className='box_container-thirdParty-details'>
                            
                            <h6>CRC Micro Standard</h6>
                            <div style={{display: 'flex', justifyContent: 'space-between', }}>
                                <div>
                                    <h5> {microStandardCount ? microStandardCount : 0}</h5>
                                    <p>Total API Calls</p>
                                </div>
                                <div>
                                    <h5>  {microStandardCost ? formatMoney.format(microStandardCost) : formatMoney.format(0)}</h5>
                                    <p>Total Value</p>
                                </div>
                            </div>
                        </div>
                    </div>
                        <div className='box_container-thirdParty'>
                        <div className='box_container-thirdParty-details'>
                            
                            <h6>Commission</h6>
                            <div style={{display: 'flex', justifyContent: 'space-between', }}>
                                    <div>
                                        <h5> {commissionCount ? commissionCount : 0}</h5>
                                        <p>Total API Calls</p>
                                    </div>
                                    <div>
                                        <h5>  {commissionCost ? formatMoney.format(commissionCost) : formatMoney.format(0)}</h5>
                                        <p>Total Commission</p>
                                    </div>
                            </div>
                        </div>
                    </div>
                   
                    </div>
                </div>

            {/* mtech & providus */}
                <div style={{marginTop: '2.5em'}}>
                <div style={{display: 'flex',alignItems: 'center'}}>
                    <div>
                        <div style={{display:'flex', alignItems: 'center', margin: '10px 0'}}>
                            <div style={{width:'32.94px', height: '32.94px', marginRight: '10px'}}><img style={{width:'100%', height:'100%'}} src={mtech} alt="" /></div>
                            <h5>Mtech</h5>
                        </div>

                        <div className='box_container'>
    
                    <div className='box_container-thirdParty'>
    
                        <div className='box_container-thirdParty-details'>
                            
                            <h6> Radar </h6>
                            <div style={{display: 'flex', justifyContent: 'space-between', }}>
                                    <div>
                                        <h5> {radarCount ? radarCount : 0}</h5>
                                        <p>Total API Calls</p>
                                    </div>
                                    <div>
                                        <h5>  {radarCost ? formatMoney.format(radarCost) : formatMoney.format(0)}</h5>
                                        <p>Total Value</p>
                                    </div>
                            </div>
                        </div>
    
                        </div>
                    </div>


                </div>
              
                <div>
                <div style={{display:'flex', alignItems: 'center', margin: '10px 0'}}>
                        <div style={{width:'32.94px', height: '32.94px', marginRight: '10px'}}><img style={{width:'100%', height:'100%'}} src={providus} alt="" /></div>
                        <h5>Providus</h5>
                    </div>
                    <div className='box_container-thirdParty'>
    
                        <div className='box_container-thirdParty-details'>
                            
                            <h6> Recova </h6>
                            <div style={{display: 'flex', justifyContent: 'space-between', }}>
                                    <div>
                                        <h5> {recovaCount ? recovaCount : 0}</h5>
                                        <p>Total API Calls</p>
                                    </div>
                                    <div>
                                        <h5>  {recovaCost ? formatMoney.format(recovaCost) : formatMoney.format(0)}</h5>
                                        <p>Total Value</p>
                                    </div>
                            </div>
                        </div>
    
                        </div>
                    </div>

                </div>
                

                </div>


            {/* identity */}
            <div style={{marginTop: '2.5em'}}>
                    <div style={{display:'flex', alignItems: 'center', margin: '10px 0'}}>
                        <div style={{width:'32.94px', height: '32.94px', marginRight: '10px'}}><img style={{width:'100%', height:'100%'}} src={identity} alt="" /></div>
                        <h5>Identity</h5>
                    </div>
                    <div className='box_container'>
                        <div className='box_container-thirdParty'>
                            <div className='box_container-thirdParty-details'>
                                
                                <h6> BVN </h6>
                                <div style={{display: 'flex', justifyContent: 'space-between', }}>
                                    <div>
                                        <h5> {identityBvnCount ? identityBvnCount : 0}</h5>
                                        <p>Total API Calls</p>
                                    </div>
                                    <div>
                                        <h5>  {identityBvnCost ? formatMoney.format(identityBvnCost) : formatMoney.format(0)}</h5>
                                        <p>Total Value</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='box_container-thirdParty'>
                            <div className='box_container-thirdParty-details'>
                                
                                <h6>NIN</h6>
                                <div style={{display: 'flex', justifyContent: 'space-between', }}>
                                    <div>
                                    <h5> {identityNinCount ? identityNinCount : 0}</h5>
                                        <p>Total API Calls</p>
                                    </div>
                                    <div>
                                        <h5>  {identityNinCost ? formatMoney.format(identityNinCost) : formatMoney.format(0)}</h5>
                                        <p>Total Value</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='box_container-thirdParty'>
                            <div className='box_container-thirdParty-details'>
                                
                                <h6>PVC</h6>
                                <div style={{display: 'flex', justifyContent: 'space-between', }}>
                                    <div>
                                    <h5> {identityPvcCount ? identityPvcCount : 0}</h5>
                                        <p>Total API Calls</p>
                                    </div>
                                    <div>
                                        <h5>  {identityPvcCost ? formatMoney.format(identityPvcCost) : formatMoney.format(0)}</h5>
                                        <p>Total Value</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='box_container-thirdParty'>
                            <div className='box_container-thirdParty-details'>
                                
                                <h6>CAC</h6>
                                <div style={{display: 'flex', justifyContent: 'space-between', }}>
                                    <div>
                                    <h5> {identityCacCount ? identityCacCount : 0}</h5>
                                        <p>Total API Calls</p>
                                    </div>
                                    <div>
                                        <h5>  {identityCacCost ? formatMoney.format(identityCacCost) : formatMoney.format(0)}</h5>
                                        <p>Total Value</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='box_container-thirdParty'>
                            <div className='box_container-thirdParty-details'>
                                
                                <h6>Basic CAC</h6>
                                <div style={{display: 'flex', justifyContent: 'space-between', }}>
                                    <div>
                                    <h5> {identityBasicCacCount ? identityBasicCacCount : 0}</h5>
                                        <p>Total API Calls</p>
                                    </div>
                                    <div>
                                        <h5>  {identityBasicCacCost ? formatMoney.format(identityBasicCacCost) : formatMoney.format(0)}</h5>
                                        <p>Total Value</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='box_container-thirdParty'>
                        <div className='box_container-thirdParty-details'>
                            
                            <h6>Driver's License</h6>
                            <div style={{display: 'flex', justifyContent: 'space-between', }}>
                                    <div>
                                        <h5> {identityDriversLicenseCount ? identityDriversLicenseCount : 0}</h5>
                                        <p>Total API Calls</p>
                                    </div>
                                    <div>
                                        <h5>  {identityDriversLicenseCost ? formatMoney.format(identityDriversLicenseCost) : formatMoney.format(0)}</h5>
                                        <p>Total Commission</p>
                                    </div>
                            </div>
                        </div>
                    </div>
                    <div className='box_container-thirdParty'>
                        <div className='box_container-thirdParty-details'>
                            
                            <h6>Bank Acc Verification</h6>
                            <div style={{display: 'flex', justifyContent: 'space-between', }}>
                                <div>
                                    <h5> {identityBankAccountCount ? identityBankAccountCount : 0}</h5>
                                    <p>Total API Calls</p>
                                </div>
                                <div>
                                    <h5>  {identityBankAccountCost ? formatMoney.format(identityBankAccountCost) : formatMoney.format(0)}</h5>
                                    <p>Total Value</p>
                                </div>
                            </div>
                        </div>
                    </div>
                        <div className='box_container-thirdParty'>
                        <div className='box_container-thirdParty-details'>
                            
                            <h6>RC Number</h6>
                            <div style={{display: 'flex', justifyContent: 'space-between', }}>
                                    <div>
                                        <h5> {commissionCount ? commissionCount : 0}</h5>
                                        <p>Total API Calls</p>
                                    </div>
                                    <div>
                                        <h5>  {commissionCost ? formatMoney.format(commissionCost) : formatMoney.format(0)}</h5>
                                        <p>Total Commission</p>
                                    </div>
                            </div>
                        </div>
                    </div>
                        <div className='box_container-thirdParty'>
                            <div className='box_container-thirdParty-details'>
                                
                                <h6>Basic Acc No Verification</h6>
                                <div style={{display: 'flex', justifyContent: 'space-between', }}>
                                        <div>
                                            <h5> {identityBasicAccountNumberCount ? identityBasicAccountNumberCount : 0}</h5>
                                            <p>Total API Calls</p>
                                        </div>
                                        <div>
                                            <h5>  {identityBasicAccountNumberCost ? formatMoney.format(identityBasicAccountNumberCost) : formatMoney.format(0)}</h5>
                                            <p>Total Commission</p>
                                        </div>
                                </div>
                            </div>
                        </div>
                   
                    </div>
                </div>

            </>
        }
        {
            adminRole==="Life Gate"  && 
            <div style={{marginTop: '2.5em'}}>
                    <div style={{display:'flex', alignItems: 'center', margin: '10px 0'}}>
                        <div style={{width:'32.94px', height: '32.94px', marginRight: '10px'}}><img style={{width:'100%', height:'100%'}} src={lifeGateIcon} alt="" /></div>
                        <h5>Lifegate</h5>
                    </div>
                    <div className='box_container'>
                     
                        <div className='box_container-thirdParty'>
                            <div className='box_container-thirdParty-details'>
                                
                                <h6>Classic Standard</h6>
                                <div style={{display: 'flex', justifyContent: 'space-between', }}>
                                    <div>
                                    <h5> {classicCount ? classicCount : 0}</h5>
                                        <p>Total API Calls</p>
                                    </div>
                                    <div>
                                        <h5>  {classicCost ? formatMoney.format(classicCost) : formatMoney.format(0)}</h5>
                                        <p>Total Value</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='box_container-thirdParty'>
                        <div className='box_container-thirdParty-details'>
                            
                            <h6>SME report-classic</h6>
                            <div style={{display: 'flex', justifyContent: 'space-between', }}>
                                    <div>
                                        <h5> {smeCount ? smeCount : 0}</h5>
                                        <p>Total API Calls</p>
                                    </div>
                                    <div>
                                        <h5>  {smeCost ? formatMoney.format(smeCount) : formatMoney.format(0)}</h5>
                                        <p>Total Commission</p>
                                    </div>
                            </div>
                        </div>
                    </div>
                    <div className='box_container-thirdParty'>
                        <div className='box_container-thirdParty-details'>
                            
                            <h6>CRC Micro Standard</h6>
                            <div style={{display: 'flex', justifyContent: 'space-between', }}>
                                <div>
                                    <h5> {microStandardCount ? microStandardCount : 0}</h5>
                                    <p>Total API Calls</p>
                                </div>
                                <div>
                                    <h5>  {microStandardCost ? formatMoney.format(microStandardCost) : formatMoney.format(0)}</h5>
                                    <p>Total Value</p>
                                </div>
                            </div>
                        </div>
                    </div>
                        <div className='box_container-thirdParty'>
                        <div className='box_container-thirdParty-details'>
                            
                            <h6>Commission</h6>
                            <div style={{display: 'flex', justifyContent: 'space-between', }}>
                                    <div>
                                        <h5> {commissionCount ? commissionCount : 0}</h5>
                                        <p>Total API Calls</p>
                                    </div>
                                    <div>
                                        <h5>  {commissionCost ? formatMoney.format(commissionCost) : formatMoney.format(0)}</h5>
                                        <p>Total Commission</p>
                                    </div>
                            </div>
                        </div>
                    </div>
                   
                    </div>
                </div>
        }
    </>
  )
}

export default ThirdPartyBox