// import previous from "../../images/svg/previous-page.svg";


function Pagination ({changePage, page, totalPages}) {
    console.log(page)
    // const handleChange = (e) => {
    //     changePage("input", e.target.value)
    // }

    return(
        <div>
            {/* <p onClick={()=>changePage(1)}>paginate</p> */}
            {/* <div className="table_page">
                <img src={previous} alt="previous" onClick={()=>changePage("prev", page-1)} />
                <input placeholder={page+1} onChange={handleChange} type="number" />
                <p>/ {totalPages}</p>
                <img src={previous} alt="next" className="next" onClick={()=>changePage("next", page+1)} />
            </div> */}
            <div className="table_page">
                {
                    page+1<3 ? "" :
                    <div className="table_page-num" onClick={()=>changePage("prev", page-2)}><p>{page-1}</p></div>
                }
                {
                    page+1<2 ? "" :
                    <div className="table_page-num" onClick={()=>changePage("prev", page-1)}><p>{page}</p></div>
                }
                {
                    totalPages > 1 && <div className="table_page-num active"><p>{page+1}</p></div>
                }
                {
                    totalPages-1 < page+1 ? "" :
                    <div className="table_page-num" onClick={()=>changePage("next", page+1)}><p>{page+2}</p></div>
                }
                {
                    totalPages-1 < page+2 ? "" :
                    <div className="table_page-num" onClick={()=>changePage("next", page+2)}><p>{page+3}</p></div>
                }
            </div>
        </div>
    )
}

export default Pagination;