import { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Back from '../../../components/back'
import DetailsBox from "./components/DetailsBox";
import { handleRequestErrors } from "utils/helpers";
import { SpectrumService } from "services/spectrum.service";


function Spectrum () {
    let params = useParams()
    let navigate = useNavigate();
    const [data, setData] = useState(null);

    useEffect(()=>{
        SpectrumService.getBusinessSpectrumData(params.id)
            .then(response => response.data)
            .then((res) => {
                if(res.status === 'success'){
                    setData(res.data)
                }
            })
            .catch((err) => {
                handleRequestErrors(err)
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <Back to={()=> navigate(-1)} />
            <DetailsBox businessData={data?.businessId} acceptedTerms={data?.acceptedTerms} />
        </div>
    )
}

export default Spectrum;