import { useState, useCallback } from 'react';

export default function useSingleState(initialValue) {
    const [state, setState] = useState(initialValue);

    const get = state;

    const set = useCallback((value) => {
        setState(value);
    }, []);

    return { get, set };
}
