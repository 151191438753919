import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import Table from '../../../components/Table';
import { capitalizeFirstLetter, dateFormatter, handleRequestErrors } from 'utils/helpers';
import Status from 'components/Status';
import DataBox from 'components/shared/DataBox';
import TopBusinesses from '../Overview/components/TopBusinesses';
import clsx from 'clsx';
import walletBlue from 'images/svg/box/walletBlue.svg'
import walletGreen from 'images/svg/box/walletGreen.svg'
import walletAsh from 'images/svg/box/walletAsh.svg'
import graph from 'images/svg/box/graph.svg'
import graphRed from 'images/svg/box/graphRed.svg'
import user from 'images/svg/box/user.svg'
import { useSearchParams } from 'react-router-dom';
import Filter from 'components/shared/Filter';
import selectArrow from "images/svg/select-arrow.svg";
import { BusinessService } from 'services/business.service';
import SearchField from 'components/shared/SearchField';
import Tabs from 'components/shared/Tabs';


const Businesses = ({setDetailName}) => {
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [CSVItems, setCSVItems] = useState([]);
  const [searchParams] = useSearchParams();
  const query = searchParams.get("filter") || "this_month"
  const startDate = searchParams.get("customStart")||""
  const endDate = searchParams.get("customEnd")||""
  const page = searchParams.get("page") || 1
  const searchTerm = searchParams.get("search") || ""
  const tableFilter = searchParams.get("tableFilter") || ""
  

  useEffect(() => {
    setDetailName("");
  }, [setDetailName]);

  const [boxData, setBoxData] = useState({
    boxFor: "businesses",
    item: []
  });

  const [tableData, setTableData] = useState(null);

  useEffect(() => {
    BusinessService.businessStats(query)
      .then(response => response.data)
      .then((res) => {
        if(res.status === 'success'){
          setBoxData({
            ...boxData,
            item: res.data
          })
        } 
      })
      .catch((err) => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  useEffect(() => {
    if(tableFilter==="active" || tableFilter==="inactive"){
      getTableByType()
    } else {
      getTableByStatus()
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableFilter, page, searchTerm])

  const getTableByStatus = () => {
    setIsTableLoading(true)
    BusinessService.getBusinessByStatus({
      page: page,
      search: searchTerm,
      status: tableFilter
    })
      .then(response => response.data)
      .then((res) => {
        setIsTableLoading(false)
        if(res.status === 'success'){
          setTableData(res.data)
        } else {
          toast.error("Something went wrong, please try again!!!");
        }
      })
      .catch((err) => {
        setIsTableLoading(false)
        handleRequestErrors(err, false, "120000")
      });
  }

  const getTableByType = () => {
    setIsTableLoading(true)
    BusinessService.getBusinessByType({
      page: page,
      search: searchTerm,
      type: tableFilter
    })
      .then(response => response.data)
      .then((res) => {
        setIsTableLoading(false)
        if(res.status === 'success'){
          setTableData(res.data)
        } else {
          toast.error("Something went wrong, please try again!!!");
        }
      })
      .catch((err) => {
        setIsTableLoading(false)
        handleRequestErrors(err, false, "120000")
      });
  }

  const filterOptions = [
    { label: "Last 7 days", value: "last_seven_days"}, 
    { label: "This Month", value: "this_month"}, 
    { label: "Last Month", value: "last_month"}, 
    { label: "Last 3 Months", value: "last_three_months"}, 
    { label: "Last 6 Months", value: "last_six_months"}, 
    { label: "Last 12 Months", value: "last_twelve_months"}, 
    { label: "Custom", value: "custom"},
  ]

  const downloadCSV = () => {
    const base_url = process.env.REACT_APP_ENVIRONMENT === "production" ?
      process.env.REACT_APP_PRODUCTION : process.env.REACT_APP_LOCAL;

    const redirect_url = (tableFilter==="active" || tableFilter==="inactive") ?
      `${base_url}/eagleeyeauth/business/business-type/csv?search=${searchTerm}&type=${tableFilter}`
      :
      `${base_url}/eagleeyeauth/business/business-data/csv?search=${searchTerm}&status=${tableFilter}`
    
     window.open(redirect_url, "_blank")
  }

  return (
    <div className='mt-10 mb-[60px]'>
      <div className='flex justify-end mb-10'>
        <Filter
          placeHolder="This Month"
          options={
            filterOptions.map((item) => ({
              label: item.label,
              value: item.value
            }))
          } 
          containerClass="border-ee-primary text-base text-[#8A8A8A] flex-row-reverse"
          icon={selectArrow}
          iconClassName="!w-[10px]"
          optionItemClass="text-sm"
        />
      </div>
      <div className='grid grid-cols-2 gap-x-10 box-border mb-10'>
        <div>
          <div className='grid grid-cols-2 gap-[20px] box-border'>
            {
              [
                {
                  title: "New Businesses",
                  value: boxData?.item?.newBusinesses || 0,
                  icon: walletBlue,
                  percentageDifference: boxData?.item?.businessPercent,
                },
                {
                  title: "Approved Businesses",
                  value: boxData?.item?.approvedBusinesses||0,
                  icon: walletGreen,
                },
                {
                  title: "Inactive Businesses",
                  value: boxData?.item?.inactiveBusinesses||0,
                  icon: walletAsh,
                },
                {
                  title: "Total Businesses",
                  value: boxData?.item?.totalBusinesses||0,
                  icon: walletBlue,
                },
                {
                  title: "Active Businesses",
                  value: boxData?.item?.activeBusinesses||0,
                  icon: walletGreen,
                  percentageDifference: boxData?.item?.activePercent ,
                },
                {
                  title: "Business Team Users",
                  value: boxData?.item?.totalUsers||0,
                  icon: user,
                  percentageDifference: boxData?.item?.userPercent,
                },
              ].map(item => (
                <div key={item.title}>
                  <DataBox 
                    title={item.title}
                    icon={<img src={item.icon} alt={item.title} className='w-[20px]' />}
                    value={item.value}
                    className="!shadow-[0px_13.3657px_44.5523px_rgba(160,163,189,0.5)]"
                    slot={
                      !!item?.percentageDifference ?
                      <div className='flex items-center justify-between'>
                        <div className='flex items-center gap-x-0.5'>
                          <img src={item?.percentageDifference < 0 ? graphRed : graph} alt="icon" /> 
                          <span 
                            className={clsx(
                              "text-sm",
                              item?.percentageDifference < 0 ? 'text-[#FA582E]':"text-[#4BDE97]"
                            )}
                          >
                            {Math.abs(item?.percentageDifference||0)}
                          </span>
                        </div>
                        <p className='text-sm text-[#B9B9B9]'>{filterOptions.find(x => x.value===query)?.label || "Custom"}</p>
                      </div>
                      : null
                    }
                  />
                </div>
              ))
            }
          </div>
        </div>
        <div className='bg-white rounded-[10px] p-[15px] shadow-[0px_13.3657px_44.5523px_rgba(160,163,189,0.5)]'>
          <TopBusinesses query={query} startDate={startDate} endDate={endDate} height='h-[330px]' />
        </div>
      </div>
      <Table
          tableTop={
            <div className='mt-1.5'>
              <div className="flex items-center border-b pb-[20px]">
                  <h4 className='text-[24px] font-bold'>Business Info</h4>
                  <div className='ml-auto'>
                    <SearchField
                      placeholder="Search approvals"
                    />
                  </div>
              </div>
              <div className='-my-2.5'>
                <Tabs
                  tabName="tableFilter"
                  tabs={[
                    "all", "pending", "verified", "active", "inactive", "declined", "suspended"
                  ].map(item => ({
                    label: capitalizeFirstLetter(item),
                    key: (
                      item==="verified" ? "approved" 
                      : item==="all" ? "" 
                      : item
                    )
                  }))}
                  variant="filled"
                  containerClass="justify-between"
                />
              </div>
            </div>
          }
          loading={isTableLoading}
          backendPaginated={true}
          totalPages={tableData?.totalPages ?? 0}
          showDownload={true}
          onDownloadClick={()=>{
            downloadCSV()
          }}
          data={tableData?.results||[]}
          tableShadow={true}
          hideLines={true}
          theadClass="font-normal text-[#06152B] pl-[20px] py-3"
          tdataClass="py-3 pl-[20px] text-sm text-[#06152B]"
          rows={[
            {
                header: <span>
                  <input 
                    type="checkbox" 
                    checked={tableData?.results?.length===CSVItems?.length}
                    className='scale-150 mt-2' 
                    onChange={()=>{
                      if(tableData?.results?.length===CSVItems?.length){
                        setCSVItems([])
                      } else {
                        setCSVItems(tableData?.results)
                      }
                    }}
                  />
                </span>,
                view: (item) => (
                    <span>
                        <input 
                          type="checkbox" 
                          checked={CSVItems?.includes(item)}
                          className='scale-150 mt-2' 
                          onChange={()=>{
                            if(!CSVItems.includes(item)){
                              setCSVItems([...CSVItems, item])
                            } else {
                              const newArray = CSVItems.filter(x => x !== item)
                              setCSVItems(newArray)
                            }
                          }}
                        />
                    </span>
                ),
            },
              {
                  header:'ID',
                  view: (item) => (
                      <span>
                          #{item?._id.slice(-6)}
                      </span>
                  ),
              },
              {
                  header:'Business Name',
                  view: (item) => (
                    <div className='max-w-[200px]'>
                      <span>
                          {item?.businessName}
                      </span>
                    </div>
                  ),
              },
              {
                  header:'Email',
                  view: (item) => (
                      <span className="lowercase">
                          {item?.email}
                      </span>
                  ),
              },
              {
                  header:'Phone',
                  view: (item) => (
                      <span>
                          {item?.phoneNumber}
                      </span>
                  ),
              },
              {
                  header:'Date',
                  view: (item) => (
                      <span>
                        {dateFormatter(item?.createdAt, true)}
                      </span>
                  ),
              },
              {
                  header:'Status',
                  view: (item) => (
                      <span>
                          <Status
                              type={
                                  item?.isActive==="pending"?"pending"
                                  : item?.isActive==="approved"?"success"
                                  : "fail"
                              }
                              text={
                                item?.isActive === 'approved' ? 
                                'Verified' 
                                : 
                                (item?.isActive===false ? "Unverified" : item?.isActive)
                              }
                              hideDot={true}
                              addClass="text-sm px-4 py-1"
                          />
                      </span>
                  ),
              },
          ]}
      />
    </div>
  )
}

export default Businesses