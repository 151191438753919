import Button from "components/shared/Button";
import {Modal} from "react-bootstrap";
import { IoCheckmarkCircle } from "react-icons/io5";


function ResultModal ({
    show, 
    handleClose, 
    title, 
    text, 
}) {
    // type - warning | success

    return(
        <Modal show={show} size="md" onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered >
            <div className="p-[20px] text-center">
                <IoCheckmarkCircle className="w-12 h-12 text-green-500 mx-auto block" />
                <h4 className="text-xl font-semibold text-ee-black-3 capitalize py-2.5">{title}</h4>
                <p className="text-ee-black-4 pb-6">{text}</p>
                <Button
                    text="Okay"
                    onClick={()=>handleClose()}
                    className="text-white w-full bg-ee-primary font-semibold py-2 rounded-[6px] capitalize"
                />
            </div>
        </Modal>
    )
}

export default ResultModal;