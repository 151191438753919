import { APIKit, loadToken } from "services";

const timeout = 15000;


export const BankService = {
    getAllBankLogo: () => {
        return APIKit(timeout).get(`/digitalassets/bank/get-all`)
    },

    bankStats: (type) => {
        return APIKit(timeout).get(`eagleeyeauth/bank/stats?type=${type}`, {
            headers: {
                Authorization: `Bearer ${loadToken()}`
            }
        })
    },

    topBank: (type) => {
        return APIKit(timeout).get(`eagleeyeauth/bank/top?type=${type}`, {
            headers: {
                Authorization: `Bearer ${loadToken()}`
            }
        })
    },
}
