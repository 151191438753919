import { useEffect, useRef, useState } from "react";

import { ToastContainer, toast } from "react-toastify";

import 'react-intl-tel-input/dist/main.css';
import notvisible from "../../images/svg/auth/not-visible.svg";
import visible from "../../images/svg/auth/visible.svg";
import loader from "../../images/gif/white-loader.gif";
import { getAdminById, SignupAction } from "../../lib/auth";
import { useParams } from "react-router-dom";


function CreateAccount () {

    let {adminId} = useParams()

    const [data, setData] = useState('');
    const [formValid, setFormValid] = useState(false)
    const [form, setForm] = useState({
        name: "",
        email: data ? data.email : '',
        role: data? data.role : '',
        password: ""
    });
    const [passwordValid, setPasswordValid] = useState(false)
    const [confirmpasswordValid, setConfirmPasswordValid] = useState(false)
    const [showPassword, setShowPassword] = useState(false);
   

    useEffect(()=>{
        // setCountries(listCountries())
        if (
            form.name !== "" &&
            form.password !== "" &&
            passwordValid &&
            confirmpasswordValid
        ){
            setFormValid(true)
        } else {
            setFormValid(false)
        }
    }, [form.name, 
        form.password,
        passwordValid,
        confirmpasswordValid
    ])


          
        const ref = useRef(null);

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
        const passwordWarning = document.getElementById("password-warning");
        if(e.target.name==="password"){
            // const passwordWarning = document.getElementById("password-warning");
            var hasUpperCase = /[A-Z]/.test(e.target.value);
            var hasLowerCase = /[a-z]/.test(e.target.value);
            var hasNumbers = /\d/.test(e.target.value);
            var hasNonalphas = /\W/.test(e.target.value);
            if (hasUpperCase && hasLowerCase && hasNonalphas && hasNumbers) {
                console.log("true")
                passwordWarning.innerHTML = "";
                setPasswordValid(true)
            } else {
                console.log("false")
                setPasswordValid(false)
                passwordWarning.innerHTML = "<p>Password must be a minimum 8 characters and contain at least one uppercase letter, one lower case letter, one special character and a number</p>"
            }
            
            
        }
        if(e.target.name === 'confirm-password'){
           if(form.password === e.target.value ){
            setConfirmPasswordValid(true)
            passwordWarning.innerHTML = "";
           }else{
            setConfirmPasswordValid(false)
            passwordWarning.innerHTML = "<p>Password isn't a match</p>"
           }
        }
    }

    useEffect(() => {
        getAdminById(adminId)
        .then(response => response.data)
        .then((res) => {
            if(res.status === 'success'){
                setData(res.data)
            }else{
                console.log('Something went wrong')
            }
        })

    }, [adminId])

    const submitHandler = (e) => {
        e.preventDefault();

        console.log(form)
        const actionButton = document.getElementById("actionBtn");
        actionButton.innerHTML=`<img width="25px" src=${loader} />`;
        // let payload = JSON.stringify(form);

        let payload = {
            name: form.name,
            email: data.email,
            role: data.role,
            password: form.password
        }
        // const acceptedMou = document.getElementById('acceptedMou');
        const readTerms = document.getElementById('readTerms');

        if (readTerms.checked) {
            if(confirmpasswordValid){
                SignupAction(adminId, payload)
                    .then((response) => response.data)
                    .then((res) => {
                        console.log(res);
                        if (res.status === "success") {
                            console.log('works')
                            toast.success("Account created successfully!!!");
                            actionButton.innerHTML=`Open Account`;
                            window.location="/auth/account-success";
                            console.log('kilode')
                            } else {
                            toast.error("Something went wrong, please try again!!!")
                            actionButton.innerHTML=`Open Account`;
                        }
                    })
                    .catch((err) => {
                        console.log(err.response)
                        if(err.response){
                            toast.error(err.response.data.message);
                            actionButton.innerHTML=`Open Account`;
                        } else{
                            toast.error("Something went wrong!!! Please make sure you have a good internet connection.");
                            actionButton.innerHTML=`Open Account`;
                        }
                    });
        } else {
            actionButton.innerHTML=`Open Account`;
            toast.info("Password doesn't match")
        } 
    } else {
            actionButton.innerHTML=`Open Account`;
            toast.info("Please make sure that you have read our terms and conditions first!")
        }
    }
 

    return(
        <div className="auth_signup">
            <ToastContainer />
            <div>

                <h3> Welcome</h3>
                <p>Create your password </p>

                <form 
                onSubmit={submitHandler}
                 autoComplete="off">

                     <label> Name
                    <input type="text" placeholder="Your Name" name="name" onChange={handleChange} />
                    </label>
                    
                      <label> Email
                      <input type="email" disabled placeholder={data ? data?.email : ''} name="email"   />
                     </label>

                      <label> Role
                     <input type="text" disabled placeholder={data ? data?.role : ''} name="role"  />
                     </label>  

                     

                    <div className="auth_signup-password">
                        <label> Password
                            <input type={showPassword?"text":"password"} required placeholder="Password" name="password" onChange={handleChange}  />
                            <img src={showPassword?visible:notvisible} alt="visible" className={showPassword?"visible":"notvisible"} onClick={()=>setShowPassword(!showPassword)} />
                        </label>   
                    </div>
                    
                    <div className="auth_signup-password">
                        <label> Confirm Password
                            <input type={showPassword?"text":"password"} required placeholder="Password" name="confirm-password" onChange={handleChange}  />
                            <img src={showPassword?visible:notvisible} alt="visible" className={showPassword?"visible":"notvisible"} onClick={()=>setShowPassword(!showPassword)} />
                        </label>   
                    </div>
                    <div id="password-warning" style={{color:"red", fontSize:"0.8em"}}>
                        <p ></p>
                    </div>
                    <div style={{display:"flex", alignItems:"flex-start"}}>
                        <input type="checkbox" style={{width:"25px", margin:"-7px 7px 0 0"}} id="readTerms" />
                        <p ref={ref}>I have read and accept the <span style={{color:'#0046E6', cursor:'pointer'}}  //</p>onClick={() => setIsVisible(!isVisible)}  
                        > Terms and Conditions </span></p>
                    </div>
                    <button 
                        className= {formValid?"auth_signup-active":"auth_signup-submit"}
                        type="submit"
                        id="actionBtn"
                        disabled={!formValid}
                    >
                        Open Account
                    </button>
                </form>
            </div>

          
        </div>
    )
}

export default CreateAccount;