import React from "react";
import {Modal} from "react-bootstrap";


function RadarModal ({show, cancel, data, ngBanks }) {
    return(
        <Modal show={show} size="md" onHide={cancel} aria-labelledby="contained-modal-title-vcenter" centered >
            <div className="myModal_main-widget">
                <div className="myModal_main-linkBank">
                    <h5>Verification Result</h5>
                </div>
                <hr/>
                <div className="myModal_main-linkBank">
                    <h5>Bank Account Verification</h5>
                </div>
                <div className="myModal_main-basic">
                    <div style={{width:'60%'}}>
                        <p className="myModal_main-basic_text1">Account Name</p>
                        <p className="myModal_main-basic_text2">{data?.account_name}</p>
                    </div>
                    <div>
                        <p className="myModal_main-basic_text1">Account Number</p>
                        <p className="myModal_main-basic_text2">{data?.account_number}</p>
                    </div>
                </div>
                <hr/>
                <div className="myModal_main-basic">
                    <div style={{width:"100px", height:"100px"}}>
                        {/* <p className="myModal_main-basic_text1">Bank ID</p>
                        <p className="myModal_main-basic_text2">{data?.bank_code}</p> */}
                        <img style={{width:"100%", height:"100%"}} src={ngBanks.find(o => o.code === data?.bank_code)?.logo?.url } alt="icon" />
                        {/* {ngBanks.find(o => o.code === bank.bankCode)?.logo?.url } */}
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default RadarModal;