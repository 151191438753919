import {Container} from "react-bootstrap";
import {Routes, Route, Navigate, useLocation, Link} from "react-router-dom";
import { useState, useEffect } from "react";
import Team from "./Team";
import Profile from "./Profile";
import Password from "./Password";
import ApprovalLogs from "./ApprovalLogs";


function Settings () {
    const location = useLocation().pathname;
    const [path, setPath] = useState("team");
    
    useEffect(()=>{
        
        setPath(location);
    }, [path, location]);

    return (
        <div className="settings">
            <div className="settings_navMain">
                <Link to="/settings/profile" className="link-decor"><p className={path.includes("settings/profile")?"active":""} onClick={()=>setPath("profile")}>My Profile</p></Link>
                <Link to="/settings/password" className="link-decor"><p className={path.includes("password")?"active":""} onClick={()=>setPath("password")}>Password</p></Link>
                <Link to="/settings/team" className="link-decor"><p className={path.includes("team")?"active":""} onClick={()=>setPath("team")}>Team</p></Link>
                <Link to="/settings/approval-log" className="link-decor"><p className={path.includes("approval-log")?"active":""} onClick={()=>setPath("approval-log")}> Logs</p></Link>
                
               
                {/* <Link to="/settings/compliance" className="link-decor"><p className={path.includes("compliance")?"active":""} onClick={()=>setPath("compliance")}>Compliance</p></Link> */}
            </div>
            <Container>
                <Routes>
                   
                    <Route path="/" element={<Navigate to="/settings/profile" />}/>
                    <Route path="/profile" element={<Profile />} />
                    <Route path="/password" element={<Password />} />
                    <Route path="/team/*" element={<Team />} />
                    <Route path="/approval-log/*" element={<ApprovalLogs />} />
                    
                    
                    {/* <Route path="/compliance/*" element={<Compliance />} /> */}
                </Routes>
            </Container>
        </div>
    )
}

export default Settings;