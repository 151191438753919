import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { getAllRoles, inviteAdmin } from '../../lib/auth';
import Select from '../Form/Select';

const InviteModal = ({cancel}) => {
  const [data, setData] = useState([])
  const [form, setForm] = useState({
    email: "",
    role: "",
});

useEffect(() => {
  getAllRoles()
  .then(response => response.data)
  .then(res => {
      console.log(res)
      if(res.success === true){
          setData(res.data)
      }else{
          toast.error("Something went wrong!!!")
      }
  })
  .catch((err) => {
    console.log(err.response)
    console.log(err)
    if(err.response){
        toast.error(err.response.data.message);
    } else{
        toast.error("Something went wrong!!! Please make sure you have a good internet connection.");
    }
});
}, [])

let roles = [...new Set(data?.map(item => item?.name))]

console.log(roles)

const handleChange = (e) => {
  setForm({
      ...form,
      [e.target.name]: e.target.value
  })
}
  const handleSelectChange = (name, val) => {
    console.log(name,val)
    setForm({
        ...form,
        [name]: val
    })
}

const handleSubmit = (e) => {
  e.preventDefault();
  let payload = JSON.stringify({
      email: form.email,
      // this is because we want to display the full name for Lifegate on the front end but back end takes Life Gate as role
      role: form.role==="Lifegate Microfinance Bank"?"Life Gate":form.role, 
  });
  console.log(payload)

  inviteAdmin(payload)
  .then(response => (response.data))
  .then(res => {
    if(res.status === 'success'){
      toast.success('Invite sent successfully')
      setTimeout(() => {
        cancel()
        window.location.reload()
      }, 1500);
    }else{
      toast.error("Something went wrong")
    }
  })
  .catch((err) => {
    console.log(err)
    console.log(err.response)
    if(err.response){
        toast.error(err.response.data.message);
    } else{
        toast.error("Something went wrong!!! Please make sure you have a good internet connection.");
    }
});
}

  return (
    <div className="myModal">
    { <div className="myModal_blurBg" ></div>}
    {/* <div className= {"myModal_main"} > */}
    <div className= {"myModal_mainInvite"} >


    <div className="myModal_mainInvite-invite">
   
        <h4> Invite Team Member</h4>

        <hr />

        <div className="myModal_mainInvite-invite-top" >
          <div className="settings_profile-form-div">

            <label>Email Address </label>

            <div className="input" style={{width:"70%"}}>
                <input type="text" placeholder="Details" name='email' style={{width:"100%"}} onChange={handleChange}/><br/>
            </div>
          </div>

          <div className="settings_profile-form-div">
            <label>Select Role </label>
                <Select 
                        placeholder={""}
                        options={roles}
                        selectChange={(item)=>handleSelectChange("role", item)}
                    />
          </div>
        </div>

        <hr />
        <div className="myModal_mainInvite-invite-bottom">
            <button className="cancel" onClick={cancel}
            >Cancel</button>
            <button className="publish" onClick={handleSubmit}
            >Invite Team Member</button>
        </div>
     </div>
    </div>
  </div>
  )
}

export default InviteModal