import React from 'react'
import deleteIcon from '../../images/svg/deleteIcon.svg'


const RejectFloatModal = ({cancel, doAction, type}) => {

  
  return (
    <div className="myModal">
            {type !== "final-modal" && type !== "share-your-data" && <div className="myModal_blurBg" ></div>}
            <div className= {"myModal_main"} >
      <div className="myModal_main-delete">

          <img src={deleteIcon} alt="delete" />
          <h4>Reject Float Offer</h4>

          <p>Are you sure you want to reject this offer? This action cannot be undone.</p>

          <div className="myModal_main-delete-bottom">
              <button className="cancel" onClick={cancel}
              >Cancel</button>
              <button className="publish" onClick={doAction}
              >Reject</button>
          </div>
      </div>
    </div>
    </div>
  )
}

export default RejectFloatModal