import { Routes, Route, Navigate, useLocation, Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useIdleTimer } from 'react-idle-timer'
import { ToastContainer, toast } from "react-toastify";
import Overview from "pages/Dashboard/Overview";
import Settings from "components/Dashboard/Settings";
import Apis from "pages/Dashboard/Apis";
import logo from "images/svg/logo.svg";
import home from "images/svg/sidebar/home.svg";
import approvals from "images/svg/sidebar/approval.svg";
import wallets from "images/svg/sidebar/wallet.svg";
import bank from "images/svg/sidebar/bank.svg";
import users from "images/svg/sidebar/user.svg";
import userConsent from "images/svg/sidebar/userConsent.svg";
import thirdParty from "images/svg/sidebar/thirdParty.svg";
import salesOps from "images/svg/sidebar/salesOps.svg";
import apis from "images/svg/sidebar/api.svg";
import setting from "images/svg/sidebar/setting.svg";
import businesses from "images/svg/sidebar/business.svg";
import float from "images/svg/sidebar/float.svg";
import logout from "images/svg/sidebar/logout.svg";
import techSupport from "images/svg/sidebar/tech-support.svg";
import profile from "images/svg/profile-dummy.svg";
import verified from "images/svg/check-verified.svg";
import mail from "images/svg/sidebar/mail.svg";
import cancel from "images/svg/cancel.svg";
import menu from "images/svg/menu.svg";
import arrow from "images/svg/arrow.svg";
import Businesses from "pages/Dashboard/Businesses";
import Approvals from "pages/Dashboard/Approvals";
import ThirdPartyApps from "components/Dashboard/ThirdPartyApps";
import UserConsent from "components/Dashboard/UserConsent";
import Wallets from "pages/Dashboard/Wallets";
import Banks from "pages/Dashboard/Banks";
import Users from "components/Dashboard/Users";
import SalesOps from "pages/Dashboard/SalesOps";
import { getRolesByAdminName, logoutAction } from "lib/auth";
import Modal from "components/Modal";
import TechSupport from "components/Dashboard/TechSupport";
import Metrics from "pages/Dashboard/Metrics";
import Float from "pages/Dashboard/Float";
import WalletTopup from "pages/Dashboard/WalletTopUp";
import EmailBlast from "pages/Dashboard/EmailBlast";
import recova from "images/svg/sidebar/recova.svg";
import Recova from "./Recova";
import { handleRequestErrors } from "utils/helpers";
import { useDetectClickOutside } from "react-detect-click-outside";


function Dashboard() {
    const location = useLocation().pathname;
    const [path, setPath] = useState("");
    const [roles, setRoles] = useState([]);
    const [detailName, setDetailName] = useState("");
    const [doAutoLogout, setDoAutoLogout] = useState(true);
    const [userActive, setUserActive] = useState(true);
    const [showModal, setShowModal] = useState(false)
    const [showProfileModal, setShowProfileModal] = useState(false)
    let refreshToken = '';
    let adminName = ''
    let adminRole = ''
    const profileRef = useDetectClickOutside({
        onTriggered: () => setShowProfileModal(false)
    })

    try {
        let details = sessionStorage.getItem('eagleEye_details');
        refreshToken = JSON.parse(details).refreshToken;
        adminName = JSON.parse(sessionStorage.getItem('adminName'));
        adminRole = JSON.parse(details).adminRole;
    } catch (err) {
        //err
    }

    useEffect(() => {
        let payload = {
            name: adminRole
        }
        getRolesByAdminName(payload)
            .then(response => response.data)
            .then((res) => {
                if (res.success === true) {
                    setRoles(res.data?.[0]?.permission)
                } else {
                    toast.error('Something went wrong!!!')
                }
            })
            .catch((err) => {
                handleRequestErrors(err)
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setPath(location);
    }, [location])

    const openNav = () => {
        document.getElementById("sidebar").style.left = "0px";
    }

    const closeNav = () => {
        document.getElementById("sidebar").style.left = "-300px";
    }

    const changePathAndClose = (path) => {
        if (window.innerWidth <= 992) {
            closeNav()
        }
        setPath(path);
    }

    const logOut = () => {
        let payload = JSON.stringify({
            refreshToken: refreshToken
        })
        logoutAction(payload)
            .then((response) => response.data)
            .then(resp => {
                if (resp.status === "success") {

                    toast.success("Logging Out...");

                    sessionStorage.clear();
                    setTimeout(function () {
                        window.location = "/auth"
                    }, 1500);
                } else {
                    toast.error("Could not log user out please try again later!!!")
                }
            })
            .catch(err => {
                console.log(err)
                toast.error("Could not log user out please try again later!!!")
            })
    }


    const handleOnIdle = event => {
        console.log('user is idle', event)
        setUserActive(false)
        setDoAutoLogout(false)
        setShowModal(true)
        setTimeout(function () {
            setDoAutoLogout(true);
        }, 1000 * 30);
        console.log('last active', getLastActiveTime())
    }

    const handleOnActive = event => {
        console.log('user is active', event)
        setDoAutoLogout(false)
        setUserActive(true)
        console.log('time remaining', getRemainingTime())
    }

    const handleOnAction = event => {
        // console.log('user did something', event)
    }

    const { getRemainingTime, getLastActiveTime } = useIdleTimer({
        timeout: 1000 * 60 * 10,
        onIdle: handleOnIdle,
        onActive: handleOnActive,
        onAction: handleOnAction,
        debounce: 500
    })

    useEffect(() => {
        if (doAutoLogout && !userActive) {
            window.location = "/auth/login"
        }
    }, [doAutoLogout, userActive])

    var getInitials = function (string) {
        var names = string?.split(' '),
            initials = names?.[0] + " "

        if (names?.length > 1) {
            initials += names?.[names?.length - 1]?.substring(0, 1).toUpperCase() + ".";
        }
        return initials;
    };

    return (
        <div>
            <ToastContainer />
            <div className="dashboard_sidebar hide-scrollBar" id="sidebar" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <div >
                    <img src={cancel} alt="cancel" className="dashboard_sidebar-cancel" onClick={closeNav} />
                    <Link to="/"><img src={logo} alt="logo" className="dashboard_sidebar-logo" /></Link>

                    {
                        roles?.includes("view dashboard") &&
                        <Link className="link-decor" to="/overview">
                            <div
                                className={path.includes("overview") || path === "overview"
                                    ? "dashboard_sidebar-items active"
                                    : "dashboard_sidebar-items"}
                                onClick={() => changePathAndClose("overview")}
                            >
                                <img src={home} alt="icon" />
                                <p>Dashboard</p>
                            </div>
                        </Link>
                    }

                    {
                        roles?.includes("view dashboard") &&
                        <Link className="link-decor" to="/metrics">
                            <div
                                className={path.includes("metrics") || path === "metrics"
                                    ? "dashboard_sidebar-items active"
                                    : "dashboard_sidebar-items"}
                                onClick={() => changePathAndClose("metrics")}
                            >
                                <img src={salesOps} alt="icon" />
                                <p>Metrics</p>
                            </div>
                        </Link>
                    }

                    {
                        roles?.includes("view approvals") &&
                        <Link to="/approvals" className="link-decor">
                            <div
                                className={path.includes("approvals") || path === "approvals"
                                    ? "dashboard_sidebar-items active"
                                    : "dashboard_sidebar-items"}
                                onClick={() => changePathAndClose("approvals")}
                            >
                                <img src={approvals} alt="icon" />
                                <p>Approvals</p>
                            </div>
                        </Link>
                    }

                    {
                        roles?.includes("view businesses") &&
                        <Link className="link-decor" to="/businesses">
                            <div
                                className={path.includes("businesses") || path === "businesses"
                                    ? "dashboard_sidebar-items active"
                                    : "dashboard_sidebar-items"}
                                onClick={() => changePathAndClose("businesses")}
                            >
                                <img src={businesses} alt="icon" />
                                <p>Businesses</p>
                            </div>
                        </Link>
                    }

                    {
                        roles?.includes("view users") &&
                        <Link className="link-decor" to="/users">
                            <div
                                className={path.includes("users") || path === "users"
                                    ? "dashboard_sidebar-items active"
                                    : "dashboard_sidebar-items"}
                                onClick={() => changePathAndClose("users")}
                            >
                                <img src={users} alt="icon" />
                                <p>Users</p>
                            </div>
                        </Link>
                    }

                    {
                        roles?.includes("view apis") &&
                        <Link className="link-decor" to="/apis">
                            <div
                                className={path.includes("apis") || path === "apis"
                                    ? "dashboard_sidebar-items active"
                                    : "dashboard_sidebar-items"}
                                onClick={() => changePathAndClose("apis")}
                            >
                                <img src={apis} alt="icon" />
                                <p>APIs</p>
                            </div>
                        </Link>
                    }

                    {
                        roles?.includes("view wallet") &&
                        <Link className="link-decor" to="wallets">
                            <div
                                className={path.includes("wallets") || path === "wallets"
                                    ? "dashboard_sidebar-items active"
                                    : "dashboard_sidebar-items"}
                                onClick={() => changePathAndClose("wallets")}
                            >
                                <img src={wallets} alt="icon" />
                                <p>Wallets</p>
                            </div>
                        </Link>
                    }

                    {
                        roles?.includes("view banks") &&
                        <Link className="link-decor" to="/banks">
                            <div
                                className={path.includes("banks") || path === "banks"
                                    ? "dashboard_sidebar-items active"
                                    : "dashboard_sidebar-items"}
                                onClick={() => changePathAndClose("banks")}
                            >
                                <img src={bank} alt="icon" />
                                <p>Banks</p>
                            </div>
                        </Link>
                    }

                    {
                        true &&
                        <Link className="link-decor" to="/recova">
                            <div
                                className={path.includes("recova") || path === "recova"
                                    ? "dashboard_sidebar-items active"
                                    : "dashboard_sidebar-items"}
                                onClick={() => changePathAndClose("recova")}
                            >
                                <img src={recova} alt="icon" className="scale-[1.3] translate-x-1 -translate-y-1 w-[20px] mr-[20px]" />
                                <p>Recova</p>
                            </div>
                        </Link>
                    }

                    {
                        roles?.includes("view user-consent") &&
                        <Link className="link-decor" to="/user-consent">
                            <div
                                className={path.includes("user-consent") || path === "user-consent"
                                    ? "dashboard_sidebar-items active"
                                    : "dashboard_sidebar-items"}
                                onClick={() => changePathAndClose("user-consent")}
                            >
                                <img src={userConsent} alt="icon" />
                                <p>User Consent</p>
                            </div>
                        </Link>
                    }

                    {
                        roles?.includes("view float") &&
                        <Link className="link-decor" to="/float">
                            <div
                                className={path.includes("float") || path === "float"
                                    ? "dashboard_sidebar-items active"
                                    : "dashboard_sidebar-items"}
                                onClick={() => changePathAndClose("float")}
                            >
                                <img src={float} alt="icon" />
                                <p> Float</p>
                            </div>
                        </Link>
                    }

                    {
                        (roles?.includes("view third-party-apps") || roles?.includes("view lifegate")) &&
                        <Link className="link-decor" to="/third-party-apps">
                            <div
                                className={path.includes("third-party-apps") || path === "third-party-apps"
                                    ? "dashboard_sidebar-items active"
                                    : "dashboard_sidebar-items"}
                                onClick={() => changePathAndClose("third-party-apps")}
                            >
                                <img src={thirdParty} alt="icon" />
                                <p>3rd Party Apps</p>
                            </div>
                        </Link>
                    }

                    {
                        roles?.includes("view sales-ops") &&
                        <Link className="link-decor" to="/sales-ops">
                            <div
                                className={path.includes("sales-ops") || path === "sales-ops"
                                    ? "dashboard_sidebar-items active"
                                    : "dashboard_sidebar-items"}
                                onClick={() => changePathAndClose("sales-ops")}
                            >
                                <img src={salesOps} alt="icon" />
                                <p>Sales Ops</p>
                            </div>
                        </Link>
                    }

                    {
                        roles?.includes("view sales-ops") &&
                        <Link className="link-decor" to="/email-blast">
                            <div
                                className={path.includes("email-blast") || path === "email-blast"
                                    ? "dashboard_sidebar-items active"
                                    : "dashboard_sidebar-items"}
                                onClick={() => changePathAndClose("email-blast")}
                            >
                                <img src={mail} alt="icon" />
                                <p>Email Blast</p>
                            </div>
                        </Link>
                    }

                    {
                        roles?.includes("view tech-support") &&
                        <Link className="link-decor" to="/tech-support">
                            <div
                                className={path.includes("tech-support") || path === "tech-support"
                                    ? "dashboard_sidebar-items active"
                                    : "dashboard_sidebar-items"}
                                onClick={() => changePathAndClose("tech-support")}
                            >
                                <img src={techSupport} alt="icon" />
                                <p>Tech Support</p>
                            </div>
                        </Link>
                    }

                    {
                        roles?.includes("view wallet-topup") &&
                        <Link className="link-decor" to="/wallet-topup">
                            <div
                                className={path.includes("wallet-topup") || path === "wallet-topup"
                                    ? "dashboard_sidebar-items active"
                                    : "dashboard_sidebar-items"}
                                onClick={() => changePathAndClose("wallet-topup")}
                            >
                                <img src={wallets} alt="icon" />
                                <p>Wallet Top-up</p>
                            </div>
                        </Link>
                    }

                    {
                        roles?.includes("view settings") &&

                        <Link className="link-decor" to="/settings">
                            <div
                                className={path.includes("settings") || path === "settings"
                                    ? "dashboard_sidebar-items active"
                                    : "dashboard_sidebar-items"}
                                onClick={() => changePathAndClose("settings")}
                            >
                                <img src={setting} alt="icon" />
                                <p>Settings</p>
                            </div>
                        </Link>
                    }


                </div>
                <div className="dashboard_sidebar-items logout" onClick={logOut}>
                    <img src={logout} alt="icon" />
                    <p>Logout</p>
                </div>
            </div>
            <div className="relative ml-[260px] px-10 max-[992px]:ml-0 max-[769px]:px-[20px] max-[769px]:overflow-x-scroll">
                <div className="fixed z-[999] top-0 right-0 bg-white shadow-md w-[calc(100%-260px)]">
                    <div className="flex my-[20px] items-center justify-between px-10">
                        <img src={menu} alt="menu" className="w-[50px] absolute top-2.5 left-[15px] min-[992px]:hidden" onClick={() => openNav()} />
                        <h4 className="font-bold text-[22px] max-[992px]:hidden">
                            {
                                path.includes("businesses") ? "Businesses"
                                    : path.includes("metrics") ? "Metrics"
                                        : path.includes("approvals") ? "Business Activation"
                                            : path.includes("wallet") ? "Wallets"
                                                : path.includes("banks") ? "Banks"
                                                    : path.includes("third-party-apps") ? "Third Party Apps"
                                                        : path.includes("sales-ops") ? "Sales Ops"
                                                            : path.includes("users") ? "Users"
                                                                : path.includes("apis") ? "APIs"
                                                                    : path.includes("user-consent") ? "User Consent"
                                                                        : path.includes("settings") ? "Settings"
                                                                            : path.includes("tech-support") ? "Tech Support"
                                                                                : path.includes("float") ? "Business Float"
                                                                                    : path.includes("email-blast") ? "Business Announcements"
                                                                                        : path.includes("recova") ? ""
                                                                                            : "Overview"
                            }
                            {detailName === "" ? <span></span> :
                                <span className="text-[17px] mx-2.5 opacity-40">
                                    {" "}{">"} {detailName}
                                </span>
                            }
                        </h4>
                        <div className="flex relative cursor-pointer" ref={profileRef}>
                            <div
                                className="flex items-center gap-x-[10px]"
                                onClick={() => setShowProfileModal(!showProfileModal)}
                            >
                                <div className="w-10 h-10 rounded-full overflow-hidden">
                                    <img src={profile} alt="profile" className="w-full" />
                                </div>
                                <h6 className="font-bold text-base">{getInitials(adminName)}</h6>
                                <img
                                    src={arrow}
                                    alt="arrow"
                                    className="w-[10px]"
                                />
                            </div>
                            {
                                showProfileModal &&
                                <div className="bg-white absolute top-[45px] right-0 rounded px-[20px] py-[12px] shadow-[3px_4px_18px_rgba(0,0,0,0.1)]">
                                    <div className="flex gap-1.5">
                                        <div className="w-10 h-10 rounded-full overflow-hidden">
                                            <img src={profile} alt="profile" className="w-full" />
                                        </div>
                                        <div className="text-center">
                                            <h6 className="font-bold text-[18px] text-[#2C3E50] capitalize whitespace-nowrap">{adminName}</h6>
                                            <p className="text-xs">{adminRole}</p>
                                        </div>
                                    </div>
                                    <div className="flex justify-center mt-2.5">
                                        <div className="flex items-center w-fit gap-1.5 border-[1px] border-ee-primary rounded-full py-0.5 px-2">
                                            <img src={verified} alt="verified" className="w-[16px]" />
                                            <p className="text-[12px] text-[#1F1F1F]">Verified Profile</p>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="mt-[120px]">
                    <Routes>
                        <Route path="/" element={adminRole === 'Onboarding' ? <Navigate to="/approvals" /> : <Navigate to="/overview" />} />

                        {
                            roles?.includes("view dashboard") &&
                            <Route path="/overview/*" element={<Overview setDetailName={() => setDetailName("")} />} />
                        }

                        {
                            roles?.includes("view businesses") &&
                            <Route path="/businesses/*" element={<Businesses setDetailName={() => setDetailName("")} />} />
                        }

                        {
                            roles?.includes("view dashboard") &&
                            <Route path="/metrics/*" element={<Metrics setDetailName={() => setDetailName("")} />} />
                        }

                        {
                            roles?.includes("view approvals") &&
                            <Route path="/approvals/*" element={<Approvals setDetailName={() => setDetailName("")} />} />
                        }

                        {
                            roles?.includes("view wallet") &&
                            <Route path="/wallets/*" element={<Wallets setDetailName={() => setDetailName("")} />} />
                        }

                        {
                            roles?.includes("view banks") &&
                            <Route path="/banks/*" element={<Banks setDetailName={() => setDetailName("")} />} />
                        }

                        {
                            (roles?.includes("view third-party-apps") || roles?.includes("view lifegate")) &&
                            <Route path="/third-party-apps/*" element={<ThirdPartyApps roles={roles} setDetailName={() => setDetailName("")} />} />
                        }

                        {
                            roles?.includes("view sales-ops") &&
                            <Route path="/sales-ops/*" element={<SalesOps setDetailName={() => setDetailName("")} roles={roles} adminRole={adminRole} />} />
                        }

                        {
                            roles?.includes("view users") &&
                            <Route path="/users/*" element={<Users setDetailName={() => setDetailName("")} />} />
                        }

                        {
                            roles?.includes("view apis") &&
                            <Route path="/apis/*" element={<Apis setDetailName={() => setDetailName("")} />} />
                        }

                        {
                            roles?.includes("view user-consent") &&
                            <Route path="/user-consent/*" element={<UserConsent setDetailName={() => setDetailName("")} />} />
                        }

                        {
                            roles?.includes("view float") &&
                            <Route path="/float/*" element={<Float setDetailName={() => setDetailName("")} roles={roles} adminRole={adminRole} />} />
                        }

                        {
                            roles?.includes("view settings") &&
                            <Route path="/settings/*" element={<Settings setDetailName={() => setDetailName("")} />} />
                        }
                        {
                            roles?.includes("view tech-support") &&
                            <Route path="/tech-support/*" element={<TechSupport setDetailName={(name) => setDetailName(name)} />} />
                        }
                        {
                            true &&
                            <Route path="/wallet-topup" element={<WalletTopup setDetailName={() => setDetailName("")} />} />
                        }
                        {
                            roles?.includes("view sales-ops") &&
                            <Route path="/email-blast" element={<EmailBlast setDetailName={() => setDetailName("")} />} />
                        }
                        {
                            true &&
                            <Route path="/recova" element={<Recova setDetailName={() => setDetailName("")} />} />
                        }
                    </Routes>
                </div>
            </div>
            {showModal && <Modal type={"idle-user"} cancel={() => { setShowModal(false); setDoAutoLogout(false) }} />}
        </div>
    )
}

export default Dashboard;