import LineGraph from "./LineGraph";
import Filter from "components/shared/Filter";
import { currencyFormatter, dateFormatter } from "utils/helpers";
import Table from "components/Table";
import Status from "components/Status";
import viewmore_dropdown from "images/svg/recova/viewmore_dropdown.svg";
import { useState } from "react";
import clsx from "clsx";


export default function NPSSection () {
    const [dropDownView, setDropDownView] = useState(null)

    const data = [
        {
            name: 'Mon',
            count: 0,
            amt: 0,
        },
        {
            name: 'Tue',
            count: 0,
            amt: 0,
        },
        {
            name: 'Wed',
            count: 0,
            amt: 0,
        },
        {
            name: 'Thur',
            count: 0,
            amt: 0,
        },
        {
            name: 'Fri',
            count: 0,
            amt: 1,
        },
        {
            name: 'Sat',
            count: 0,
            amt: 0,
        },
        {
            name: 'Sun',
            count: 0,
            amt: 0,
        },
        {
            name: 'Mon',
            count: 0,
            amt: 0,
        },
        {
            name: 'Tue',
            count: 0,
            amt: 0,
        },
        {
            name: 'Wed',
            count: 0,
            amt: 0,
        },
        {
            name: 'Thur',
            count: 0,
            amt: 0,
        },
        {
            name: 'Fri',
            count: 0,
            amt: 1,
        },
        {
            name: 'Sat',
            count: 0,
            amt: 0,
        },
        {
            name: 'Sun',
            count: 0,
            amt: 0,
        },
    ];

    return (
        <div>
            <div>
                <h1 className="text-2xl font-bold">NPS Survey Metrics</h1>
                <div className="flex justify-end max-w-[924px] mx-auto my-10">
                    <Filter 
                        options={
                            ["Top 5", "Top 10", "Top 15"].map((item) => ({
                                label: item,
                                value: item
                            }))
                        }   
                    />
                </div>
                <div className="grid grid-cols-3 gap-x-10 gap-y-6 mb-8 mx-auto max-w-[924px]">
                    <div className="flex gap-x-2.5 items-center">
                        <div className="w-[50px] h-[60px] rounded-full flex items-center justify-center bg-[#2F49D1] bg-opacity-10">
                            <PersonIcon color="#0046E6" />
                        </div>
                        <div>
                            <p className="text-base font-bold">0</p>
                            <p className="text--ee-grey-1">Total NPS Responses</p>
                        </div>
                    </div>
                    <div className="flex gap-x-2.5 items-center">
                        <div className="w-[50px] h-[60px] rounded-full flex items-center justify-center bg-[#54BD95] bg-opacity-10">
                            <PersonIcon color="#54BD95"/>
                        </div>
                        <div>
                            <p className="text-base font-bold">0</p>
                            <p className="text--ee-grey-1">Total promoters</p>
                        </div>
                    </div>
                    <div className="flex gap-x-2.5 items-center">
                        <div className="w-[50px] h-[60px] rounded-full flex items-center justify-center bg-[#F8650C] bg-opacity-10">
                            <PersonIcon color="#F8650C"/>
                        </div>
                        <div>
                            <p className="text-base font-bold">0</p>
                            <p className="text--ee-grey-1">Total Passive</p>
                        </div>
                    </div>
                    <div className="flex gap-x-2.5 items-center">
                        <div className="w-[50px] h-[60px] rounded-full flex items-center justify-center bg-[#DE213C] bg-opacity-10">
                            <PersonIcon color="#DE213C" />
                        </div>
                        <div>
                            <p className="text-base font-bold">0</p>
                            <p className="text--ee-grey-1">Total Detractors</p>
                        </div>
                    </div>
                    <div className="flex gap-x-2.5 items-center">
                        <div className="w-[50px] h-[60px] rounded-full flex items-center justify-center bg-[#2F49D1] bg-opacity-10">
                            <svg width="20" height="16" viewBox="0 0 25 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M23.3255 9.30182C23.0072 9.30182 22.702 9.17014 22.477 8.93574C22.252 8.70135 22.1255 8.38344 22.1255 8.05195V4.81479L15.7779 11.4391C15.5983 11.6246 15.367 11.7463 15.117 11.7867C14.867 11.8271 14.6112 11.7842 14.386 11.6641L8.03842 8.36442L2.65076 15.1012C2.44709 15.3565 2.15441 15.517 1.83712 15.5474C1.51983 15.5779 1.20391 15.4759 0.958866 15.2637C0.713821 15.0515 0.559722 14.7467 0.530468 14.4162C0.501214 14.0857 0.599202 13.7566 0.802876 13.5014L6.8025 6.00216C6.97662 5.78531 7.21618 5.63635 7.48206 5.57959C7.74794 5.52284 8.02444 5.56166 8.26641 5.6897L14.686 9.02685L20.4336 3.05247H17.3258C17.0076 3.05247 16.7024 2.92079 16.4774 2.68639C16.2523 2.452 16.1259 2.13409 16.1259 1.8026C16.1259 1.47112 16.2523 1.15321 16.4774 0.918813C16.7024 0.684417 17.0076 0.552734 17.3258 0.552734H23.3255C23.6437 0.552734 23.9489 0.684417 24.1739 0.918813C24.399 1.15321 24.5254 1.47112 24.5254 1.8026V8.05195C24.5254 8.38344 24.399 8.70135 24.1739 8.93574C23.9489 9.17014 23.6437 9.30182 23.3255 9.30182Z" fill="#0046E6"/>
                            </svg>
                        </div>
                        <div>
                            <p className="text-base font-bold">0</p>
                            <p className="text--ee-grey-1">NPS Score</p>
                        </div>
                    </div>
                </div>
                <div className="flex justify-center">
                    <LineGraph data={data} cartesianGridType="dottedXonly"/>
                </div>
                <div className="my-10">
                    <Table
                        tableTitle={"NPS Survey Responses"}
                        hideSearch={false}
                        data={[]}
                        searchPlaceHolder="Search Business Name..."
                        slot={
                            <div>
                            <Filter 
                                options={
                                    ["Value"].map((item) => ({
                                        label: item,
                                        value: item
                                    }))
                                } 
                            />
                            </div>
                        }
                        theadClass="font-semibold text-[#667085] text-xs"
                        tdataClass="py-2.5 pl-[10px] text-sm"
                        rows={[
                            {
                                header:'Business Name',
                                view: (item) => (
                                    <span>
                                        PayFi
                                    </span>
                                ),
                            },
                            {
                                header:'Response ID',
                                view: (item) => (
                                    <span>
                                        PayFi
                                    </span>
                                ),
                            },
                            {
                                header:'Survey Source',
                                view: (item) => (
                                    <span>
                                        {currencyFormatter(3000)}
                                    </span>
                                ),
                            },
                            {
                                header:'NPS Choice',
                                view: (item) => (
                                    <span>
                                        {currencyFormatter(3000)}
                                    </span>
                                ),
                            },
                            {
                                header:'NPS Tag',
                                view: (item) => (
                                    <span>
                                        <Status
                                            type={
                                                item.status==="inprogress"?"pending"
                                                : item.status==="rejected"?"fail"
                                                : item.status==="pending"?"pending"
                                                : "success"
                                            }
                                            text={
                                                item.status==="inprogress"?"In Progress"
                                                : item.status==="rejected"?"Rejected"
                                                : item.status==="pending"?"Pending"
                                                : "Successful"
                                            }
                                        />
                                    </span>
                                ),
                            },
                            {
                                header:'Date of Survey',
                                view: (item) => (
                                    <span>{dateFormatter(new Date(), false)}</span>
                                ),
                            },
                            {
                                header: "",
                                view: (item) => (
                                    <div>
                                        <img 
                                            src={viewmore_dropdown} 
                                            alt="" 
                                            onClick={()=>{
                                                if(!!dropDownView){
                                                setDropDownView(null)
                                                } else {
                                                setDropDownView(item)
                                                }
                                            }}
                                            className={clsx(
                                                "w-8 cursor-pointer transition-all",
                                                item===dropDownView ? "rotate-180":"rotate-0",
                                            )}
                                        />
                                        {
                                            item===dropDownView &&
                                            <div className="absolute w-full left-0 z-10">
                                                <div className="shadow-md bg-white rounded mx-6 my-[10px] border-[1px] text-sm border-[#0349FD] p-[20px] grid grid-cols-4 gap-[20px]">
                                                    <div>
                                                        <p className="text-[#667085]">Title</p>
                                                        <p>Value</p>
                                                    </div>
                                                    <div>
                                                        <p className="text-[#667085]">Title</p>
                                                        <p>Value</p>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                )
                            },
                        ]}
                    />
                </div>
          </div>
        </div>
    )
}

const PersonIcon = ({color}) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.5199 0.35536C16.7384 0.00883283 17.211 -0.103528 17.5753 0.104384L17.1795 0.731818C17.5753 0.104384 17.5749 0.104189 17.5753 0.104384L17.5767 0.105204L17.5782 0.10613L17.582 0.108326L17.5919 0.114101C17.5995 0.11856 17.6089 0.124257 17.6202 0.131204C17.6428 0.145086 17.6726 0.163994 17.7087 0.188023C17.7808 0.236043 17.8782 0.304823 17.9924 0.395321C18.2202 0.575886 18.5181 0.845643 18.8148 1.21252C19.4126 1.95177 20 3.08045 20 4.63426C20 6.18807 19.4126 7.31675 18.8148 8.05599C18.5181 8.42287 18.2202 8.69261 17.9924 8.87319C17.8782 8.96373 17.7808 9.03251 17.7087 9.08051C17.6726 9.10451 17.6428 9.12344 17.6202 9.13729C17.6089 9.14422 17.5995 9.14997 17.5919 9.15446L17.582 9.16022L17.5782 9.16236L17.5767 9.16334C17.5763 9.16354 17.5753 9.16412 17.1795 8.5367L17.5753 9.16412C17.211 9.37202 16.7384 9.25963 16.5199 8.91319C16.3022 8.56812 16.4184 8.12096 16.7792 7.91191L16.7848 7.90845C16.7923 7.90386 16.8064 7.89494 16.8264 7.88162C16.8666 7.85494 16.9294 7.81092 17.0076 7.74898C17.1644 7.62467 17.3793 7.43101 17.5954 7.16374C18.0233 6.6347 18.4615 5.81216 18.4615 4.63426C18.4615 3.45635 18.0233 2.63382 17.5954 2.10477C17.3793 1.83751 17.1644 1.64385 17.0076 1.51955C16.9294 1.45759 16.8666 1.41357 16.8264 1.3869C16.8064 1.37358 16.7923 1.36467 16.7848 1.36006L16.7792 1.35662C16.4184 1.14756 16.3022 0.700423 16.5199 0.35536Z" fill={color}/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.94795 0C5.25734 0 3.07617 2.07478 3.07617 4.63415C3.07617 7.19351 5.25734 9.2683 7.94795 9.2683C10.6386 9.2683 12.8197 7.19351 12.8197 4.63415C12.8197 2.07478 10.6386 0 7.94795 0ZM4.61463 4.63415C4.61463 2.883 6.107 1.46341 7.94795 1.46341C9.78886 1.46341 11.2813 2.883 11.2813 4.63415C11.2813 6.3853 9.78886 7.80488 7.94795 7.80488C6.107 7.80488 4.61463 6.3853 4.61463 4.63415Z" fill={color}/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.94868 10.7324C5.85707 10.7324 3.92564 11.1916 2.4904 11.9718C1.07692 12.7401 0 13.913 0 15.3666C0 16.8201 1.07692 17.993 2.4904 18.7613C3.92564 19.5414 5.85707 20.0007 7.94868 20.0007C10.0403 20.0007 11.9717 19.5414 13.407 18.7613C14.8204 17.993 15.8974 16.8201 15.8974 15.3666C15.8974 13.913 14.8204 12.7401 13.407 11.9718C11.9717 11.1916 10.0403 10.7324 7.94868 10.7324ZM1.53845 15.3666C1.53845 14.6648 2.06872 13.8865 3.25369 13.2424C4.41689 12.6102 6.07519 12.1958 7.94868 12.1958C9.82221 12.1958 11.4805 12.6102 12.6436 13.2424C13.8287 13.8865 14.3589 14.6648 14.3589 15.3666C14.3589 16.0683 13.8287 16.8466 12.6436 17.4907C11.4805 18.123 9.82221 18.5373 7.94868 18.5373C6.07519 18.5373 4.41689 18.123 3.25369 17.4907C2.06872 16.8466 1.53845 16.0683 1.53845 15.3666Z" fill={color}/>
            <path d="M15.5245 2.05556C15.1602 1.84764 14.6877 1.96 14.4691 2.30653L14.7237 3.30506L14.7317 3.31025C14.7437 3.31825 14.7665 3.33407 14.7965 3.35791C14.8572 3.406 14.9439 3.4838 15.0318 3.59253C15.2033 3.8045 15.3851 4.13923 15.3851 4.63421C15.3851 5.12919 15.2033 5.46392 15.0318 5.67589C14.9439 5.78462 14.8572 5.86242 14.7965 5.91051C14.7665 5.93435 14.7437 5.95017 14.7317 5.95817L14.7237 5.96336C14.3667 6.17356 14.2524 6.61832 14.4691 6.96189C14.6877 7.30841 15.1602 7.42078 15.5245 7.21286L15.1287 6.58543C15.5245 7.21286 15.5241 7.21306 15.5245 7.21286L15.5258 7.21207L15.5274 7.21123L15.5306 7.20935L15.5382 7.20487L15.5579 7.19295C15.573 7.18369 15.5918 7.17174 15.6139 7.15705C15.6579 7.1277 15.7154 7.08713 15.7814 7.03474C15.913 6.93039 16.0828 6.77648 16.2513 6.56814C16.5926 6.14597 16.9236 5.50509 16.9236 4.63421C16.9236 3.76333 16.5926 3.12245 16.2513 2.70028C16.0828 2.49194 15.913 2.33803 15.7814 2.23368C15.7154 2.1813 15.6579 2.14072 15.6139 2.11137C15.5918 2.09668 15.573 2.08474 15.5579 2.07547L15.5382 2.06356L15.5306 2.05907L15.5274 2.0572L15.5258 2.05635C15.5255 2.05615 15.5245 2.05556 15.1287 2.68299L15.5245 2.05556Z" fill={color}/>
        </svg>
    )
}