import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import { editRole, getAllModules, getRolesById } from '../../../../../lib/auth';
import Back from '../../../../back'

const Permissions = () => {
  const [edit, setEdit] = useState(true);
  const [permissionData, setPermissionData] = useState([]);
  const [allModules, setAllModules] = useState([]);
  let navigate = useNavigate()
  let params = useParams()
  const myRef = useRef([]);


  // const permissionData = sessionStorage.getItem('permissionData')
  // const allModules = JSON.parse(sessionStorage.getItem('allModules'))

  const handleChange = (e, item) => {
    console.log(item)
    if(e.target.checked === true){
      if(!myRef?.current?.includes(item)){
        let a = myRef?.current
        a.push(item)
        myRef.current=a
        console.log(myRef.current)
      }
    }else{
      if(myRef?.current?.includes(item)){
        const index = myRef?.current?.indexOf(item);
       console.log(index)
       myRef?.current?.splice(index, 1)
        console.log(myRef.current)
      }
    }
  }

  const handleError = () => {
    toast.error('Click the "Edit" button to edit permissions')
  }

  const handleSubmit = () => {
    let payload = {
      permission: myRef.current
    }
console.log(payload)
    editRole(permissionData?._id, payload)
    .then(response => response.data)
    .then((res) => {
      console.log(res.data)
      if(res.success === true){
        toast.success('Permission updated successfully!!!')
        setInterval(() => {
          window.location.reload()
        }, 1500);
      }
    })
    .catch((err) => {
      console.log(err.response)
      console.log(err)
      if(err.response){
          toast.error(err.response.data.message);
      } else{
          toast.error("Something went wrong!!! Please make sure you have a good internet connection.");
      }
  });
  }

  useEffect(() => {
    getRolesById(params?.id)
    .then(resp => resp.data)
    .then(res=> {

        console.log(res.data?.[0])
        if(res.success === true){
            setPermissionData(res.data?.[0]);
            myRef.current = res?.data?.[0]?.permission
        }else{
            toast.error('Something went wrong!!!')
        } 
    })
    .catch((err) => {
        console.log(err.response)
        console.log(err)
        if(err.response){
            toast.error(err.response.data.message);
        } else{
            toast.error("Something went wrong!!! Please make sure you have a good internet connection.");
        }
    });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    getAllModules()
    .then(response => response.data)
    .then(res => {
        console.log(res)
        if(res.success === true){
          setAllModules(res.data)
            // sessionStorage.setItem( "allModules", JSON.stringify(res.data));
        }else{
            toast.error("Something went wrong!!!")
        }
    })
    .catch((err) => {
      console.log(err.response)
      console.log(err)
      if(err.response){
          toast.error(err.response.data.message);
      } else{
          toast.error("Something went wrong!!! Please make sure you have a good internet connection.");
      }
  });
  }, [])

  

  return (
    <div className="home_main">
        <div className="accounts">
          <Back to={()=> navigate("/settings/team/roles")} />
          <h4 style={{marginTop: '20px'}}> {permissionData?.name} Permissions  </h4>


          {

          edit ?
        
            <div className="accounts_permission">

              <p> <span onClick={()=>setEdit(false)}> Edit </span> </p> 
                 
              {
                allModules && allModules?.map((item, index)=> (              
                <div key={index}>
                  <label >
                    <input 
                    id='value'
                    type="checkbox" 
                    onChange={(e)=>handleError()} 
                    defaultChecked={
                      permissionData?.permission?.includes(item.name) ?
                      true : false
                    }
                    value={
                      permissionData?.permission?.includes(item.name) &&
                      item?.name 
                    }
                    checked={
                      permissionData?.permission?.includes(item.name) 
                    }
                    style={{marginRight:'10px'}}
                    />
                    {item.name}
                  </label>
                </div>   
                  ))
              }
            </div>
            :
            <div className="accounts_permission">
            
                  {
                    edit &&
                    <p> <span onClick={()=>setEdit(false)}> Edit </span> </p> 
                  }
                  
                  
              {
                 permissionData && allModules && allModules?.map((item, index)=> (              
                <div key={index}>
                      <label >
                        <input 
                        id='value'
                        type="checkbox" 
                        onChange={(e)=>handleChange(e, item.name)} 
                        defaultChecked={
                          permissionData?.permission?.includes(item.name) ?
                          true : false
                        }
                        value={
                          permissionData?.permission?.includes(item.name) &&
                          item?.name 
                        }
                        style={{marginRight:'10px'}}
                        />
                        {item.name}
                      </label>
                </div>
                          
                  ))
              }
              {
                !edit &&
                <button 
                  onClick={ handleSubmit}
                  >Update 
                </button>
              }
            </div>
          
        }   
        </div>
    </div>
  )
}

export default Permissions