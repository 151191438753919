import clsx from "clsx";
import file from "images/svg/approvals/file.svg";
import dwnld from "images/svg/approvals/download.svg";
import view from "images/svg/approvals/view.svg";

export default function FilePreview ({
    name, additionalName, hideDownload, downloadLink, hideView,
    icon=file,
    containerClass,
}) {

    return (
        <div 
            className={clsx(
                "flex items-center gap-1.5 border-[1px] border-[#D0D5DD] rounded p-2.5 mt-[15px]",
                containerClass,
            )}
        >
            <img src={icon} alt="" className="w-8" />
            <div className="overflow-hidden mr-2.5">
                <h6 className="text-sm font-medium">{name}</h6>
                <p className="capitalize text-xs">{additionalName}</p>
            </div>
            <div className="ml-auto flex gap-[10px]">
                <div 
                    className={clsx(
                        "rounded-lg bg-white border-[1px] border-[#D0D5DD] w-8 h-7 flex items-center justify-center cursor-pointer",
                        hideDownload && "hidden"
                    )}
                >
                    <a href={downloadLink} download target="_blank" rel="noreferrer">
                        <img src={dwnld} alt="" className="w-[16px]" />
                    </a>
                </div>
                <div 
                    className={clsx(
                        "rounded-lg bg-white border-[1px] border-[#D0D5DD] w-8 h-7 flex items-center justify-center cursor-pointer",
                        hideView && "hidden"
                    )}
                >
                    <a href={downloadLink} target="_blank" rel="noreferrer">
                        <img src={view} alt="" className="w-[16px]" />
                    </a>
                </div>
            </div>
        </div>
    )
}