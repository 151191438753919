import { APIKit, loadToken } from 'services';


export const MetricsService = {
    getMetrics: (args) => {
        return APIKit(10000).get(`eagleeyeauth/business/metrics`, {
            headers: {
                Authorization: `Bearer ${loadToken()}`
            },
            params: {
                ...args,
            }
        })
    },

    getNPSMetricsStat: (args) => {
        return APIKit(10000).get(`eagleeyeauth/metrics/nps-stats`, {
            headers: {
                Authorization: `Bearer ${loadToken()}`
            },
            params: {
                ...args,
            }
        })
    },

    getNPSMetricsGraph: () => {
        return APIKit(10000).get(`eagleeyeauth/metrics/nps-graph`, {
            headers: {
                Authorization: `Bearer ${loadToken()}`
            }
        })
    },

    getNPSMetricsSurvey: (args) => {
        return APIKit(10000).get(`eagleeyeauth/metrics/nps`, {
            headers: {
                Authorization: `Bearer ${loadToken()}`
            },
            params: {
                ...args,
            }
        })
    },
}