import { APIKit, loadToken } from "services";

const timeout = 15000;

let voltronBaseUrl = process.env.REACT_APP_ENVIRONMENT === "production" ?
    process.env.REACT_APP_VOLTRON_URL 
    : 
    process.env.REACT_APP_LOCAL;

export const RecovaService = {
    getRecovaActivity: (service) => {
        return APIKit(timeout).get(`eagleeye-services/recova/business-activity?service=${service}`, {
            headers: {
                Authorization: `Bearer ${loadToken()}`
            }
        })
    },

    getAllBusinesses: (args) => {
        return APIKit(timeout).get(`eagleeye-services/recova/recova-business`, {
            headers: {
                Authorization: `Bearer ${loadToken()}`
            },
            params: { ...args }
        })
    },

    getRecovaBillings: (args) => {
        return APIKit(timeout).get(`eagleeye-services/recova/billing-list`, {
            headers: {
                Authorization: `Bearer ${loadToken()}`
            },
            params: { ...args }
        })
    },

    getPayoutSelfServiceActivity: () => {
        return APIKit(timeout).get(`eagleeye-services/payout/self_service-activity`, {
            headers: {
                Authorization: `Bearer ${loadToken()}`
            }
        })
    },

    getAutoCollectionActivity: (args) => {
        return APIKit(timeout).get(`eagleeye-services/collection/auto-activity`, {
            headers: {
                Authorization: `Bearer ${loadToken()}`
            },
            params: {
                ...args
            }
        })
    },

    getManualCollectionActivity: (args) => {
        return APIKit(timeout).get(`eagleeye-services/collection/manual-activity`, {
            headers: {
                Authorization: `Bearer ${loadToken()}`
            },
            params: {
                ...args
            }
        })
    },

    approvePayout: (payload) => {
        return APIKit(timeout).post(`payouts/withdrawal-approved`, payload, {
            baseURL: voltronBaseUrl,
            headers: {
                token: `2ad109e692e279927fa7471a54a73f01CHEK`
            }
        })
    },

    rejectPayout: (payload) => {
        return APIKit(timeout).post(`payouts/withdrawal-rejected`, payload, {
            baseURL: voltronBaseUrl,
            headers: {
                token: `2ad109e692e279927fa7471a54a73f01CHEK`
            }
        })
    },

    getMandates: (page, search, status) => {
        return APIKit(timeout).get(`eagleeye-services/recova/business-mandates`, {
            headers: {
                Authorization: `Bearer ${loadToken()}`
            },
            params: {
                page,
                search,
                status,
            }
        })
    },

    getMandateBreakdown: (mandateId) => {
        return APIKit(timeout).get(`eagleeye-services/recova/recova/breakdown`, {
            headers: {
                Authorization: `Bearer ${loadToken()}`
            },
            params: {
                mandateId
            }
        })
    },

    getMandateMonoStatus: (mandateId) => {
        return APIKit(timeout).get(`eagleeye-services/recova/mono/status`, {
            headers: {
                Authorization: `Bearer ${loadToken()}`
            },
            params: {
                mandateId
            }
        })
    },
}
