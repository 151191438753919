import React, { useState } from 'react'
import BusinessesModal from "./components/BusinessesModal";
import dot from '../../../images/svg/dot.svg'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { sendEmails } from '../../../lib/auth';
import { toast } from 'react-toastify';

function EmailBlast () {
    const [data, setData] = useState({
        title: '',
        subject: '',
        contacts: [],
        body: ''
    })
    const [showModal, setShowModal] = useState(false);
    const [body, setBody] = useState('');
   
    const handleFormChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = () => {
        if(data?.title === "" || data.subject === "" || data.contacts?.length < 1 || body === ""){
            toast.error("please fill every field")
            return
        }

        const payload = {
            ...data,
            body: body
        }
        sendEmails(payload)
            .then(response => response.data)
            .then((res) => {
            if(res.status === 'success'){
                toast.success(res.message || "Email sent successfully");
            
                setTimeout(() => {
                    window.location.reload()
                }, 1500);
                
            }    else {
                toast.error("Something went wrong, please try again!!!")
            }
        })
        .catch((err) => {
            if(err.response){
                toast.error(err.response.data.message);
            } else{
                toast.error("Something went wrong!!! Please make sure you have a good internet connection.");
            }
        });
    }

    // onChange expects a function with these 4 arguments
    function handleChange(content, delta, source, editor) {
        setBody(content);
    }

    return(
        <div>
            <div className='home_wallet-topup-form' id="example" style={{width: "500px"}}>
                <div>
                    <label>Email Title</label><br/>
                    <div >
                        <input placeholder='Title' name="title" onChange={handleFormChange} />
                    </div>
                </div>
                <div>
                    <label>Email Subject</label><br/>
                    <div>
                        <input placeholder='Subject' name="subject" onChange={handleFormChange} />
                    </div>
                </div>
                <div>
                    <label>Select Business Recipients</label><br/>
                    <div onClick={()=>setShowModal(true)}>
                        <input placeholder={data?.contacts?.length > 0 ? `${data?.contacts?.length} Business Recipient(s) Selected` : 'Click To Select'} name="contacts" onChange={handleFormChange} />
                    </div>
                    <div style={{display: "flex", flexWrap: "wrap", marginBottom: "1rem"}}> 
                        {
                            data?.contacts?.map((contact, idx) => (
                                <div style={{display: "flex", marginRight: "5px", alignItems: "center",}} key={idx}>
                                    <img src={dot} alt="dot icon" />
                                    <p style={{fontWeight: 400, marginBottom:0, marginLeft: "2px", fontSize: "12px"}}> {contact?.businessName}</p>
                                </div>
                            ) )
                        }
                    </div>
                </div>
                <div>
                    <label>Email Body</label><br/>
                    <div>
                        <ReactQuill 
                            theme="snow" 
                            value={body} 
                            onChange={handleChange} 
                            // formats={"mention"}
                            // modules={modules}
                        />
                    </div>
                </div>
                <button className='home_wallet-topup-form-button' style={{marginTop: "1.5rem"}} onClick={handleSubmit}>Send Email(s)</button>
            </div>

            <BusinessesModal 
                show={showModal}
                handleClose={()=>setShowModal(false)} 
                setValues={setData} 
                values={data}
            />
        </div>
    )
}

export default EmailBlast