import { useState } from "react";
import {Modal} from "react-bootstrap";


function CreateService ({show, type, handleAction, cancel}) {
    const [data, setData] = useState("")
    return(
        <Modal show={show} size="md" onHide={cancel} aria-labelledby="contained-modal-title-vcenter" centered >
            <div className="myModal_main-widget">
                <h4>Create New Service</h4>
                <form>
                    <div className="myModal_main-linkBank">
                       <input 
                        name=""
                        type="text" 
                        placeholder="Enter Service Name" 
                        onChange={(e)=>setData(e.target.value)} 
                       />
                    </div>
                </form>
                <div className="myModal_main-widget-action">
                    <button className="cancel" onClick={cancel}>Cancel</button>
                    <button onClick={()=>handleAction(data)} className="publish">Create</button>
                </div>
            </div>
        </Modal>
    )
}

export default CreateService;