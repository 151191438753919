import settingAsh from '../../../../images/svg/settings/settingAsh.svg'
import invite from '../../../../images/svg/settings/invite.svg'
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { deleteAdmin, getAllAdmin } from '../../../../lib/auth';
import Pagination from "./sub-component/Pagination";
import { toast, ToastContainer } from 'react-toastify';
import InviteModal from '../../../Modal/InviteModal';
import deleteIcon from '../../../../images/svg/approvals/delete.svg'
import Modal from '../../../Modal';

function Main () {
  const [showModal, setShowModal] = useState(false)
  const [showProfileModal, setShowProfileModal] = useState(false)
  const [adminId, setAdminId] = useState('')
  const [arr, setArr] = useState([])
  const [page, setPage] = useState(0);
  const [data, setData] = useState([]);
  const [displayData, setDisplayData] = useState([]);


  useEffect(() => {
   getAllAdmin()
   .then(response => response.data)
   .then((res) => {

    if(res.status === 'success'){
        setData(res.data)
    }else {
        toast.error("Something went wrong, please try again!!!")
    }
})
.catch((err) => {
    console.log(err.response)
    console.log(err)
    if(err.response){
        toast.error(err.response.data.message);
    } else{
        toast.error("Something went wrong!!! Please make sure you have a good internet connection.");
    }
});

  }, [])

useEffect(() => {
    if(data?.length > 0){
        setArr(data)
        // setLoaded(true)
        setDisplayData(paginate(arr, 5)); // 10 per page
    }
    else {
        console.log('else')
        setDisplayData([]);
        // setArr([]);
    }
}, [arr, data])
  
  function paginate (arr, size) {
    return arr.reduce((acc, val, i) => {
      let idx = Math.floor(i / size)
      let page = acc[idx] || (acc[idx] = [])
      page.push(val)
  
      return acc
    }, [])
}

const handleDelete = (id) => {
    deleteAdmin(id)
    .then(response => response.data)
    .then((res) => {
      if(res.status === 'success'){
        toast.success(res.message)
        setTimeout(() => {
          window.location.reload();
          setShowProfileModal(false)
         }, 1500);
      }
      else {
        toast.error("Something went wrong, please try again!!!")
    }
})
.catch((err) => {
    if(err.response){
        toast.error(err.response.data.message);
    } else{
        toast.error("Something went wrong!!! Please make sure you have a good internet connection.");
    }
});
  }


function changePage (name, num) {
    if(name==="prev"){
        if (num<0){console.log("end")}
        else {setPage(num)}
    } else if (name==="next") {
        if (num === displayData.length){console.log("end")}
        else {setPage(num)}
    } else{
        if (parseInt(num)<=0 || isNaN(parseInt(num)) || parseInt(num) > displayData.length){console.log("end")}
        else {setPage(parseInt(num)-1)}
    }
    console.log(name, num)
}
console.log(displayData)

const handleDeleteChange = (id) => {
    setAdminId(id)
    console.log(id)
    setShowProfileModal(true)
  }



    return(
        <div className="apps_create">
            <h4>Team</h4>
            <ToastContainer />

            <div className="apps_create-header">
                <p> Team members</p>
                <div className="flex">

                    <Link to='roles'>
                        <div className='flex-roles'> 
                            <img src={settingAsh} alt="icon" />
                            <p > Manage roles </p>
                        </div>
                    </Link>
                    

                    <div className='flex-invite' onClick={()=>setShowModal(true) }> 
                        <img src={invite} alt="icon" />
                        <p > Invite Someone </p>
                    </div>
                </div>
            </div>

            <div className='table'>
{ 
            displayData && displayData.length > 0 ?
<div>
                <table>
                    <thead>
                        <tr>
                            <th> Name </th>
                            <th> Email Address </th>
                            <th> Role </th>
                            <th> Action </th>
                        </tr>
                    </thead>

                    <tbody>
                        {
                           displayData && displayData?.[page]?.map((item, idx) =>(
                                
                                <tr key={idx}>
                                    {console.log(item)}
                                    <td>{item?.name ? item?.name : "**Request sent**"}</td>
                                    <td>{item?.email}</td>
                                    <td>{item.role} </td>
                                    <td>
                                        <img onClick={()=>handleDeleteChange(item?.id)} src={deleteIcon} alt="delete" />
                                    </td>
                                </tr>
                            ))
                        }
                          
                    </tbody>
                </table>

                <div>
                <Pagination
                    changePage={(name, num)=>changePage(name, num)} 
                    page={page}
                    totalPages={displayData.length}
                />  
            </div>
            </div>
            :
            <h4 style={{textAlign:"center"}}>No data to show</h4>
}
            </div>
           { showModal && <InviteModal cancel={()=> setShowModal(false)} />  }
           {showProfileModal && <Modal type={"delete-admin"} cancel={()=>{setShowProfileModal(false);}} doAction={(id)=> handleDelete(adminId)} />}
            
        </div>
     
    )
}

export default Main;