import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { getUpdatedAt } from '../../../lib/auth';
import Box from '../../Box';
import Loader from '../../shared/Loader';

const Main = ({setDetailName}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [boxData, setBoxData] = useState({
        boxFor: "tech-support",
        item: []
      });

      useEffect(()=>{
        setDetailName("")
    },[setDetailName]);

      useEffect(() => {
        setIsLoading(true)
        getUpdatedAt()
        .then(response => response.data)
        .then((res) => {
            if(res.status === 'success'){
              setBoxData({
                  ...boxData,
                  item : res?.data
              });
              setIsLoading(false)
            } else {
              toast.error("Something went wrong, please try again!!!")
              setIsLoading(false)
          }
        } )
        .catch((err) => {
            if(err.response){
                toast.error(err.response.data.message);
                setIsLoading(false)
            } else{
                toast.error("Something went wrong!!!");
                setIsLoading(false)
            }
          });
          // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [])

      const doAction = (data) => {
        window.location=`/tech-support/${data}`
      }
      
  return (
    <div>
      <div>
      {
        isLoading ?
        <Loader /> :
        <Box 
          boxData={boxData}
          doAction={(data) => doAction(data)}
        />
      }
      </div>
    </div>
  )
}

export default Main