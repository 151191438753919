import axios from "axios";


export const OtherService = {
    getGeolocation: () => {
        return axios.get('https://geolocation-db.com/json/', {
            // withCredentials: true,  this causes CORS error
            headers: {
                'Accept': 'application/json',
            }
        })
    },
}
