import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import { getApprovalLogs } from "../../../../lib/auth";
import Table from "../../../Table/general.js";
import search from '../../../../images/svg/search.svg'
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/theme/default.css';
import 'react-date-range/dist/styles.css';
import SelectInput from "../../../Form/SelectInput";


function ApprovalLogs () {
    const [query, setQuery] = useState('all')
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [startDateee, setStartDateee] = useState("");
    const [endDateee, setEndDateee] = useState("");
    const [show, setShow] = useState(false);

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
]);

const handleClose = () => setShow(false);

function formatPickerDate(date) {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [year, month, day].join('-');
}

    const [data, setData] = useState({
        tableFor: "approval-logs",
        headings: ["Name", "Role", "Action", "Business Name", "Country", "IP Address", "When" ],
        items: []
    });

    useEffect(() => {
        getApprovalLogs(query)
        .then(response => response.data)
        .then(res => {
            if(res.success === true){
                setFilteredData(res.data)
            }else{
                toast.error('Something went wrong!!!')
            }
        })
        .catch((err) => {
            if(err.response){
                toast.error(err.response.data.message);
            } else{
                toast.error("Something went wrong!!!");
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query])

    useEffect(() => {
        const filtered = filteredData?.filter((item) => 
        item?.businessName?.toLowerCase()?.includes(searchTerm.toLowerCase()) || 
        item?.adminRole?.toLowerCase()?.includes(searchTerm.toLowerCase()) || 
        item?.action?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
        item?.adminName?.toLowerCase()?.includes(searchTerm.toLowerCase())
        )
        setData({
          ...data,
          items: filtered
        })
         // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [searchTerm, filteredData])

    const handleSelectChange = (name, val) => {
        if
        (val === 'All'){
          setQuery('all')
        }else if
        (val=== 'Last 7 days'){
          setQuery('last_seven_days')
        }else if(val === 'This Month'){
          setQuery('this_month')
        }else if(val === 'Last Month'){
          setQuery('last_month')
        }else if(val === 'Last 3 Months'){
          setQuery('last_three_months')
        }else if(val === 'Last 6 Months'){
            setQuery('last_six_months')
          }else if(val === 'Last 12 Months'){
            setQuery('last_twelve_months')
        }else if(val === 'Custom'){
          setQuery('custom')
          setShow(true)
        }
      }
      
      const handleChange = (e) => {
        setSearchTerm(e.target.value)
      }
    return(
        <div className='home_main'>
            <ToastContainer />

            <Modal show={show} onHide={handleClose}>
                <DateRange
                    editableDateInputs={true}
                    onChange={item => {
                            setStartDateee(formatPickerDate(item.selection.startDate))
                            setEndDateee(formatPickerDate(item.selection.endDate))
                            setState([item.selection])
                        }
                    }
                    moveRangeOnFirstSelection={false}
                    ranges={state}
                />
                <button 
                    style={{
                        color:"white",
                        padding:"7px 25px",
                        textAlign:"center",
                        border:"1px solid #0046E6",
                        background:"#0046E6"
                    }} 
                    onClick={()=>{   
                            handleClose();
                            setQuery(`custom&start=${startDateee}&end=${endDateee}`)
                        }}
                    >Proceed</button>
                </Modal>

                <div className='w-[150px] block ml-auto'>
                    <SelectInput 
                        placeholder={"All"}
                        options={["All", "Last 7 days", "This Month", "Last Month", "Last 3 Months", "Last 6 Months", "Last 12 Months", "Custom"]}
                        selectChange={(item)=>handleSelectChange("Query", item)}
                    />
                </div>

                <div className='home_main-tableHeader' >
                    <div className="table_top" style={{display:"flex", alignItems:"center"}}>
                        <div>
                            <h4> Logs</h4>
                        </div>
                        <div className="ml-auto rounded-[10px] flex w-[230px] h-10 p-[0.5em] items-center bg-white border-[1px] border-solid border-[#D0D5DD] box-border">
                            <input placeholder="Search logs" onChange={handleChange}  className='flex-1 bg-transparent border-0 outline-0 pl-[0.5em] font-normal leading-[16px] text-[#06152B] opacity-50 text-[12.64px]'
                            />
                            <img src={search} alt="search" />
                        </div>
                    </div>
                </div>

            <Table data={data} />
           
           
        </div>
    )
}


export default ApprovalLogs;
