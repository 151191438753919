import React, { useEffect, useState } from "react";
import Table from 'components/Table/index.js';
import { currencyFormatter, dateFormatter, handleRequestErrors } from 'utils/helpers.js';
import { useSearchParams } from 'react-router-dom';
import SearchField from 'components/shared/SearchField';
import { RecovaService } from "services/recova.service";
import Filter from "components/shared/Filter";
 

export default function BusinessSection () {
    const [isLoading, setIsLoading] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [searchParams] = useSearchParams();
    const search = searchParams.get("search")
    const page = searchParams.get("page") ?? 1

    useEffect(() => {
        setIsLoading(true)
        RecovaService.getAllBusinesses({
            page
        })
            .then(response => response.data)
            .then((res) => {
                setIsLoading(false)
                if(res.status === true){
                    setTableData(res?.data)
                    setIsLoading(false)
                }
            } )
            .catch((err) => {
                handleRequestErrors(err)
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, page])

    return (
        <div>
            <h1 className="text-2xl font-bold">Businesses</h1>
            <div>
                <div className="flex items-center justify-between">
                    <h1 className="text-[18px] font-bold">All Businesses</h1>
                    <div className="flex items-center gap-2">
                        <SearchField
                            placeholder="Search by business name"
                            containerClass='ml-auto my-6'
                        />
                        <Filter
                            placeHolder="Filter"
                            options={[]}
                            // containerClass="border-ee-primary text-base text-[#8A8A8A] flex-row-reverse"
                            // iconClassName="!w-[10px]"
                            // optionItemClass="text-sm text-xs font-semibold p-2 rounded-md m-0 hover:bg-ee-grey-3"
                        />
                    </div>

                </div>
                <Table
                    loading={isLoading}
                    data={tableData?.results||[]}
                    backendPaginated={true}
                    totalPages={tableData?.totalPages ?? 0}
                    theadClass="!font-bold py-2.5 text-[#667085] bg-[#CCDCFF80] text-sm"
                    tdataClass="py-[20px] pl-[10px] text-sm"
                    hideTableTop={true}
                    hideOverallTableBorder={true}
                    hideTableBodyBorder={true}
                    hideLines={true}
                    paginationContainer="!px-0"
                    showDownload={false}
                    rows={[
                        {
                            header:'ID',
                            view: (item) => (
                                <div className='!w-[150px] text-ellipsis overflow-hidden'>
                                    <span>
                                        #{item?.businessId?._id||"---"}
                                    </span>
                                </div>
                            ),
                        },
                        {
                            header:'Business Name',
                            view: (item) => (
                                <div className='flex items-center gap-x-1.5 max-w-[300px]'>
                                    <div
                                        className='w-8 h-8 shrink-0 bg-no-repeat bg-center bg-cover'
                                        style={{
                                            backgroundImage: !!item?.businessId?.logo ? `url(${item?.businessId?.logo})` : "url('/profile-placeholder.svg')"
                                        }}
                                    />
                                    <span>{item?.businessId?.businessName||"---"}</span>
                                </div>
                            ),
                        },
                        {
                            header:'Activation Date',
                            view: (item) => (
                                <span>{dateFormatter(item?.createdAt, true)}</span>
                            ),
                        },
                        {
                            header:'Total e-Mandates',
                            view: (item) => (
                                <div className="bg-[#0072F9] bg-opacity-20 text-[#3A36DB] w-fit py-1 px-2 rounded">
                                  {item?.mandateCount||0}
                                </div>
                            ),
                        },
                        {
                            header:'Total e-Mandate Amount',
                            view: (item) => (
                                <span>
                                    {currencyFormatter(item?.mandateAmount||0, "NGN", 0)}
                                </span>
                            ),
                        },
                    ]}
                />
            </div>
        </div>
    )
}