import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { ForgotPasswordAction } from "../../lib/auth";
import { Link } from "react-router-dom";

import loader from "../../images/gif/white-loader.gif";


function Login () {
    const [formValid, setFormValid] = useState(false)
    const [email, setEmail] = useState("");

    useEffect(()=>{
        if (email.length) {
            setFormValid(true)
        } else {
            setFormValid(false)
        }
    }, [email])

    const handleChange = (e) => {
        setEmail(e.target.value)
        if(email.length){
            setFormValid(true)
        }
    }

    const sendMail = (e) => {
        e.preventDefault();
        var btn = document.getElementById("actionBtn");
        btn.innerHTML=`<img src=${loader} width="25px" />`;
        
        let payload = JSON.stringify({
            email: email
        });

        ForgotPasswordAction(payload)
            .then((response) => response.data)
            .then((res) => {
                console.log(res);
                if (res.status === "success") {
                    toast.success(`Password reset instructions sent to your email!!!`);
                    btn.innerHTML=`Send Mail`;
                } else {
                    toast.error("Something went wrong, please try again!!!")
                    btn.innerHTML=`Send Mail`;
                }
            })
            .catch((err) => {
                if(err.response){
                    toast.error(err.response.data.message);
                    btn.innerHTML=`Send Mail`;
                } else{
                    toast.error("Something went wrong!!! Please make sure you have a good internet connection.");
                    btn.innerHTML=`Send Mail`;
                }
            });
    }

    return(
        <div className="auth_signup">
            <ToastContainer />

            <h3> Forgot Password</h3>
                <p>We will  send your reset instructions</p>
            <form  onSubmit={sendMail} 
            >
                 <label> Email
                <input type="email" required placeholder="Email Address" name="email" onChange={handleChange} />
                </label>
                <button 
                    className= {formValid?"auth_signup-active":"auth_signup-submit"}
                    id="actionBtn"
                    disabled={!formValid}
                >
                    Send Mail
                </button>
                <p className="auth_signup-text1"> Back to <Link to="/auth/login"><span>Login</span></Link></p>
            </form>
        </div>
    )
}

export default Login;