import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import Table from '../../../components/Table';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Filter from 'components/shared/Filter';
import selectArrow from "images/svg/select-arrow.svg";
import requestBlue from 'images/svg/box/requestBlue.svg'
import requestGreen from 'images/svg/box/requestGreen.svg'
import requestRed from 'images/svg/box/requestRed.svg'
import DataBox from 'components/shared/DataBox';
import SearchField from 'components/shared/SearchField';
import { OtherService } from 'services/other.services';
import { BusinessService } from 'services/business.service';
import { handleRequestErrors } from 'utils/helpers';
import Tabs from 'components/shared/Tabs';
import Button from 'components/shared/Button';
import clsx from 'clsx';
import dayjs from 'dayjs';
import see from "images/svg/see.svg";
import deleteIcon from 'images/svg/approvals/delete.svg'
import { mkConfig, generateCsv, download } from "export-to-csv";
import prodIcon from "images/svg/approvals/prod-icon.svg";
import devIcon from "images/svg/approvals/dev-icon.svg";
import { Tooltip } from "react-tooltip";
import { SpectrumService } from 'services/spectrum.service';
import WarningModal from 'components/Modal/WarningModal';


const Main = () => {
  let navigate = useNavigate();
  const [filteredData, setFilteredData] = useState([]);
  const [showWarning, setShowWarning] = useState(false)
  const [businessAction, setBusinessAction] = useState({
    id: "",
    status: "",
  })
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [ipDeets, setIpDeets] = useState({
    country_name: "Nigeria",
    IPv4: "102.89.45.161"
  });
  const [searchParams] = useSearchParams();
  const search = searchParams.get("search")||""
  const query = searchParams.get("filter") || "all"
  const startDate = searchParams.get("customStart")||""
  const endDate = searchParams.get("customEnd")||""
  const tabName = searchParams.get("approvalTab") || "request-pending"
  const isSpectrum = (tabName.split("-")?.[0]==="spectrum")
  const currentStatus = tabName.split("-")?.[1]
  const [boxData, setBoxData] = useState({
    boxFor: "approval",
    item: []
  });
  const [data, setData] = useState([]);
  const [warningLoading, setWarningLoading] = useState(false)

  useEffect( () => {
    OtherService.getGeolocation()
      .then(response => response.data)
      .then((res) => {
          setIpDeets(res)
      })
      .catch(()=>{})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(()=>{
    if(!isSpectrum){
      getRequestTableData(currentStatus)
    } else {
      getSpectrumTableData(currentStatus)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[tabName, query])

  const getRequestTableData = (status) => {
    setIsTableLoading(true)
    BusinessService.approvalRequests(
      status, 
      {
        type: query,
        start: startDate,
        end: endDate,
      }
    )
      .then((response) => response.data)
      .then((res) => {
        setIsTableLoading(false)
        if (res.status === 'success') {
            setFilteredData(res.data)
        }
      })
      .catch((err) => {
        setIsTableLoading(false)
        handleRequestErrors(err)
      });
  }

  const getSpectrumTableData = (status) => {
    setIsTableLoading(true)
    SpectrumService.getSpectrumRequests(status)
      .then((response) => response.data)
      .then((res) => {
        setIsTableLoading(false)
        setData(res.data)
      })
      .catch((err) => {
        setIsTableLoading(false)
        handleRequestErrors(err)
      });
  }

  useEffect(() => {
    const filtered = filteredData?.filter((item) => 
      item?.businessName?.toLowerCase()?.includes(search.toLowerCase()) || 
      item?.businessSector?.toLowerCase()?.includes(search.toLowerCase()) || 
      item?.email?.toLowerCase()?.includes(search.toLowerCase()) ||
      item?.phoneNumber?.toLowerCase()?.includes(search.toLowerCase())
    )
    setData(filtered)
    setIsTableLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, filteredData])

  useEffect(()=>{
    BusinessService.approvalStats({
      type: query,
      start: startDate,
      end: endDate,
    })
      .then((response) => response.data)
      .then((res) => {
        if (res.status === 'success') {
            setBoxData({
              ...boxData,
              item:res.data
            })
        }
      })
      .catch((err) => {
        handleRequestErrors(err)
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[query])

  const toggleStatus = (id, status) => {
    let payload = {
      businessId: id,
      status,
    }
    setWarningLoading(true)
    BusinessService.approvalAction(payload, ipDeets)
      .then(response => response.data)
      .then((res) => {
        setWarningLoading(false)
        if(res.status === 'success') {
          toast.success(res.message)
          getRequestTableData(currentStatus)
          setShowWarning(false)
        }
      })
      .catch((err) => {
        setWarningLoading(false)
        handleRequestErrors(err)
      });
  }

  const handleDelete = (id) => {
    setWarningLoading(true)
    BusinessService.deleteBusiness(id, ipDeets)
      .then(response => response.data)
      .then((res) => {
        setWarningLoading(false)
        if(res.status === 'success'){
          toast.success(res.message)
          getRequestTableData(currentStatus)
          setShowWarning(false)
        } else {
            toast.error("Unable to delete business, please contact support!!!")
        }
      })
      .catch((err) => {
        handleRequestErrors(err)
        setWarningLoading(false)
      });
  }

  const toggleSpectrumStatus = (id, status) => {
    let payload = {
      businessId: id,
      status,
    }
    setWarningLoading(true)
    SpectrumService.changeSpectrumStatus(payload)
      .then(response => response.data)
      .then((res) => {
        if(res.status === 'success'){
          toast.success(res.message)
          getSpectrumTableData(currentStatus)
          setShowWarning(false)
        }
        setWarningLoading(false)
      })
      .catch((err) => {
        handleRequestErrors(err)
        setWarningLoading(false)
      });
  }

  const filterOptions = [
    { label: "All", value: "all"}, 
    { label: "Last 7 days", value: "last_seven_days"}, 
    { label: "This Month", value: "this_month"}, 
    { label: "Last Month", value: "last_month"}, 
    { label: "Last 3 Months", value: "last_three_months"}, 
    { label: "Last 6 Months", value: "last_six_months"}, 
    { label: "Last 12 Months", value: "last_twelve_months"}, 
    { label: "Custom", value: "custom"},
  ]

  const csvConfig = mkConfig({ 
    useKeysAsHeaders: true,
    filename: `${currentStatus}-approvals`
  });

  return (
    <div className='mt-10 mb-[60px]'>
      <div className='flex justify-end mb-10'>
        <Filter
          placeHolder="All"
          options={
            filterOptions.map((item) => ({
              label: item.label,
              value: item.value
            }))
          } 
          containerClass="border-ee-primary text-base text-[#8A8A8A] flex-row-reverse"
          icon={selectArrow}
          iconClassName="!w-[10px]"
          optionItemClass="text-sm"
        />
      </div>
      <div className='grid grid-cols-4 gap-[20px] box-border'>
        {
          [
            {
              title: "Request Received",
              value: boxData?.item?.pending || 0,
              icon: requestBlue,
            },
            {
              title: "Business Approved",
              value: boxData?.item?.approved||0,
              icon: requestGreen,
            },
            {
              title: "Request Declined",
              value: boxData?.item?.declined||0,
              icon: requestRed,
            },
            {
              title: "Business Suspended",
              value: boxData?.item?.suspended||0,
              icon: requestRed,
            },
          ].map(item => (
            <div key={item.title}>
              <DataBox 
                title={item.title}
                icon={<img src={item.icon} alt={item.title} className='w-[20px]' />}
                value={item.value}
                className="!shadow-[0px_13.3657px_44.5523px_rgba(160,163,189,0.5)]"
              />
            </div>
          ))
        }
      </div>
      <div className='mt-10 flex items-center justify-between'>
        <h4 className='text-[20px] font-bold'>Approval Requests</h4>
        {
          !isSpectrum &&
          <SearchField
            placeholder="Search approvals"
          />
        }
      </div>
      <Tabs 
        tabName="approvalTab"
        tabs={[
          {
            label: "Pending",
            key: "request-pending"
          },
          {
            label: "Approved",
            key: "request-approved"
          },
          {
            label: "Declined",
            key: "request-declined"
          },
          {
            label: "Suspended",
            key: "request-suspended"
          },
          {
            label: "Spectrum Pending",
            key: "spectrum-pending"
          },
          {
            label: "Spectrum Approved",
            key: "spectrum-approved"
          },
          {
            label: "Spectrum Suspended",
            key: "spectrum-suspended"
          },
          {
            label: "Spectrum Declined",
            key: "spectrum-declined"
          },
        ]}
        variant="filled"
      />
      <div className='mt-[20px]'>
        <Table
          loading={isTableLoading}
          data={data||[]}
          theadClass="!font-bold py-2.5 text-[#667085] bg-[#F0F0F0] text-sm"
          tdataClass="py-6 pl-[10px] text-sm"
          trClassName="shadow-[-1px_4px_17px_-11px_rgba(0,0,0,0.25)]"
          tableClassName={clsx(!isSpectrum && "min-w-[1200px]")}
          hideTableTop={true}
          hideOverallTableBorder={true}
          hideTableBodyBorder={true}
          hideLines={true}
          paginationContainer="!px-0"
          showDownload={!isSpectrum}
          onDownloadClick={()=>{
            if(data?.length>0){
              const csv = generateCsv(csvConfig)(data);
              download(csvConfig)(csv)
            }
          }}
          rows={
            !isSpectrum ?
            approvalDataBlock(
              // view business
              (id)=>{
                navigate(`/approvals/${id}`)
              },
              // delete business
              (id)=>{
                setBusinessAction({
                  id: id,
                  status: "delete"
                })
                setShowWarning(true)
              },
              // handle business approval
              (status, id)=>{
                setBusinessAction({
                  id: id,
                  status: status
                })
                setShowWarning(true)
              },
            )
            :
            spectrumDataBlock(
              // view business spectrum details
              (id)=>{
                navigate(`/approvals/spectrum/${id}`)
              },
              // handle spectrum approval
              (status, id)=>{
                setBusinessAction({
                  id: id,
                  status: status
                })
                setShowWarning(true)
              },
            )
          }
        />
      </div>
      <WarningModal
        show={showWarning}
        isLoading={warningLoading}
        handleClose={()=>setShowWarning(false)}
        handleAction={()=>
          businessAction.action==="delete" ? handleDelete(businessAction?.id)
          : (
            isSpectrum ?
            toggleSpectrumStatus(businessAction?.id, businessAction?.status)
            :
            toggleStatus(businessAction?.id, businessAction?.status)
          )
        }
        title={`
          ${
            businessAction?.status==="approved" ? "Approve "
            : businessAction?.status==="suspended" ? "Suspend "
            : businessAction?.status==="declined" ? "Decline "
            : "Delete "
          }
          request
        `}
        text={`
          Are you sure you want to 
          ${
            businessAction?.status==="approved" ? `approve this business? This allows the business to have access ${isSpectrum?"to spectrum":""}.`
            : businessAction?.status==="suspended" ? `suspend this business? This restricts the business access ${isSpectrum?"to spectrum":""}.`
            : businessAction?.status==="declined" ? `decline this business? This restricts the business access ${isSpectrum?"to spectrum":""}.`
            : "Delete this request? This action cannot be undone."
          } 
        `}
        type={businessAction?.status==="approved" ? "success":"warning"}
      />
    </div>
  )
}

export default Main

const approvalDataBlock = (
  viewBusiness,
  handleDelete,
  handleApproval,
) => {
  return [
    {
        header:'Business Name',
        view: (item) => (
            <div className='max-w-[300px]'>
                <span>{item?.businessName||"---"}</span>
            </div>
        ),
    },
    {
        header:'Sector',
        view: (item) => (
            <span className='mr-6'>
                {item?.businessSector||"---"}
            </span>
        ),
    },
    {
        header:'Email',
        view: (item) => (
            <span className='lowercase'>
                {item?.email||"---"}
            </span>
        ),
    },
    {
        header: <span className='whitespace-nowrap'>Phone Number</span>,
        view: (item) => (
            <span className='mr-6'>
                {item?.phoneNumber||"---"}
            </span>
        ),
    },
    {
        header: <span className='whitespace-nowrap'>Compliance Status</span>,
        view: (item) => (
            <span 
              className={clsx(
                'font-semibold',
                item?.isComplied ? "text-[#47D800]":"text-[#FB2C2C]",
              )}
            >
                {item?.isComplied ? "True":"False"}
            </span>
        ),
    },
    {
        header:'Date',
        view: (item) => (
          <span className='whitespace-nowrap mr-6'>
              {dayjs(item?.updatedAt).format("DD/MM/YYYY hh:mm A")}
          </span>
        ),
    },
    {
        header:'Country',
        view: (item) => (
          <span>
              {item?.country||"---"}
          </span>
        ),
    },
    {
        header:'Action',
        view: (item) => (
          <div className='flex items-center gap-1.5'>
            <Button
                icon={<img src={see} alt="see" className='w-[20px]' />}
                iconPosition="left"
                onClick={()=>{
                  viewBusiness(item?._id)
                }}
                className="bg-[#0046E6] !rounded px-2.5 py-2.5 !text-sm shrink-0"
            />
            {
              item.isActive!=="approved" &&
              <Button
                text="Approve"
                onClick={()=>{
                  handleApproval("approved", item?._id)
                }}
                className="bg-[#47D800] !rounded px-2.5 py-2 !text-sm"
              />
            }
            {
              item.isActive==="approved" &&
              <Button
                text="Suspend"
                onClick={()=>{
                  handleApproval("suspended", item?._id)
                }}
                className="bg-[#FB2C2C] !rounded px-2.5 py-2 !text-sm"
              />
            }
            {
              item.isActive==="pending" &&
              <Button
                text="Decline"
                onClick={()=>{
                  handleApproval("declined", item?._id)
                }}
                className="bg-[#FB2C2C] !rounded px-2.5 py-2 !text-sm"
              />
            }
            {
              (item.isActive==="declined" || item.isActive==="suspended")  &&
              <img className='cursor-ponter' onClick={()=>handleDelete(item?._id)} src={deleteIcon} alt="delete" />
            }
          </div>
        ),
    },
]
}

const spectrumDataBlock = (
  viewSpectrumProfile,
  handleApproval,
) => {
  return [
    {
        header:'Business Name',
        view: (item) => (
            <div className='max-w-[300px]'>
                <span>{item?.businessId?.businessName||"---"}</span>
            </div>
        ),
    },
    {
        header:'Email',
        view: (item) => (
            <span className='lowercase'>
                {item?.businessId?.email||"---"}
            </span>
        ),
    },
    {
        header: <span className='whitespace-nowrap'>Phone Number</span>,
        view: (item) => (
            <span className='mr-6'>
                {item?.businessId?.phoneNumber||"---"}
            </span>
        ),
    },
    {
        header:'Date',
        view: (item) => (
          <span className='whitespace-nowrap mr-6'>
              {dayjs(item?.businessId?.updatedAt).format("DD/MM/YYYY hh:mm A")}
          </span>
        ),
    },
    {
        header:'Country',
        view: (item) => (
          <span>
              {item?.businessId?.country||"---"}
          </span>
        ),
    },
    {
        header:'Action',
        view: (item) => (
          <div className='flex items-center gap-1.5'>
            <Button
                icon={<img src={see} alt="see" className='w-[20px]' />}
                iconPosition="left"
                onClick={()=>{
                  viewSpectrumProfile(item?.businessId?._id)
                }}
                className="bg-[#0046E6] !rounded px-2.5 py-2.5 !text-sm shrink-0"
            />
            {
              (item?.businessId?.spectrumApproved!=="approved"&&item?.prodAccessRequest) &&
              <Button
                text="Approve"
                onClick={()=>{
                  handleApproval("approved", item?.businessId?._id)
                }}
                className="bg-[#47D800] !rounded px-2.5 py-2 !text-sm"
              />
            }
            {
              item?.businessId?.spectrumApproved==="approved" &&
              <Button
                text="Suspend"
                onClick={()=>{
                  handleApproval("suspended", item?.businessId?._id)
                }}
                className="bg-[#FB2C2C] !rounded px-2.5 py-2 !text-sm"
              />
            }
            {
              (item?.businessId?.spectrumApproved==="pending"&&item?.prodAccessRequest) &&
              <Button
                text="Decline"
                onClick={()=>{
                  handleApproval("declined", item?.businessId?._id)
                }}
                className="bg-[#FB2C2C] !rounded px-2.5 py-2 !text-sm"
              />
            }
            <img 
              data-tooltip-id="my-tooltip" 
              data-tooltip-content={
                item?.businessId?.spectrumApproved==="approved" ? 
                "This business has production access." 
                : "This business has only dev access." 
              }
              src={item?.businessId?.spectrumApproved==="approved" ? prodIcon:devIcon} 
              alt="env" 
              className='w-9'
            />
            <Tooltip id="my-tooltip" />
          </div>
        ),
    },
  ]
}