// import { useState, useEffect } from "react";
import { ToastContainer, //toast 
} from "react-toastify";
// import { Link } from "react-router-dom";
import success from "../../images/svg/auth/success.svg";



function AccountSuccess () {

    return(
        <div className="auth_signup" >
            <ToastContainer />
            <img src={success} alt="pattern" />

            <h3> Account Created Successful</h3>
            <p>Your Account & password has been succesfully created</p>
            <button 
                className= "auth_signup-active"
                type="submit"
                onClick={() => window.location= 'login'}
                >
                        Continue
                    </button>
          
        </div>
    )
}

export default AccountSuccess;