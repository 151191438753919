
import { useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import password from '../../../../images/svg/settings/password.svg'
import { changeAdminPassword } from '../../../../lib/auth';

function Password () {

    const [form, setForm] = useState({
        oldPassword:"",
        newPassword:"",
        confirmPassword:""
    })

    let adminId = '';
    try {
        let details = sessionStorage.getItem('eagleEye_details');
        adminId = JSON.parse(details).adminId;
    } catch (err) {
        //err
    }

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if(form.newPassword !== form.confirmPassword){
            toast.error("Password don't match")
        }else{
        let payload = JSON.stringify({
            oldPassword: form.oldPassword,
            newPassword: form.newPassword
        })
        console.log(payload)
        console.log(adminId)
        changeAdminPassword(adminId, payload)
            .then((response) => response.data)
            .then((res) => {
                console.log(res);
                if (res.status === 'success') {
                    toast.success(res.message);
                    setTimeout(() => {
                        window.location.reload()
                    }, 1500);
                   
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                if(err.response){
                    toast.error(err.response.data.message);
                } else{
                    toast.error("Something went wrong!!! Please make sure you have a good internet connection.")
                }
            });
        }
    }


    return(
        <div className="apps_create">
            <ToastContainer />
        <h4>Change Password</h4>
       
        <form className="settings_profile-form">

            <label>Old Password</label>
            <div className="settings_profile-form-div">
                <div className="input" style={{width:"100%"}}>
                            <img src={password} alt="icon" />
                            <input type="text" placeholder="Your Old Password" name='oldPassword' onChange={handleChange}  /><br/>
                        </div>

            </div>

            <label>New Password </label>
            <div className="settings_profile-form-div">
                <div className="input" style={{width:"100%"}}>
                    <img src={password} alt="icon" />
                    <input type="text" placeholder="Your New Password" name='newPassword' onChange={handleChange} /><br/>
                </div>

            </div>
           
            <div className="settings_profile-form-div">
                <div className="input" style={{width:"100%"}}>
                    <img src={password} alt="icon" />
                    <input type="text" placeholder="Enter new password again" name='confirmPassword' onChange={handleChange} /><br/>
                </div>

            </div>
           
           
            
         
            <button className="apps_create-button settings_profile-form-btn2" onClick={handleSubmit}>Save Changes</button>
        </form>

    </div>
    )
}

export default Password;