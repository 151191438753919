import React from 'react'


const DisbursedFloatModal = ({cancel, doAction, type}) => {

  
  return (
    <div className="myModal">
            {type !== "final-modal" && type !== "share-your-data" && <div className="myModal_blurBg" ></div>}
            <div className= {"myModal_main"} >
      <div className="myModal_main-delete">
          <h4>Disburse Offer Mail</h4>

          <p>Are you sure you've disbursed to this account as this will trigger an email to the user. This action cannot be undone.</p>

          <div className="myModal_main-delete-bottom">
              <button className="cancel" onClick={cancel}
              >Cancel</button>
              <button className="proceed" onClick={doAction}
              >Proceed</button>
          </div>
      </div>
    </div>
    </div>
  )
}

export default DisbursedFloatModal