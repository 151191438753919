import goback from "../images/svg/back.svg";

function back ({to}) {
    return(
        <div>
        <div onClick={()=>to()} style={{display:"flex", marginTop:"30px", cursor:"pointer"}}>
            <img src={goback} alt="back" />
            <p style={{fontSize:"1.1em",margin:"0 0 0 6px", fontWeight:"bold"}}>Back</p>
        </div>
        {/* <div style={{height:"40px"}}></div> */}
        </div>
    )
}

export default back;