import React from 'react'
import blueLoader from "../../images/gif/blue-loader.gif"

const Loader = ({
  height="30vh" // optional
}) => {
  return (
    <div style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: height,
      width: "100%"
      }}
    
    >
        <img src={blueLoader} alt="blue gif"  style={{height: "50px"}}/>
    </div>
  )
}

export default Loader