import clsx from "clsx";
import filter_lines from "images/svg/filter_lines.svg";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useDetectClickOutside } from "react-detect-click-outside";
import { Modal } from "react-bootstrap";
import { DateRange } from 'react-date-range';
import dayjs from "dayjs";
import 'react-date-range/dist/theme/default.css';
import 'react-date-range/dist/styles.css';


export default function Filter ({
    options,
    keyName="filter",
    icon=filter_lines,
    placeHolder="Filter",
    containerClass,
    iconClassName,
    optionItemClass,
    resetDate=true,
}) {
    const [searchParams, setSearchParams] = useSearchParams();
    const [showOptions, setShowOptions] = useState(false);
    const selectedLabel = options?.find(x => x?.value === searchParams.get(keyName))?.label;
    const optionRef = useDetectClickOutside({
      onTriggered: ()=>setShowOptions(false)
    })
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [show, setShow] = useState(false);
    const [state, setState] = useState([
        {
          startDate: new Date(),
          endDate: new Date(),
          key: 'selection'
        }
    ]);

    const changeFilter = (value) => {
        searchParams.set(keyName, value);
        if(resetDate){
            searchParams.set("page", 1);
        }
        setSearchParams(searchParams);
    }

    return (
        <div className="relative w-fit" ref={optionRef}>
            <div
                className={clsx(
                    "border-[1px] border-[#D0D5DD] py-1.5 px-2.5 cursor-pointer rounded flex items-center gap-x-2.5",
                    containerClass
                )}
                onClick={()=>setShowOptions(!showOptions)}
            >
                <img
                    src={icon}
                    alt=""
                    className={clsx(
                        "w-[20px]",
                        iconClassName
                    )}
                />
                <p className="whitespace-nowrap">{selectedLabel || placeHolder}</p>
            </div>
            {
                showOptions &&
                <div className="absolute right-0 p-2.5 shadow-md flex flex-col gap-1.5 bg-white z-10 rounded">
                    {
                        options.map((item, idx)=>(
                            <p
                                className={clsx(
                                    "whitespace-nowrap cursor-pointer",
                                    optionItemClass
                                )}
                                key={idx}
                                onClick={()=>{
                                    if(item.value==="custom"){
                                        setShow(true)
                                    } else {
                                        changeFilter(item.value)
                                    }
                                    setShowOptions(false)
                                }}
                            >{item.label}</p>
                        ))
                    }
                </div>
            }
            <Modal show={show} onHide={()=>setShow(false)}>
                <DateRange
                    editableDateInputs={true}
                    onChange={item => {
                            setStartDate(dayjs(item.selection.startDate).format("YYYY-MM-DD"))
                            setEndDate(dayjs(item.selection.endDate).format("YYYY-MM-DD"))
                            setState([item.selection])
                        }
                    }
                    moveRangeOnFirstSelection={false}
                    ranges={state}
                />
                <button
                    className="text-white py-[7px] px-6 text-center border-[1px] border-solid border-ee-primary bg-ee-primary"
                    onClick={()=>{
                        setShow(false)
                        searchParams.set(keyName, "custom");
                        searchParams.set("customStart", dayjs(startDate).format("YYYY-MM-DD"));
                        searchParams.set("customEnd", dayjs(endDate).format("YYYY-MM-DD"));
                        setSearchParams(searchParams);
                    }}
                >Proceed</button>
            </Modal>
        </div>
    )
}