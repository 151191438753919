import clsx from "clsx";


export default function Status ({
    text, type,
    hideDot=false,
    addClass,
}) {
    // Types::
    // success - green status
    // fail - red status
    // pending - yellow status
    // nuetral - blue status
    // unverified - pink status
    // verified - greenish blue status
    return (
        <button 
            type="button"
            className={clsx(
                "text-[0.9em] capitalize cursor-default font-semibold rounded-[25px] text-center py-0.5 px-2.5 flex items-center",
                type==="success" ? "text-ee-green-1 bg-ee-green-2"
                    : type==="pending" ? "bg-ee-yellow-2 text-ee-yellow-1"
                    : type==="fail" ? "text-ee-red-1 bg-ee-red-2"
                    : type==="verified" ? "text-[#03A89E] bg-[#dcfffd]"
                    : type==="unverified" ? "text-[#FF69B4] bg-[#ffebf4]"
                    : "text-ee-primary bg-ee-primary bg-opacity-10",
                addClass,
            )} 
        >
            <div className={clsx(
                    "rounded-full cursor-default w-[5px] mr-1 h-[5px] text-[2em]",
                    type==="success" ? "bg-ee-green-1"
                        : type==="pending" ? "bg-ee-yellow-1"
                        : type==="fail" ? "bg-ee-red-1"
                        : type==="verified" ? "bg-[#03A89E]"
                        : type==="unverified" ? "bg-[#FF69B4]"
                        : "bg-ee-primary", 
                    hideDot && "hidden",
                )} 
            ></div>
            {text}
        </button>
    )
}