import { APIKit, loadToken } from "services";

const timeout = 15000;


export const SpectrumService = {
    getSpectrumRequests: (status) => {
        return APIKit(timeout).get(`eagleeyeauth/spectrum/business/status/${status}`, {
            headers: {
                token: `${loadToken()}`
            }
        })
    },

    changeSpectrumStatus: (payload) => {
        return APIKit(timeout).put(`eagleeyeauth/spectrum/business/action`,payload, {
            headers: {
                token: `${loadToken()}`
            }
        })
    },

    getBusinessSpectrumData: (businessId) => {
        return APIKit(timeout).get(`eagleeyeauth/spectrum/business/get-business/${businessId}`, {
            headers: {
                token: `${loadToken()}`
            }
        })
    }
}
