import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { createRole, getAllModules } from '../../lib/auth';

const CustomRoles = ({cancel}) => {
  const [allModules, setAllModules] = useState([]);
  const [form, setForm] = useState({
    name: "",
    description: "",
});

let values = [];


const handleChange = (e) => {
  setForm({
      ...form,
      [e.target.name]: e.target.value
  })
}
console.log(form)
console.log(values)

  
  useEffect(() => {
    getAllModules()
    .then(response => response.data)
    .then(res => {
        console.log(res)
        if(res.success === true){
          setAllModules(res.data)
        }else{
            toast.error("Something went wrong!!!")
        }
    })
    .catch((err) => {
      if(err.response){
          toast.error(err.response.data.message);
      } else{
          toast.error("Something went wrong!!! Please make sure you have a good internet connection.");
      }
  });
  }, [])

  const handleChecked = (e, item) => {
    if(e.target.checked === true){
      if(!values.includes(item)){
        values.push(item)
      }
    }else{
      if(values.includes(item)){
        values.pop(item)
      }
    }
  }

  const handleSubmit = () => {
    let payload = {
      ...form,
      permission : values
    }

    createRole(payload)
    .then(response => response.data)
    .then((res) => {
      if(res.success === true){
        toast.success('Role created successfully!!!')
        setInterval(() => {
          window.location.reload()
        }, 1500);
      }else{
        toast.error('Something went wrong!!!')
      }
    })
    .catch((err) => {
      console.log(err)
      console.log(err.response)
      if(err.response){
          toast.error(err.response.data.message);
      } else{
          toast.error("Something went wrong!!! Please make sure you have a good internet connection.");
      }
  });
  }

  return (
    <div className="myModal">
    { <div className="myModal_blurBg" ></div>}
    <div className= {"myModal_custom"} >


      <div className="myModal_custom-invite">
    
          <h4 className='ml-4'> Create Custom Role </h4>

          <hr />

          <div className="myModal_custom-invite-top" >
            <div className="myModal_custom-invite-top_profile-form-div">

              <label>Role name </label>

              <div className="input" style={{width:"60%"}}>
                  <input type="text" placeholder="Enter role name" name='name' style={{width:"100%"}} onChange={handleChange}
                  /><br/>
              </div>
            </div>
            <div className="myModal_custom-invite-top_profile-form-div">

              <label>Description </label>

              <div className="input" style={{width:"60%"}}>
                  <input type="text" placeholder="Enter role description" name='description' style={{width:"100%"}} onChange={handleChange}
                  /><br/>
              </div>
            </div>

            
          </div>

          <hr />


          {/* <div className="myModal_custom-invite-bottom"> */}
          <div className="myModal_custom_permission">

            <p style={{color:'#0046E6', fontWeight:'700'}}>Select permissions</p>
          {
                 allModules && allModules?.map((item, index)=> (              
                <div key={index}>
                      <label >
                        <input 
                        id='value'
                        type="checkbox" 
                        onChange={(e)=>handleChecked(e, item.name)} 
                        style={{marginRight:'10px'}}
                        />
                        {item.name}
                      </label>
                </div>
                          
                  ))
              }
              
              <div className="myModal_custom-invite-bottom">
                  <button className="cancel" onClick={cancel}
                  >Cancel</button>
                  <button className="publish" onClick={handleSubmit}
                  >Create Role</button>
              </div>
          </div>
      </div>
    </div>
  </div>
  )
}

export default CustomRoles