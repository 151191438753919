import { APIKit, loadToken } from "services";

const timeout = 15000;


export const FloatService = {
    repaymentHistoryByBusinessId: (id) => {
        return APIKit(timeout).get(`eagleeyeauth/float/repayment-history/${id}`, {
            headers: {
                Authorization: `Bearer ${loadToken()}`
            }
        })
    },

    repaymentStatsByBusinessId: (id) => {
        return APIKit(timeout).get(`eagleeyeauth/float/repayment-stat/${id}`, {
            headers: {
                Authorization: `Bearer ${loadToken()}`
            }
        })
    },
}
