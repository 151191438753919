import React, { useEffect } from 'react';
import {Routes, Route} from "react-router-dom";
import Details from './Details';
import Main from './Main';
import Spectrum from './Spectrum';


function Approvals ({setDetailName}) {

    useEffect(() => {
        setDetailName("");
    }, [setDetailName]);

    return (
        <Routes>
            <Route path="/" element={<Main />}/>
            <Route path="/:id" element={<Details />} />
            <Route path="/spectrum/:id" element={<Spectrum />} />
        </Routes>
    )
}



export default Approvals