import { Navigate, Outlet } from "react-router-dom";

const useAuth = () => {
    const admin = sessionStorage.getItem('eagleEye_details');
    return admin;
    // return true;
};

const ProtectedRoutes = () => {
    const isAuth = useAuth();
    return isAuth ? (
    <Outlet /> ):
     <Navigate to="/auth" />;
};

export default ProtectedRoutes;