import Table from "components/Table";
import SearchField from "components/shared/SearchField";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { SalesOpsService } from "services/salesops.service";
import { handleRequestErrors } from "utils/helpers";
import { mkConfig, generateCsv, download } from "export-to-csv";
import { toast } from "react-toastify";


export default function Borrowers ({businessName, query}) {
    const [searchParams] = useSearchParams();
    const search = searchParams.get("search")
    const [data, setData] = useState(null)
    const [isLoading, setIsLoading] = useState(false);
    const page = searchParams.get("page") ?? 1
    let params = useParams()

    useEffect(()=>{
        getTableData(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[search, page, query])

    const csvConfig = mkConfig({ 
        useKeysAsHeaders: false,
        columnHeaders: [
            { displayLabel: "Full Name", key: "fullname" },
            { displayLabel: "BVN", key: "bvn" },
            { displayLabel: "Email", key: "email" },
            { displayLabel: "Phone Number", key: "phone" },
            { displayLabel: "Date", key: "createdAt" },
            { displayLabel: "Gender", key: "gender" },
            { displayLabel: "Marital Status", key: "maritalStatus" },
        ],
        filename: `${businessName}_borrowers`
    });

    const getTableData = (isCSV) => {
        setIsLoading(true)
        SalesOpsService.getBusinessBorrowers(params?.id, {
            page,
            search,
            csv: isCSV,
            type: query,
        })
            .then(response => response.data)
            .then(res => {
                setIsLoading(false)
                if(res.status === 'success'){
                    if(isCSV){
                        if(res.data==="processing..."){
                            toast.success("CSV data is processing. Results will be sent to your email.")
                        }else{
                            const csv = generateCsv(csvConfig)(res?.data);
                            download(csvConfig)(csv)
                        }
                    } else {
                        setData(res?.data)
                    }
                }
            })
            .catch((err) => {
                setIsLoading(false)
                handleRequestErrors(err)
            });
    }

    return (
        <div>
            <SearchField 
                containerClass='ml-auto my-6'
                placeholder="Search by full name"
            />
            <Table
                loading={isLoading}
                data={data?.results||[]}
                backendPaginated={true}
                totalPages={data?.totalPages ?? 0}
                theadClass="!font-bold py-2.5 text-[#667085] bg-[#F0F0F0] text-sm"
                tdataClass="py-6 pl-[10px] text-sm"
                trClassName="shadow-[-1px_4px_17px_-11px_rgba(0,0,0,0.25)]"
                hideTableTop={true}
                hideOverallTableBorder={true}
                hideTableBodyBorder={true}
                hideLines={true}
                paginationContainer="!px-0"
                showDownload={true}
                onDownloadClick={()=>{
                    if(data?.results?.length>0){
                        getTableData(true)
                    }
                }}
                rows={[
                    {
                        header:'Full Name',
                        view: (item) => (
                            <span>
                                {item?.fullname||"---"}
                            </span>
                        ),
                    },
                    {
                        header:'BVN',
                        view: (item) => (
                            <span>
                                {item?.bvn||"---"}
                            </span>
                        ),
                    },
                    {
                        header:'Email',
                        view: (item) => (
                            <span className="lowercase">
                                {item?.email || item?.emails?.[0] || "---"}
                            </span>
                        ),
                    },
                    {
                        header:'Phone Number',
                        view: (item) => (
                            <span>
                                {item?.phone|| item?.phones?.[0] || "---"}
                            </span>
                        ),
                    },
                    {
                        header:'Date',
                        view: (item) => (
                            <span>
                                {dayjs(item?.createdAt).format("DD/MM/YYYY hh:mm A")}
                            </span>
                        ),
                    },
                    {
                        header:'Gender',
                        view: (item) => (
                            <span>
                                {item?.gender||"---"}
                            </span>
                        ),
                    },
                    {
                        header:'Marital Status',
                        view: (item) => (
                            <span>
                                {item?.maritalStatus||"---"}
                            </span>
                        ),
                    },
                ]}
            />
        </div>
    )
} 