import React, { useEffect, useState } from 'react'
import { Modal } from "react-bootstrap";
import { floatRequests, floatStats } from '../../../lib/auth';
import { toast } from 'react-toastify';
import Box from '../../../components/Box'
import Table from '../../../components/Table';
import search from '../../../images/svg/search.svg'
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/theme/default.css';
import 'react-date-range/dist/styles.css';
import SelectInput from '../../../components/Form/SelectInput';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Loader from '../../../components/shared/Loader';
// import SearchField from 'components/shared/SearchField';
import Status from 'components/Status';
import Button from 'components/shared/Button';
import { currencyFormatter } from 'utils/helpers';
import dayjs from 'dayjs';
import Tabs from 'components/shared/Tabs';

const Main = ({setHeader}) => {
  let navigate = useNavigate(); 
  const [query, setQuery] = useState('all')
  const [queryVal, setQueryVal] = useState('all')
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [startDateee, setStartDateee] = useState("");
  const [endDateee, setEndDateee] = useState("");
  const [show, setShow] = useState(false);
  const [isBoxLoading, setIsBoxLoading] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const path = searchParams.get("tab")
  const amountType = "naira"
    

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ]);

  useEffect(()=>{
    setHeader()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

const handleClose = () => setShow(false);

function formatPickerDate(date) {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [year, month, day].join('-');
}

    const [boxData, setBoxData] = useState({
      boxFor: "float",
      item: []
    });
    const [data, setData] = useState({
      tableFor: "float-naira",
      headings: ["Business Name", "Requested Amount", "Approved Amount", "Requested Tenure", "Approved Tenure", "Email", "Time", "Status", "Action"],
      items: []
  });

useEffect(()=>{
  setIsTableLoading(true)
    floatRequests(path, query, amountType)
        .then((response) => response.data)
        .then((res) => {
            if (res.status === 'success') {
                setFilteredData(res.data)
                setIsTableLoading(false)
            } else {
                toast.error("Something went wrong, please try again!!!")
                setIsTableLoading(false)
            }
        })
        .catch((err) => {
            if(err.response){
                toast.error(err.response.data.message);
                setIsTableLoading(false)
            } else{
                toast.error("Something went wrong!!! Please make sure you have a good internet connection.");
                setIsTableLoading(false)
            }
        });
// eslint-disable-next-line react-hooks/exhaustive-deps
},[path, query])

useEffect(() => {
  const filtered = filteredData?.filter((item) => 
  item?.businessId?.businessName?.toLowerCase()?.includes(searchTerm.toLowerCase()) || 
  item?.businessId?.email?.toLowerCase()?.includes(searchTerm.toLowerCase()) 
  )
  setData({
    ...data,
    items: filtered
  })
   // eslint-disable-next-line react-hooks/exhaustive-deps
}, [searchTerm, filteredData])

const handleSelectChange = (name, val) => {
  if
  (val === 'All'){
    setQuery('all')
  }else if
  (val=== 'Last 7 days'){
    setQuery('last_seven_days')
    setQueryVal('Last 7 days')
  }else if(val === 'This Month'){
    setQuery('this_month')
    setQueryVal('This Month')
  }else if(val === 'Last Month'){
    setQuery('last_month')
    setQueryVal('Last Month')
  }else if(val === 'Last 3 Months'){
    setQuery('last_three_months')
    setQueryVal('Last 3 Months')
  }else if(val === 'Last 6 Months'){
    setQuery('last_six_months')
    setQueryVal('Last 6 Months')
  }else if(val === 'Last 12 Months'){
    setQuery('last_twelve_months')
    setQueryVal('Last 12 Months')
  }else if(val === 'Custom'){
    setQuery('custom')
    setQueryVal('Custom')
    setShow(true)
  }
}

useEffect(()=>{
  setIsBoxLoading(true)
  floatStats(query, amountType)
    .then((response) => response.data)
    .then((res) => {
        if (res.status === 'success') {
            setBoxData({
              ...boxData,
              item:res.data
            })
            setIsBoxLoading(false)
        } else {
            toast.error("Something went wrong, please try again!!!")
            setIsBoxLoading(false)
        }
    })
    .catch((err) => {
        if(err.response){
            toast.error(err.response.data.message);
            setIsBoxLoading(false)
        } else{
            toast.error("Something went wrong!!! Please make sure you have a good internet connection.");
            setIsBoxLoading(false)
        }
    });
// eslint-disable-next-line react-hooks/exhaustive-deps
},[query])


    const handleChange = (e) => {
      setSearchTerm(e.target.value)
    }

    return (
      <div>
        <Modal show={show} onHide={handleClose}>
          <DateRange
              editableDateInputs={true}
              onChange={item => {
                      setStartDateee(formatPickerDate(item.selection.startDate))
                      setEndDateee(formatPickerDate(item.selection.endDate))
                      setState([item.selection])
                  }
              }
              moveRangeOnFirstSelection={false}
              ranges={state}
          />
          <button 
              style={{
                  color:"white",
                  padding:"7px 25px",
                  textAlign:"center",
                  border:"1px solid #0046E6",
                  background:"#0046E6"
              }} 
              onClick={()=>{   
                      handleClose();
                      setQuery(`custom&start=${startDateee}&end=${endDateee}`)
                  }}
              >Proceed</button>
        </Modal>

        <div className='w-[150px] block ml-auto]'>
          
          <SelectInput 
              placeholder={"All"}
              options={["All", "Last 7 days", "This Month", "Last Month", "Last 3 Months", "Last 6 Months", "Last 12 Months", "Custom"]}
              selectChange={(item)=>handleSelectChange("Query", item)}
          />
        </div>
        {
          isBoxLoading ?
          <Loader /> :
          <Box boxData={boxData} query={queryVal} />
        }
        <div className='home_main-tableHeader' >
          <div className="table_top" style={{display:"flex", alignItems:"center"}}>
              <div>
                <h4>Float Requests</h4>
              </div>
              <div className="ml-auto rounded-[10px] flex w-[230px] h-10 p-[0.5em] items-center bg-white border-[1px] border-solid border-[#D0D5DD] box-border">
                  <input placeholder="Search float" onChange={handleChange}  className='flex-1 bg-transparent border-0 outline-0 pl-[0.5em] font-normal leading-[16px] text-[#06152B] opacity-50 text-[12.64px]'
                  />
                  <img src={search} alt="search" />
              </div>
          </div>
        </div>
            {/* <SearchField
                placeholder="Search by name, ID"
                containerClass='ml-auto my-6'
            /> */}
        <Tabs
          tabs={[
            {
                label: "All",
                key: "all"
            },
            {
                label: "Pending",
                key: "pending"
            },
            {
                label: "Approved",
                key: "approved"
            },
            {
                label: "Accepted",
                key: "accepted"
            },
            {
                label: "Declined",
                key: "declined"
            },
            {
                label: "Rejected",
                key: "rejected"
            },
          ]}
          variant="line"
        />
        <Table
            loading={isTableLoading}
            data={data.items}
            theadClass="!font-bold py-2.5 text-[#667085] bg-[#F0F0F0] text-sm"
            tdataClass="py-2.5 pl-[10px] text-sm"
            trClassName="shadow-[-1px_4px_17px_-11px_rgba(0,0,0,0.25)]"
            hideTableTop={true}
            hideOverallTableBorder={true}
            hideTableBodyBorder={true}
            hideLines={true}
            paginationContainer="!px-0"
            showDownload={false}
            rows={[
                {
                    header:'Business Name',
                    view: (item) => (
                        <div className='max-w-[230px]'>
                            <span>{item?.businessId?.businessName||"---"}</span>
                        </div>
                    ),
                },
                {
                    header:'Requested Amount',
                    view: (item) => (
                        <span>
                            {currencyFormatter(item?.loanAmount||0)}
                        </span>
                    ),
                },
                {
                  header:'Approved Amount',
                  view: (item) => (
                      <span>
                        {
                          item?.isApproved === 'pending' || 
                          item?.isApproved === 'declined' || 
                          item?.isApproved === 'rejected' ? "---" :
                          currencyFormatter(item?.approvedFloat?.approvedAmount||0) 
                        }            
                      </span>
                  ),
                },
                {
                    header:'Requested Tenure',
                    view: (item) => (
                        <span>
                            {item?.loanDuration||"---"}
                        </span>
                    ),
                },
                {
                    header:'Approved Tenure',
                    view: (item) => (
                        <span>
                          {
                            item?.isApproved === 'pending' || 
                            item?.isApproved === 'declined' || 
                            item?.isApproved === 'rejected' ? "---" :
                            `${item?.approvedFloat?.loanTenure} months`
                          } 
                        </span>
                    ),
                },
                {
                  header:'Email',
                  view: (item) => (
                      <span className='lowercase'>
                          {item?.businessId?.email||"---"}
                      </span>
                  ),
                },
                {
                  header:'Time',
                  view: (item) => (
                      <span>
                          {dayjs(item?.updatedAt).format("DD/MM/YYYY")}<br/>
                          {dayjs(item?.updatedAt).format("hh:mm A")}
                      </span>
                  ),
                },
                {
                    header:'Status',
                    view: (item) => (
                        <span>
                            <Status 
                                text={item?.isApproved}
                                type={
                                    item?.isApproved === 'pending' ? "pending" 
                                    : (item?.isApproved === 'declined' || item?.isApproved === 'rejected') ? "unverified" 
                                    : 'verified'
                                }
                                addClass="!text-sm py-1.5 px-4"
                                hideDot={true}
                            />
                        </span>
                    ),
                },
                {
                    header:'Action',
                    view: (item) => (
                        <Button
                            text="View"
                            onClick={()=>{
                              navigate(`/float/request/${item?._id}`)
                            }}
                            className="bg-ee-primary px-3 py-1 !text-sm !rounded"
                        />
                    ),
                },
            ]}
        />
      </div>
    )
  }

export default Main