import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import { formatBigNumber } from 'utils/helpers';


export default function LineGraph ({
    data,
    graphFor,
    cartesianGridType="none", // "dotted", "dottedXonly"
}) {
    return(
        <div className="w-[1000px] mt-[20px]">
            <LineChart
                width={1000}
                height={300}
                data={data}
                margin={{
                    top: 5,
                    right: 0,
                    left: 0,
                    bottom: 5,
                }}
            >
                {
                    cartesianGridType==="dotted" ?
                    <CartesianGrid strokeDasharray="5" />
                    :
                    cartesianGridType==="line" ?
                    <CartesianGrid />
                    :
                    cartesianGridType==="dottedXonly" ?
                    <CartesianGrid strokeDasharray="5" vertical={false} />
                    :null
                }
                <Tooltip content={<CustomTooltip />} />
                <XAxis 
                    dataKey={
                        (graphFor==="home"||graphFor==="payout") ?
                        "daysRange":"name"} />
                <YAxis tickFormatter={(tick)=>formatBigNumber(tick)} />
                <Line type="monotone" dataKey="count" stroke="#0046E6" strokeWidth={2} activeDot={false} dot={<CustomizedDot />} />
            </LineChart>
        </div>
    )
}

const CustomizedDot = (props) => {
    const { cx, cy, } = props;
  
    return (
        <svg x={cx - 13} y={cy - 13} width="26" height="26" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <ellipse cx="12.8813" cy="13.1018" rx="12.5375" ry="12.9358" fill="#CAD9FE"/>
            <ellipse cx="12.8813" cy="13.1019" rx="8.77628" ry="9.05505" fill="#0349FD"/>
        </svg>
    );
};

const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
            <p className="text-sm text-[#CACDD4]">{formatBigNumber(payload[0].value||0, true)}</p>
      );
    }
  
    return null;
};